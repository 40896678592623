import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../_services/dashboard.service';

@Component({
  selector: 'app-dashboard-upcoming-birthdays',
  templateUrl: './dashboard-upcoming-birthdays.component.html',
  styleUrls: ['./dashboard-upcoming-birthdays.component.css']
})
export class DashboardUpcomingBirthdaysComponent implements OnInit {
  birthdayList: Array<any> = [];
  alertFlag:boolean =false;
  alerts: any = [ 
    {
      type: 'info',
      msg: `No Birthdays to display`
    },
  ]

  constructor(public dashboardService: DashboardService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getEmployeeBirthdays();
  }

  getEmployeeBirthdays() {
    this.dashboardService.getUpcomingEmployeeBirthdays().subscribe((birthdays: any) => {
      if (birthdays.isSuccessful == true) { //success
        this.alertFlag = false;
        this.birthdayList = birthdays.data;
      }
      else if (birthdays.isSuccessful == false) {
        this.alertFlag = true;
      }
      
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
}
