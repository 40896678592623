import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../../../_services/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-banner',
  templateUrl: './dashboard-banner.component.html',
  styleUrls: ['./dashboard-banner.component.scss']
})
export class DashboardBannerComponent implements OnInit {
  events: Array<{ title: string, description: string }> = [];
  @Input() hasEvent: boolean = false;
  @Input() eventImage: string = '';

  showBanner: boolean = false;
  showConfetti: boolean = false;
  confettiTimeout: any;
  birthdayList: Array<any> = [];  // ✅ Initialize as an empty array
  birthdayPerson: string | null = null;

  constructor(
    public dashboardService: DashboardService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getEmployeeBirthdays(); // ✅ Move API call to ngOnInit()
  }

  getEmployeeBirthdays() {
    this.dashboardService.getUpcomingEmployeeBirthdays().subscribe(
      (birthdays: any) => {
        if (birthdays.isSuccessful) {
          this.birthdayList = birthdays.data;
          this.checkBirthday();
        }

      },
      error => {
        this.spinner.hide();
      }
    );
  }

  checkBirthday() {
    debugger
    const today = new Date();
    const todayMonthDay = today.toISOString().slice(5, 10); // Extract MM-DD

    console.log('Today (MM-DD):', todayMonthDay);

    const lastShownDate = localStorage.getItem('birthdayBannerShown');
    if (lastShownDate === todayMonthDay) {
      this.spinner.hide();
      return; // Prevent duplicate display
    }

    const birthdayMatches = this.birthdayList
      .filter(emp => {
        if (!emp.birthdayDate) return false;

        const empBirthdayUTC = new Date(emp.birthdayDate);
        const empBirthdayMonthDay = empBirthdayUTC.toISOString().slice(5, 10); // Extract MM-DD

        console.log(`Checking: ${emp.fullName} - Birthday: ${empBirthdayMonthDay}`);

        return empBirthdayMonthDay === todayMonthDay;
      })
      .map(emp => emp.fullName);

      this.spinner.hide();

    if (birthdayMatches.length > 0) {
      this.birthdayPerson = birthdayMatches.join(' & ');
      this.showBanner = true;
      localStorage.setItem('birthdayBannerShown', todayMonthDay);
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }







  startCelebration() {
    this.showConfetti = true;
    this.confettiTimeout = setTimeout(() => {
      this.showConfetti = false;
    }, 5000);
  }

  closeDialog() {
    this.showBanner = false;
    this.showConfetti = false;
    clearTimeout(this.confettiTimeout);
  }

  getConfettiStyle(index: number) {
    return {
      'left': `${Math.random() * 100}%`,
      'animation-duration': `${Math.random() * 3 + 2}s`,
      'background-color': this.getRandomColor(),
    };
  }

  getRandomColor() {
    const colors = ['#ff0a54', '#ff477e', '#ff85a1', '#ffccd5', '#fcf6bd', '#a0c4ff'];
    return colors[Math.floor(Math.random() * colors.length)];
  }
}
