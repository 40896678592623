import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeRecordService } from '../_services/employee-record.service';
import { AuthService } from '../_services/auth.service';
import { EmployeeDataModel } from '../_models/employee-data-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';




@Component({
  selector: 'app-demo-solzit',
  templateUrl: './demo-solzit.component.html',
  styleUrls: ['./demo-solzit.component.scss']
})
export class DemoSolzitComponent   {

  enableCrop: boolean = true;
  size: string = 'small';
  type: string = 'circle';
  imageDataURI: string = '';
  resImageDataURI: string = '';
  resImgFormat: string = 'image/png';
  resImgQuality: number = 1;
  selMinSize: number = 100;
  resImgSize: number = 200;
  disable: boolean = true;
$dataURI: string;

images = [
  { url: '../../assets/img/Emp_Boy.png', alt: 'Image 1' },
  { url: '../../assets/img/Emp_Boy.png', alt: 'Image 1' },
  { url: '../../assets/img/Emp_Boy.png', alt: 'Image 1' },
  { url: '../../assets/img/Emp_Boy.png', alt: 'Image 1' },
    // Add more image objects as needed
];
  constructor(
    private authservice : AuthService
  ) { }

  ngOnInit(): void {
  }

  onChange(dataURI: string): void {
  }

  onLoadBegin(): void {
  }

  onLoadDone(): void {
  }

  onLoadError(): void {
  }

  handleFileSelect(event: any): void {
    const file = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      this.imageDataURI = evt.target.result;
      this.disable = false;
    };
    reader.readAsDataURL(file);
  }

  upload(): void {
    let str = this.resImageDataURI;
    str = str.replace('data:image/png;base64,', '');

    const empObjval = this.getCachedAuthInfo(); // Implement this function as per your requirement

    let emp: any;
    if (empObjval.contact) {
      emp = {
        employeeImg: str,
        contact: empObjval.contact,
      };
    } else {
      emp = {
        employeeImg: str,
        ID: empObjval.ID,
        employee: {
          email: empObjval.employee.email,
          password: empObjval.employee.password
        }
      };
    }

    // Implement the following service call using Angular HttpClient
    this.authservice.updateDisplayPicture(emp).subscribe((result:any)=>{
      const rCode = result.data.ResponseCode;
      if (rCode === 100) {
        this.saveAuthInfo(result.data.Data);
        window.location.reload();
      }
    })
    // this.http.post(baseUrlApi + "EmployeeAuthorization/UpdateDisplayPicture/", emp).subscribe((result: any) => {
    
    // });
  }

  // Implement the following functions as per your requirement
  getCachedAuthInfo(): any {
    // Implement this function
  }

  saveAuthInfo(data: any): void {
    // Implement this function
  }
}