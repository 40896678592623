import { Component, OnInit, ViewChild } from '@angular/core';
import { ToDosService } from '../../../_services/to-dos.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { Table } from 'primeng/table';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
@Component({
  selector: 'app-my-report',
  templateUrl: './my-report.component.html',
  styleUrls: ['./my-report.component.scss']
})
export class MyReportComponent implements OnInit {
  @ViewChild('parentTable') parentTable: Table;
  userData: EmployeeDataModel;
  contactId: any;
  empId: any;
  toDoList: Array<ToDosDataModal> = [];
  toDosObj: ToDosDataModal;
  ContactLoggedIn: boolean;
  isManager: any;
  heading: string;
  ToggleFlag: any;
  checked: boolean = false;
  selectedRecords: any[] = [];
  selectedProducts!: ToDosDataModal[];;

  constructor(public toDosService: ToDosService,
    // private primeNGConfig: PrimeNGConfig,
    private storage: StorageMap,
    public supportReqService: SupportRequestService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.spinner.show();
    this.toDosObj = new ToDosDataModal();
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  onRowSelect(event: any) {
    console.log("✅ Row Selected:", event.data);
  }

  onRowUnselect(event: any) {
    console.log("❌ Row Unselected:", event.data);
  }

  onRowSelectionChange(event: any) {
    console.log("🔄 Selection Changed:", this.selectedProducts);
  }

  rowData(record: any, event: Event) {
    console.log("📌 Row Clicked:", record);
    event.stopPropagation(); // Prevent row selection conflict
  }

  getSelectedRows() {
    console.log("📝 Selected Rows:", this.selectedProducts);
  }


  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myToDos: EmployeeDataModel) => {
      this.userData = myToDos.userProfile;
      // If Contact User is logged in
      if (this.userData.userId) {
        this.ContactLoggedIn = false;
        this.isManager = this.userData?.isManager;
        this.empId = this.userData?.userId
        this.GetToDoListBasedOnFilter(3, 0)
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //Toggle for Expand And collapsed Table Row Data
  showHideToggle() {
    this.ToggleFlag = !this.ToggleFlag;

  }
  GetToDoListBasedOnFilter(filterId, itemType) {
    this.spinner.show();

    if (filterId == 1 && itemType == 0) {
      this.heading = " My Active Items";
    }
    else if (filterId == 2 && itemType == 0) {
      this.heading = "Not Started Items";
    }
    else if (filterId == 3 && itemType == 0) {
      this.heading = "Items I'm Working On";
    }
    else if (filterId == 3 && itemType == 674180003) {
      this.heading = "Bugs I'm Working On";
    }
    this.toDosService.GetToDoListBasedOnFilter(filterId, itemType).subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = []
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }
}

