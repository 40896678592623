import { EmployeeDataModel } from './employee-data-model';

export class BreakLogDataModel {
  number: number;
  start: number;
  pageNumber: number;
  totalItemNo: number;

  employee: EmployeeDataModel;
  reason: string;
  outtime: Date;
  outTime: Date;
  returntime: Date;
  breakduration: string;
  createdon: Date;
  serialnumber: string;
  day: string;// created by Udbhav for Dual-way binding.
  ID: string;// created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  Name: string; // created by Udbhav for API DM compatability as the DM there inherits Base Entity.
}
