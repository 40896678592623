import { Component, OnInit } from '@angular/core';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportRequestService } from '../../../_services/support-request.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToDosService } from '../../../_services/to-dos.service';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import * as moment from 'moment';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';
type NewType = ToDosDataModal;

@Component({
  selector: 'app-update-raise-bug-all-project',
  templateUrl: './update-raise-bug-all-project.component.html',
  styleUrls: ['./update-raise-bug-all-project.component.scss']
})
export class UpdateRaiseBugAllProjectComponent implements OnInit {

  supportReqObj: SupportRequestDataModel;
  empData: EmployeeDataModel;
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: NewType;
  solzStatusList: Array<OptionSetDataModel> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  downloadFileList: Array<UploadFileDataModel> = [];
  updateToDoId: string = "";
  lockStartDate: boolean = false; // used for locking start date form field if not having null or null date 
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList = [];
  srNumberItemno: string;

  // used for Asign Me As Option Set.
  assignMeList: any[] = [];
  assign: any;
  // used for work logs list
  workLogsList: Array<WorkLogDataModel> = [];
  showHideBugGridFlag: boolean = false;
  // for update form
  lockFields: boolean = false;
  implementationEffortFlag: boolean;
  expectedDateFlag: boolean;
  linkedTaskFlag: boolean = false;
  bugListData: any;
  edit: string;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sharepointServices: SharepointServiceService,
    private router: Router) { }

  ngOnInit(): void {


    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateToDoId = this.route.snapshot.paramMap.get('id');
    }

    this.supportReqForm = this.fb.group({
      ID: [''],
      Name: [''],
      createdon: [''],
      expectedResDate: [null],
      createdOn: [null],
      ItemId: [''],
      itemNo: [''],
      title: [''],
      projectName: [''],
      projectId: [null],
      solzStatus: [null],
      customerStatus: [null],
      itemDescription: [''],
      parentId: [null],
      parentItemNo: [''],
      parentTitle: [''],
      userPriority: [null],
      typeOfItem: [null],
      assigneeId: [null],
      assigneeName: [''],
      assignedReviewerId: [null],
      assignedReviewerName: [''],
      comment: [''],
      startDate: [null],
      testingEnvironment: [null],
      isBug: ["false", [Validators.required]],
     

    });

    this.getData(); // getting data from local storage api and setting it in our local variable.
    //get data from local storage (work status , testing environment, priority , Emp projects by emp Id)

   

  }

  //*********************** Get APIs Begin *****************************
  //********************* To Dos Begin********************

  async getData() {


    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {

      this.empData = empNewRequest.userProfile;

      // If Contact User is logged in
      if (this.empData.contact) {

        this.custToDosObj = new ToDosDataModal();
        this.contactLoggedIn = true;
        // this.getCustomerProjects();
        this.getToDosDataToUpdate()
      }
      else if (this.empData.userId) {
        this.custToDosObj = new ToDosDataModal();
        this.contactLoggedIn = false;
        this.getToDosDataToUpdate()
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }


  // for To Do update.
  async getToDosDataToUpdate() {

    this.supportReqObj = new SupportRequestDataModel();
    this.assignMeList = [{ Label: "Assignee", Value: 0 }, { Label: "Assigned Reviewer", Value: 1 }];
    await this.toDosService.getobservationDetailsForUpdation(this.updateToDoId).subscribe((toUpRes: any) => {

      if (toUpRes.isSuccessful == true) { // success 

        this.supportReqObj = toUpRes.data;
        // filling form fields.
        this.srNumberItemno = this.supportReqObj.itemNo

        this.supportReqForm.patchValue({
          ID: this.supportReqObj.ID,
          ItemId: this.supportReqObj.ItemId,
          Name: this.supportReqObj.Name,
          assignedReviewerId: this.supportReqObj.assignedReviewerId,
          assignedReviewerName: this.supportReqObj.assignedReviewerName,
          assigneeId: this.supportReqObj.assigneeId,
          assigneeName: this.supportReqObj.assigneeName,
          comment: this.supportReqObj.comment,
          itemDescription: this.supportReqObj.itemDescription,
          itemNo: this.supportReqObj.itemNo,
          projectId: this.supportReqObj.projectId,
          projectName: this.supportReqObj.projectName,
          customerStatus: toUpRes.data.customerStatus?.label,
          testingEnvironment: toUpRes.data.testingEnvironment?.label,
          title: this.supportReqObj.title,
          isBug: this.supportReqObj.isBug,
          userPriority:toUpRes.data.userPriority?.label


        });
       
        this.spinner.hide();
      }


      else if (toUpRes.isSuccessful == false) {
        this.toastr.error(toUpRes?.messageDetail?.message, 'Error');
        this.spinner.hide();

      }

      this.getToDsAttachments();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail.message);
    });
  }

  async getToDsAttachments() {
    this.downloadFileList = [] as Array<UploadFileDataModel>;
 
    await this.sharepointServices.GetAttachmentFromSharePoint(this.updateToDoId).subscribe((fileRes: any) => {
      if (fileRes.isSuccessful == true) {// success
        this.downloadFileList = fileRes.data;
      }

      else if (fileRes.isSuccessful == false) {
        // if(fileRes.Data != null){q
        //   this.toastr.error(fileRes.Message, 'Error');
        // }        
        this.spinner.hide();

      }
      // this.getWorkLogList();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }



  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }


  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  onSubmit() {debugger


    this.spinner.show();
    // deciding which object to send according to who logged in i.e., 

    var updateSupportReqObj;

      updateSupportReqObj = {
        ID: this.updateToDoId,
        comment: this.supportReqForm.value.comment,
      }
    

    //else if (this.supportReqForm.value.newEmpId == 0 )
    this.toDosService.updateobservation(updateSupportReqObj).subscribe((updateRes: any) => {


      if (updateRes.isSuccessful == true) { // success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = this.updateToDoId;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);

        }

        // else { // else just show message.
        if (this.upLoadFileList.length == 0) {
          this.spinner.hide();
          this.toastr.success(updateRes?.messageDetail?.message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          this.router.navigate(['/ESSPortal/my-observation']);

        }
      }

      else if (updateRes.ResponseCode == 999) {
        this.toastr.error(updateRes.Message, 'Error');
        this.spinner.hide();

      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });


  }
  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
    this.spinner.show()
    await this.sharepointServices.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        // this.spinner.hide();
        this.router.navigate(['/ESSPortal/my-observation']);
        this.getData()

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }



  download(file: any) {


    import("file-saver").then(FileSaver => {
      var contentbase64 = file.bytes;
      var contenttype = file.fileType;
      var fileFormat = "data:" + contenttype + ";base64," + contentbase64;
      //download file
      FileSaver.saveAs(fileFormat, file.fileName);
    });


  }



}
