import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  empData: EmployeeDataModel;
  changePassForm: FormGroup;
  message: string = "";
  flag: boolean;
  changeFormSubmitted: boolean;

  constructor(public authService: AuthService,
    private storage: StorageMap,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.spinner.show()
    this.empData = new EmployeeDataModel();
    this.flag = false;
    this.changeFormSubmitted = false;

    // building form.
    this.changePassForm = this.fb.group({
      ID: [''],
      Name: [''],
      email: ['', Validators.required],
      // fullName: [''],
      oldpassword: ['', Validators.required],
      newpassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), Validators.minLength(8), Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), Validators.minLength(8), Validators.maxLength(20)]]
    });

    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  async getData() { 
    await this.storage.get('empDetails').subscribe((getDettails: any) => {
      this.empData = getDettails.userProfile;

      this.changePassForm.patchValue({
        ID: this.empData.userId,
        email: this.empData.email,
        fullName: this.empData.fullName
      });
      this.spinner.hide();
    });
  }

  // getting Change Password Form.
  get cpf() {
    return this.changePassForm.controls;
  }

  // making sure that the confirm password and new password matches.
  validation(val: any) {
    if (this.changePassForm.value.password != val) {
      this.message = "Password and Confirm Password should match.";
      this.flag = true;
      this.changePassForm.controls['newpassword'].setErrors({ 'incorrect': true });
    }

    else if (this.changePassForm.value.newpassword != val) {
      this.message = "Password and Confirm Password should match.";
      this.flag = true;
      this.changePassForm.controls['newpassword'].setErrors({ 'incorrect': true });
    }

    else {
      this.flag = false;
    }

  }

  async onSubmit() {
    this.spinner.show();
    this.changeFormSubmitted = true;
    if (this.changePassForm.invalid) {
      this.spinner.hide();
      return;
    }
    await this.authService.postChangePassword(this.changePassForm.value).subscribe((chngRes: any) => {
      this.spinner.hide();
      if (chngRes.isSuccessful == true) { // success
        this.toastr.success(chngRes.messageDetail.message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        setTimeout(() => {
          this.toastr.info('Please login again.', 'Info', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          this.authService.logoutEmployee();
        }, 2000);
      }

      else if (chngRes.isSuccessful == false) {
        this.toastr.error(chngRes.messageDetail.message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error.messageDetail.message, "Error");
    });
  }


}
