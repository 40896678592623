import { OptionSetDataModel } from './option-set-data-model';

export class ToDosDataModal {
  [x: string]: any;

  ID: string // created by Udbhav for matching with API DM.(ToDosDM).
  Name: string; // created by Udbhav for matching with API DM.(ToDosDM).

  number: number;
  start: number;
  pageNumber: number;
  totalItemNo: number;

  customerId: string;
  createdBy: string;

  itemNo: string;
  createdon: string;

  title: string;
  expectedResDate: string;

  projectId: string;
  projectName: string;

  assigneeId: string;
  assigneeName: string;

  assignedReviewerId: string;
  assignedReviewerName: string;

  userPriority: OptionSetDataModel;
  solzStatus: OptionSetDataModel;

  itemDescription: string;
  reproSteps: string;

  parentId: string;
  parentItemNo: string;
  parentTitle: string;

  typeOfItem: OptionSetDataModel;
  workArea: OptionSetDataModel;

  isClientReported: boolean;
  releaseNumber: string
  
  startDate: Date;
  dateMoved: Date;

  implementationeffort: number;
  revieweffort: number;
  testingEnvironment: OptionSetDataModel;

  comment: string;
  descriptionOfResolution: string;

  typeOfItems: OptionSetDataModel;

  sprintsList:OptionSetDataModel;
}
