import { Component, Input, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../../../_services/dashboard.service';


@Component({
  selector: 'app-dashboard-line-chart',
  templateUrl: './dashboard-line-chart.component.html',
  styleUrls: ['./dashboard-line-chart.component.css']
})
export class DashboardLineChartComponent implements OnInit {
  @Input() employeeId: string;
  leaveList: Array<any> = [];
  data: any;
  options: any;
  month: Array<any> = [];
  earnLeaves: Array<any> = [];
  medicalLeaves: Array<any> = [];
  optionalLeaves: Array<any> = [];
  lopLeaves: Array<any> = [];

  constructor(public dashboardService: DashboardService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getLeaveAvailedGraph(); // getting data for graph.
  }

  async getLeaveAvailedGraph() {
    await this.dashboardService.getLeavesGraphData().subscribe((barData: any) => {
      if (barData.isSuccessful == true) { // success
        this.leaveList = barData.data;
        this.leaveList?.sort((a, b) => {
          return <any>new Date(a.monthYear) - <any>new Date(b.monthYear);
        });
        this.getLabels();
        this.getEarnLeaves();
        // this.getMedicalLeaves();
        // this.getOptionalLeaves();
        this.getLOPLeaves();
        this.getLineChart();

      }
      else if (barData.isSuccessful == false) {
        this.spinner.hide();
        if(barData?.messageDetail?.message_code != 204){

          this.toastr.error(barData.Message, 'Error');
        }
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  async getLineChart() {
    this.data = {
      labels: this.month,
      datasets: [
        {
          label: 'Earned Leave',
          data: this.earnLeaves,
          fill: true,
          backgroundColor: 'rgba(11,156,49,0.2)',
          borderColor: 'rgba(46, 184, 92, 0.8)',
          pointBackgroundColor: 'rgba(46, 184, 92, 0.8)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(46, 184, 92, 0.2)'

        },

        // {
        //   label: 'Medical Label',
        //   data: this.medicalLeaves,
        //   fill: true,
        //   backgroundColor: 'rgb(255,0,0,0.1)',
        //   borderColor: 'rgba(247,135,200,1)',
        //   pointBackgroundColor: 'rgba(247,135,200,1)',
        //   pointBorderColor: '#fff',
        //   pointHoverBackgroundColor: '#fff',
        //   pointHoverBorderColor: 'rgba(247,135,170,0.1)'
        // },

        // {
        //   label: 'Optional Label',
        //   data: this.optionalLeaves,
        //   fill: true,
        //   backgroundColor: 'rgba(4, 17, 138, 0.2)',
        //   borderColor: 'rgba(4, 17, 138, 0.8)',
        //   pointBackgroundColor: 'rgba(4, 17, 138, 0.8)',
        //   pointBorderColor: '#fff',
        //   pointHoverBackgroundColor: '#fff',
        //   pointHoverBorderColor: 'rgba(4, 17, 138, 0.2)'
        // },

        {
          label: 'LOPs',
          data: this.lopLeaves,
          fill: true,
          backgroundColor: 'rgba(219, 58, 4, 0.2)',
          borderColor: 'rgba(219, 58, 4, 0.8)',
          pointBackgroundColor: 'rgba(219, 58, 4, 0.8)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(219, 58, 4, 0.2)'
        }
      ]
    }
    this.options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          }
        }],
        xAxes: [{
          ticks: {
            beginAtZero: true,
          }
        }]
      }
    }
    this.spinner.hide();
  }

  async getLabels() {
    this.leaveList?.filter((i) => {
      this.month.push(i.monthYear);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getEarnLeaves() {
    this.leaveList?.filter((i) => {
      this.earnLeaves.push(i.earnleaveavailed);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getMedicalLeaves() {
    this.leaveList?.filter((i) => {
      this.medicalLeaves.push(i.medicalleaveavailed);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getOptionalLeaves() {
    this.leaveList?.filter((i) => {
      this.optionalLeaves.push(i.optionalleavesavailed);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getLOPLeaves() {
    this.leaveList?.filter((i) => {
      this.lopLeaves.push(i.totallopleave);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
}
