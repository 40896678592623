
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PaymentInvoiceService } from '../../_services/payment-invoice.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from 'lodash';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeDataModel } from '../../_models/employee-data-model';
import { AuthService } from '../../_services/auth.service';
import { navItems } from '../../_nav';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit {
  public navItems = navItems;
  userData: EmployeeDataModel;
  paymentForm: FormGroup;
  paymentInvoiceForm: FormGroup;
  invoiceData: any;
  paymentSelectid: any;
  checkboxListIdPayment: any;
  hideInvoiceForm: boolean = false;
  amountReceived: any;
  amountINR: any;
  allocatedAmount: any;
  unAllocatedAmount: any;
  AmountToallocated: number = 0;
  paymentInvoiceListData: any;
  totalAmountt: number = 0;
  BilledNewPartiallyPaidInvoiceID: any;
  errorMessageAmt: any = '';
  errormsgFalg: boolean = false;
  successMsgFlag: boolean = false;
  allData: any = [];
  fullypaidMarked: boolean = false;
  Amount: any;
  PartiallyCheckBox: any;
  diffPdingAmtTotalAmt: number;
  filteredItems: any;
  customerName: any;
  invoiceName: any;
  invoiceNumber: any;
  invoiceDate: any;
  invoiceStatusRegion: any;
  totalAmount: any;
  pendingAmount: any;
  noDataFound: boolean = false;
  isManager: boolean = false;
  ContactLoggedIn: boolean;
  contactId: string;
  empId: string;
  accessDeniedFlag : boolean = false;
  fullypaidInputBoxAmt: any;
  data: any;
  searchProjectData: string;
  filterString: string = "";
  disabledFlag: boolean = true;
  paymentFormArray: FormArray;
  filteredRecords: any;
  postData: any;
  PendingAmt: any;




  constructor(
    private _formBuilder: FormBuilder,
    public paymentInvoiceServices: PaymentInvoiceService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    private storage: StorageMap,
    private router: Router,
    public authService: AuthService,
    private domSanitizer: DomSanitizer,
    public routers:ActivatedRoute,

  ) {
    this.createPaymentsForm();
    // this.empId = localStorage.getItem("empId")
    // // var ismanager = localStorage.getItem("ismanager");

    // if (this.navItems[8].url == '/ESSPortal/invoice-payment' || this.isManager == true) {
    // }

  }
  

  ngOnInit(): void {
    this.spinner.show();
    //this.invoiceForm();
    this.getData();
  }

  async getData() { 
    this.accessDeniedFlag = false;
    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {
      
      this.userData = myInvoice.userProfile;
      // If Contact User is logged in
      if (this.userData.contact) {
        this.ContactLoggedIn = true;
        this.contactId = this.userData.contact?.ID;
      }

      // If Soluzione Emp/Manager logged in
      else if (this.userData.userId) {
        this.ContactLoggedIn = false;
        this.isManager = this.userData?.isManager;
        this.empId = this.userData?.userId
        // this.getEmployeeProjects(this.userData.ID);
      }
       if (this.isManager === true) {
        this.accessDeniedFlag = true ;
        
        this.getInvoiceActivePayment();
        this.GetBilledNewPartiallyPaidInvoiceLists();
        this.GetPaymentById(this.paymentSelectid);
        this.paymentSelected(event);
        this.invoiceForm();         
      }
      else{
        this.accessDeniedFlag= false;
        this.spinner.hide();
        //  alert("access denied")
         this.router.navigate(['/ESSPortal/error']);
      }
      this.spinner.hide();
      // alert("access denied")
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //Payement Form Group 
  createPaymentsForm() {
    this.paymentForm = this._formBuilder.group({
      payments: ['', Validators.required],
      currency: ['', Validators.required],
      exchangeRate: ['', Validators.required],
      allocatedAmt: ['', Validators.required],
      unallocatedAmt: ['', Validators.required],
      AmountToallocated: ['', Validators.required],
      receivedAmt: ['', Validators.required],
      amtReceiveINR: ['', Validators.required],
      totalAmountt: ['', Validators.required],
      //  invoices: this._formBuilder.array([])
    });

  }


  invoiceForm() {
    this.paymentInvoiceForm = this._formBuilder.group({
      id: [null],
      invoices: this._formBuilder.array([]),

    });
  }

  tableColumns(): FormArray {
    return this.paymentInvoiceForm.get('invoices') as FormArray;
  }
  invoicePmtArray() {
    this.checkboxListIdPayment.forEach((element: any, index: any) => {
      this.tableColumns().push(this.Form());
      this.tableColumns().controls[index].patchValue(element);
      this.tableColumns().controls[index].updateValueAndValidity();
    });
  }
  Form() {
    return this._formBuilder.group({
      ID: [null],
      amountAllocated: [null],
      invoiceNumber: [''],
      invoiceDate: [null],
      customerName: [''],
      invoiceName: [''],
      invoiceStatusRegion: [''],
      currencyName: [''],
      totalAmount: [''],
      pendingAmount: [''],
      PartiallyPaidCheckBox1: [false, Validators.required],
      fullypaidInputBoxAmt: [0, Validators.required],
      diffPdingAmtTotalAmt: [0, Validators.required],
      fullypaidMarked: [''],
    });

  }
  //API for Active Payment List Unsetteled And Unpaid
  getInvoiceActivePayment(): void {

    this.paymentInvoiceServices.getActivePaymentInvoice()
      .subscribe({
        next: (res: any) => {


          this.invoiceData = res.Data;
          this.AmountToallocated = res?.Data?.AmountToallocated


          const obj = {

            amountReceived: this.invoiceData?.receivedAmt,
            amountINR: this.invoiceData?.amtReceiveINR,
            allocatedAmount: this.invoiceData?.allocatedAmt,
            unAllocatedAmount: this.invoiceData?.unallocatedAmt,

          }

          this.paymentForm.patchValue(obj);
          this.spinner.hide();
        }
      })

    this.paymentSelected(event);
  }

  paymentSelected(event) {


    this.spinner.show();

    if (this.paymentSelectid = event) {

      this.paymentSelectid = event;
      this.hideInvoiceForm = true;

    }
    this.GetPaymentById(this.paymentSelectid);

  }

  GetPaymentById(paymentSelectid) {


    this.paymentInvoiceServices.GetPaymentById(paymentSelectid)
      .subscribe({
        next: (responsee: any) => {

          if (responsee.ResponseCode == 100) {

            this.paymentInvoiceListData = responsee.Data;
            this.spinner.hide();
            this.paymentForm.patchValue(this.paymentInvoiceListData);

            this.spinner.hide();
          }


          else {
            this.spinner.hide();
          }
        }

      })

  }

  //GetBilledNewPartiallyPaidInvoiceLists API for partially invoice payment

  GetBilledNewPartiallyPaidInvoiceLists() {


    this.paymentInvoiceServices.GetBilledNewPartiallyPaidInvoiceLists()
      .subscribe({
        next: (Resp: any) => {
          if (Resp.ResponseCode == 100) {

            this.checkboxListIdPayment = Resp.Data;

            if (this.checkboxListIdPayment.length > 0) {
              this.checkboxListIdPayment.forEach(element => {
                element.isActive = false
                element.mapPendingAmount = 0;
                element.mapPendingAmount2 = 0;
              });


              this.invoicePmtArray();
              this.spinner.hide();


            }


            this.BilledNewPartiallyPaidInvoiceID = Resp.Data.ID
          }
          else {
            this.spinner.hide();
          }
        }
      })
  }




  //When User Input Value In Amount To Be Allocated Field Then Store Value which is enter by user
  AmountTobeAllocated(value) {
    this.AmountToallocated = value;

    //check if Total Checked Amount And Amount To be allocated is Equall then success If not Equall then show error !
    if (this.totalAmountt == this.AmountToallocated) {

      this.errorMessageAmt = " Amount Matched Successfully !"
      this.successMsgFlag = true;
      this.errormsgFalg = false;
      return true

    }

    else {

      this.errorMessageAmt = "Total allocated amount on both side must be equal!"
      this.successMsgFlag = false;
      this.errormsgFalg = true;
      return false;

    }
    // if (this.totalAmountt <= this.unAllocatedAmount) {

    //   this.errorMessageAmt = " Amount Matched Successfully !"
    // }
    // else{
    //   this.errorMessageAmt = "Total allocated amount and Unallocated amount must be equal!"
    //   this.successMsgFlag = false;
    //   this.errormsgFalg = true;
    //   return false;
    // }

  }

  // //Sum up the total amount allocated to all the invoices 
  onClickCheckbox(item, event) {

    if (item.controls['fullypaidInputBoxAmt']?.value <= item.controls['pendingAmount']?.value) {

      this.successMsgFlag = false;
      this.errormsgFalg = false;
    }

    else {
      //  this.totalAmountt = item.controls['fullypaidInputBoxAmt'].value;
      this.errorMessageAmt = "Allocated Amount cannot not exceeds Pending Amount!"
      this.errormsgFalg = true;
      this.successMsgFlag = false;

    }
    this.getTotalAmount();
    // this.totalStatus(item, event) 
  }

  getTotalAmount() {
    this.totalAmountt = 0;
    this.tableColumns().controls.forEach((x: any) => {

      // if (x.value.PartiallyPaidCheckBox1) {
      this.totalAmountt = this.totalAmountt + x.value.fullypaidInputBoxAmt;
      // }
    })
  }
  // //On Invoice allocated amount change 
  totalStatus(item, event) {
    
    this.fullypaidMarked = event;
    // item.controls['PartiallyPaidCheckBox1']?.setValue(event);
    // item.controls['PartiallyPaidCheckBox1']?.updateValueAndValidity();
    this.PartiallyCheckBox = event

    if (event) {
      this.diffPdingAmtTotalAmt = item.controls['pendingAmount']?.value - item.controls['fullypaidInputBoxAmt']?.value;
      item.controls['diffPdingAmtTotalAmt']?.setValue(this.diffPdingAmtTotalAmt);
      item.controls['diffPdingAmtTotalAmt']?.updateValueAndValidity(this.diffPdingAmtTotalAmt);
    }
    else {
      item.controls['diffPdingAmtTotalAmt']?.setValue(0);
      item.controls['diffPdingAmtTotalAmt']?.updateValueAndValidity(0);
    }

    if (item.controls['fullypaidInputBoxAmt']?.value <= item.controls['pendingAmount']?.value) {

      this.successMsgFlag = false;
      this.errormsgFalg = false;
    }

    else {
      //  this.totalAmountt = item.controls['fullypaidInputBoxAmt'].value;
      this.errorMessageAmt = "Allocated Amount cannot not exceeds Pending Amount!"
      this.errormsgFalg = true;
      this.successMsgFlag = false;

    }
    this.getTotalAmount();
  }


  //check box for fully paid amount user checkd then all panding amount value patch in input box
  onClickCheckboxFullyPaid(row, event) {
    if (event === true) {
      row.value.mapPendingAmount = row.controls['pendingAmount']?.value;
      row.controls['fullypaidInputBoxAmt'].setValue(row.controls['pendingAmount']?.value);
      row.controls['fullypaidInputBoxAmt'].updateValueAndValidity();
      row.value.amountAllocated = row.controls['fullypaidInputBoxAmt']?.value
    }
    else {

      row.controls['fullypaidInputBoxAmt']?.setValue(0);
      row.controls['fullypaidInputBoxAmt']?.updateValueAndValidity();
    }

    this.onClickCheckbox(row, event);
    this.getTotalAmount()

  }

  onSubmit(value: any) {

    var valid = this.AmountTobeAllocated(this.AmountToallocated);
    if (valid) {
      //value.Id =   this.paymentSelectid;
      value.invoices.forEach((x: any) => {
        if (x.PartiallyPaidCheckBox1 == true) {
          x.amountAllocated = x.fullypaidInputBoxAmt;
          if (x.fullypaidMarked == true) {
            x.markedFullyPaid = this.fullypaidMarked;
          }
          else {
            x.markedFullyPaid = false;
          }
          this.allData.push(x);
        }
      });

      let testObj = {
        paymentId: this.paymentSelectid,
        InvoiceLists: this.allData
      }
      // this.allData.push(testObj)
      this.spinner.show();
      this.paymentInvoiceServices.postMultiInvoicePayment(testObj)
        .subscribe({
          next: (Responset: any) => {

            if (Responset.ResponseCode == 100) {

              this.toastr.success(Responset.message, 'Record Created Successfully', {//Success
                positionClass: 'toast-top-full-width',


              });

              this.spinner.hide();
              setTimeout(function () {
                location.reload();
              }, 5000);

            }

            else if (Responset.ResponseCode == 999) {
              this.toastr.error(Responset.Message, 'Error');
              this.spinner.hide();
            }
          }
        })
    }
    else {

      this.errorMessageAmt = "Total allocated amount on both side must be equal!"

    }
  }


  filterRecords() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      if (item.invoiceNumber !== null) {
        return item.invoiceNumber.toLowerCase().includes(this.invoiceNumber.toLowerCase())
      }
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  filterRecords1() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      return item.invoiceDate.toLowerCase().includes(this.invoiceDate.toLowerCase())
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  filterRecords2() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      return item.customerName.toLowerCase().includes(this.customerName.toLowerCase())
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }

  }

  filterRecords3() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      return item.invoiceName.toLowerCase().includes(this.invoiceName.toLowerCase())
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  filterRecords4() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      return item.invoiceStatusRegion.toLowerCase().includes(this.invoiceStatusRegion.toLowerCase())
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }


  filterRecords5() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      return item.totalAmount.toString().includes(this.totalAmount.toLowerCase())
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }


  filterRecords6() {
    this.filteredItems = this.checkboxListIdPayment.filter(item => {
      return item.pendingAmount.toString().includes(this.pendingAmount.toLowerCase())
    });
    this.tableColumns().clear();
    this.filteredItems.forEach(element => {
      this.tableColumns().push(this.Form());
    });
    this.tableColumns().patchValue(this.filteredItems);
    this.tableColumns().updateValueAndValidity();
    this._changeDetectorRef.detectChanges()
    if (this.checkboxListIdPayment.length !== 0 && this.filteredItems.length === 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

}






















