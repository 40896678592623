import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { ToDosService } from '../../../_services/to-dos.service';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-worklog',
  templateUrl: './my-worklog.component.html',
  styleUrls: ['./my-worklog.component.css']
})
export class MyWorklogComponent implements OnInit {
  toDoId: string = "";
  workLogId: string = "";
  typeOfId: string = "";
  empData: EmployeeDataModel;
  isManager: boolean;
  empId: string = "";
  toDosList: Array<ToDosDataModal> = []; // used for AddWork
  workLogObj: WorkLogDataModel; // used for EditWork
  workLogForm: FormGroup;
  workFormSubmitted: boolean = false;
  addFlag: boolean = true;
  saveButtonFlag: boolean = true;
  withdrawButtonFlag: boolean = false;
  submitApprovalButtonFlag: boolean = true;
  readonly: boolean = false;
  todayDate = new Date();
  // maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  maxDate: string;
  minDate: string;
  contactLoggedIn: boolean = false;
  ProjectId: any;
  ProjectName: any;
  showDataFlag: boolean = false;
  approvedStatusFlag: boolean = false;
  rejectedStatusFlag: boolean = false;
  worklogResp: any;



  constructor(private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public supportReqService: SupportRequestService,
    private router: Router,
    private toDosService: ToDosService,
    private location: Location) { }

  ngOnInit(): void {

    this.spinner.show();
    this.workLogForm = this.fb.group({
      ID: [''],
      Name: [''],
      todo: [null, Validators.required],
      date: ['', Validators.required],
      hours: ["", [Validators.required, Validators.max(24), Validators.min(0)]],
      description: ['', Validators.required],
      loggedBy: [null],
      status: [null],
      Project: [null,],
      approvedRejectedBy: [''],
      reasonForRejection: [''],
      approvedRejectedOn: [''],
      worklogStatus: ['']
    });
    this.typeOfId = this.route.snapshot.paramMap.get('type');

    // getting to-do id from url for creating a new worklog for that particular to-do.
    if (this.typeOfId == 'AddWork') {
      this.showDataFlag = false
      this.toDoId = this.route.snapshot.paramMap.get('id');
    }



    // getting worklog id from url for updating particular worklog.
    else if (this.typeOfId == 'EditWork') {
      this.showDataFlag = true
      this.workLogId = this.route.snapshot.paramMap.get('id');
    }

    // Set maxDate to today’s date
    this.maxDate = moment().format('YYYY-MM-DD');

    // Set minDate to one week before today
    this.minDate = moment().subtract(6, 'days').format('YYYY-MM-DD');

    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  //*********************** Get APIs Begin *****************************

  async getData() {

    // initialising data here so as to call this function only for updating the to do.
    this.addFlag = true;
    this.empData = new EmployeeDataModel();
    this.workFormSubmitted = false;
    await this.storage.get('empDetails').subscribe((empWorkRequest: EmployeeDataModel) => {

      this.empData = empWorkRequest.userProfile;

      // If Soluzione Emp/Manager logged in
      this.isManager = this.empData?.isManager;
      this.empId = this.empData?.userId

      // for add form
      if (this.typeOfId == 'AddWork') {

        this.getToDosDataToUpdate();
        this.searchToDos();
        //this.searchToDos(0);
      }

      // for edit form
      else if (this.typeOfId == 'EditWork') {
        this.addFlag = false;
        this.getWorkLogsById();

      }

      //else {
      //  this.searchToDos(1);
      //}

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // getting data for adding a work log for a particular to do.
  //async searchToDos(flag: number, event?: any) {
  async searchToDos() {

    //if (flag == 0) {
    this.toDosList = [] as Array<ToDosDataModal>;
    //await this.toDosService.getToDoTasksByToDoIdAndIsManager(this.toDoId, this.isManager, flag).subscribe((searchRes: any) => {
    await this.toDosService.getToDoTasksByToDoIdAndIsManager(this.toDoId, this.isManager).subscribe((searchRes: any) => {
      if (searchRes.ResponseCode == 100) { // success

        this.toDosList = searchRes.Data;

        //project name 

        // setting today's date initially.
        var t = new Date();
        var today = moment(t).format('YYYY-MM-DD');
        this.workLogForm.patchValue({
          date: today,
          todo: this.toDosList[0].itemNo + " - " + this.toDosList[0].title,
          Project: this.toDosList[0].projectName,
          // Project: this.workLogObj.project,

        });
      }

      else if (searchRes.ResponseCode == 999) {
        this.toastr.error(searchRes.Message, 'Error');
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //else if (flag == 1) {
  //  this.spinner.hide();

  //}
  //}

  // getting data for updating an already existing work log.
  async getWorkLogsById() {

    this.workLogObj = new WorkLogDataModel();
    this.toDosService.getWorkLogById(this.workLogId).subscribe((editRes: any) => {

      if (editRes.ResponseCode == 100) { // success

        this.workLogObj = editRes.Data;
        this.workLogForm.patchValue({
          ID: this.workLogObj.ID,
          date: this.workLogObj.date,
          todo: this.workLogObj.todo?.itemNo + " - " + this.workLogObj.todo?.title,
          hours: this.workLogObj.hours,
          description: this.workLogObj.description,
          loggedBy: this.workLogObj.loggedBy,
          Project: this.workLogObj.project,
          reasonForRejection: this.workLogObj.reasonForRejection,
          approvedRejectedBy: this.workLogObj.approvedRejectedBy,
          approvedRejectedOn: this.workLogObj.approvedRejectedOn,
          worklogStatus: this.workLogObj.worklogStatusName

        });
        //show hide button using status id
        if (this.workLogObj.worklogStatus === 674180000) {
          this.saveButtonFlag = true;
          this.withdrawButtonFlag = false;
          this.submitApprovalButtonFlag = true;
          this.addFlag = true;
        }
        else if (this.workLogObj.worklogStatus === 674180003) {
          this.saveButtonFlag = true;
          this.withdrawButtonFlag = false;
          this.submitApprovalButtonFlag = true;
          this.addFlag = true;
          this.rejectedStatusFlag = true;
          this.approvedStatusFlag = false;
        }

        else if (this.workLogObj.worklogStatus === 674180002) {
          this.saveButtonFlag = false;
          this.withdrawButtonFlag = false;
          this.submitApprovalButtonFlag = false;
          this.addFlag = false;
          this.approvedStatusFlag = true;
          this.rejectedStatusFlag = false;
        }
        else if (this.workLogObj.worklogStatus === 674180004) {
          this.saveButtonFlag = false;
          this.withdrawButtonFlag = false;
          this.submitApprovalButtonFlag = false;
          this.addFlag = false;
        }
        else if (this.workLogObj.worklogStatus === 674180001) {
          this.saveButtonFlag = false;
          // this.withdrawButtonFlag = true;
          this.submitApprovalButtonFlag = true;
          this.addFlag = true;
        }
      }

      else if (editRes.ResponseCode == 999) {
        this.toastr.error(editRes.Message, 'Error');
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //*********************** Get APIs End *****************************

  //Worklog Form Control
  get wlf() {
    return this.workLogForm.controls;
  }
  modelValidate() {
    this.workFormSubmitted = true
    // this.spinner.show();
    if (this.workLogForm.invalid) {
      this.spinner.hide();
      return
    }
  }

  async onSubmit(worklogStatus) {
    this.workFormSubmitted = true
    this.spinner.show();
    if (this.workLogForm.invalid) {
      this.spinner.hide();
      return
    }

    this.workLogForm.value.loggedBy = new Object();
    this.workLogForm.value.loggedBy.ID = this.empData.userId;
    this.workLogForm.value.loggedBy.Name = this.empData.fullName;
    // this.workLogForm.value.loggedBy.project = this.empData.project;

    this.workLogForm.value.hours = parseFloat(this.workLogForm.value.hours);

    if (this.typeOfId == 'AddWork') {
      // creating new work log 
      this.workLogForm.value.date = moment(this.workLogForm.value.date).format('YYYY-MM-DD');
      this.workLogForm.value.todo = new Object();
      this.workLogForm.value.todo = this.toDosList[0];
      // if (worklogStatus == 674180001) {


      //   await this.toDosService.checkExistingWorkLog(this.toDoId, this.workLogForm.value.date, this.workLogForm.value.loggedBy.ID).subscribe((chkRes: any) => {


      //     //checking if the result contain data, or having some error.
      //     if (chkRes.ResponseCode == 100) { // success

      //       //If true, it means no existing worklog on the todo on same date by the same employee
      //       if (chkRes.Data) {

      //         this.workLogForm.value.worklogStatus = worklogStatus;
      //         this.workLogForm.value.projectId = this.ProjectId;
      //         this.saveLog();
      //       }

      //       else {
      //         //Show Notification on the exiting work log on same date on same todo by the same employee
      //         this.toastr.error("You have already logged time for this ticket on this date. Please update the previous record.", 'Failure', {
      //           timeOut: 5000
      //         });
      //         this.spinner.hide();

      //       }
      //     }

      //     else if (chkRes.ResponseCode == 999) {
      //       this.toastr.error(chkRes.Message, 'Error');
      //     }
      //   }, error => {
      //     this.spinner.hide();
      //     this.toastr.error(error.message);
      //   });
      // }
      this.workLogForm.value.worklogStatus = worklogStatus;
      this.workLogForm.value.projectId = this.ProjectId;
      this.saveLog();
    }

    else {
      // updating existing work log

      this.workLogForm.value.todo = new Object();
      this.workLogForm.value.todo = this.workLogObj.todo;
      if (worklogStatus == 674180001) {
        this.workLogForm.value.worklogStatus = worklogStatus
        //Show Notification when you click on submitt for approval Are you want to sure submitt for this approval after this the task will locked
        //  this.toastr.success(" Are you want to sure submitt for this approval after this the task will locked", 'Confirmation', {
        //   timeOut: 5000
        // });

      }

      else if (worklogStatus == 674180000) {

        this.workLogForm.value.worklogStatus = worklogStatus
        //  this.submitApprovalButtonFlag=true;
      }
      this.saveLog();
    }
  }

  async saveLog() {

    await this.toDosService.saveWorkLog(this.workLogForm.value).subscribe((saveRes: any) => {

      this.spinner.hide();
      if (saveRes.ResponseCode == 100) { // success
        this.toastr.success(saveRes.Message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });

        this.submitApprovalButtonFlag = true;
        this.saveButtonFlag = false;
        if (this.typeOfId == "AddWork") {

          this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.toDoId]);
        }

        else {
          this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.workLogObj.todo?.ID]);
        }
      }

      else if (saveRes.ResponseCode == 999) {
        this.toastr.error(saveRes.Message, 'Error');
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getToDosDataToUpdate() {

    this.supportReqService.getToDosDetailsForUpdation(this.toDoId, this.contactLoggedIn).subscribe((toUpRes: any) => {
      if (toUpRes.ResponseCode == 100) { // success
        this.worklogResp = toUpRes.Data
        this.ProjectId = toUpRes.Data.projectId;
        this.ProjectName = toUpRes.Data.projectName;
      }
    })
  }
  backBtn() {
    if (window.history.length > 1) {
      this.location.back()
    } else {
      if (this.typeOfId == "AddWork") {

        this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.toDoId]);
      }

      else {
        this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.workLogObj.todo?.ID]);
      }
    }
    // this.router.navigate(['ESSPortal/dashboard']);
    // this.location.back();
  }

}


