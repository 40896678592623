import { Component, NgZone, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataModel } from '../../../_models/user-data-model';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-generate-password',
  templateUrl: './generate-password.component.html',
  styleUrls: ['./generate-password.component.scss']
})
export class GeneratePasswordComponent implements OnInit {

  userObj: UserDataModel;
  message: string = "";
  VerifyForgotPasswordId: string;
  email: any;
  passwordMismatch: boolean = false;
  ispasswordMismatch: boolean = false

  constructor(private HttpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public toastr: ToastrService,
    private ngZone: NgZone) {
    

    if (this.route.snapshot.paramMap.get('id')) {
      this.VerifyForgotPasswordId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    
    this.userObj = new UserDataModel();
    this.VerifyForgotPasswordLink()
  }

  checkPasswordMatch() {
    
    if (this.userObj.password && this.userObj.newpassword) {
      this.ispasswordMismatch = this.userObj.password !== this.userObj.newpassword;

    }
  }

  VerifyForgotPasswordLink() {

    this.authService.VerifyForgotPasswordLink(this.VerifyForgotPasswordId).subscribe((resp: any) => {
      if (resp.isSuccessful == true) {
        

        console.log('VerifyForgotPasswordIdresp', resp)
        this.email = resp.data.email;
        this.userObj.email = resp.data.email;
        this.userObj.Name = resp.data.fullName
        this.toastr.info(resp.messageDetail.message, 'Info', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
      }
      else if (resp.isSuccessful === false) {
        this.toastr.error(resp.messageDetail.message, 'Error', {
          positionClass: 'toast-top-full-width',
          timeOut: 3000,
        });

        setTimeout(() => {
          this.ngZone.run(() => {
            this.router.navigate(['/login']);
          });
        }, 3000); // Wait for the toast message timeout duration (3 seconds)
      }

    })
  }

   // Custom Password Validation for complexity
   validatePasswordComplexity(password: string): boolean {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,20}$/;
    return regex.test(password);
  }
  forgotPassword(forgotForm: NgForm) {
    this.passwordMismatch = this.ispasswordMismatch;
 
    // Ensure the password matches the complexity requirements
    if (!this.validatePasswordComplexity(this.userObj.password)) {
      this.toastr.error('Password must contain an uppercase letter, a lowercase letter, a number, a special character, and be 8-20 characters long.', 'Error', {
        positionClass: 'toast-top-full-width',
        timeOut: 5000,
      });
      return;
    }
 
    if (this.passwordMismatch === true) {
      return;
    }

      
      var data = forgotForm.value;
      this.userObj.email = data.email;
      this.userObj.password = data.password;
      let obj = {
        email: this.userObj.email,
        newPassword: this.userObj.password
      }
      if (this.email) {
        this.authService.generatepassword(obj).subscribe((res: any) => {
          if (res.isSuccessful == true) {
            this.toastr.info(res?.messageDetail?.message, 'Info', {
              positionClass: 'toast-top-full-width',
              timeOut: 3000
            });
            // Set a 5-second delay before navigating to login
            setTimeout(() => {
              this.router.navigate(['/login'])
            }, 3000); // 5000 milliseconds = 5 seconds
            // this.router.navigate(['/login'])
          }
          else if (res.isSuccessful == false) {
            this.toastr.error(res?.messageDetail?.message, 'Error');
          }

        }, error => {
          this.toastr.error(error?.error?.messageDetail?.message , "Error");
        });
      }
      else {
        this.toastr.error('Somthing Went Wrong', 'Error')
      }


    }
  }
