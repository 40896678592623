import { Component, OnInit } from '@angular/core';
import { AlertConfig } from 'ngx-bootstrap/alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../_services/dashboard.service';


export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: 'success' });
}

@Component({
  selector: 'app-dashboard-soluzione-holidays',
  templateUrl: './dashboard-soluzione-holidays.component.html',
  styleUrls: ['./dashboard-soluzione-holidays.component.css'],
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }]
})


export class DashboardSoluzioneHolidaysComponent implements OnInit {
  holidayList: Array<any> = [];
  alertFlag: boolean = false;
  alerts: any = [
    {
      type: 'info',
      msg: `No holidays to display`
    },
  ]

  constructor(public dashboardService: DashboardService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.spinner.show();
    this.getSolzHolidayList();
  }
 
  async getSolzHolidayList() {
    this.dashboardService.getSoluzioneHolidays().subscribe((holidays: any) => {

      if (holidays.isSuccessful == true) // success
      {
        this.alertFlag = false;
        this.holidayList = holidays.data;
      }
      else if (holidays?.messageDetail?.message_code == 217) {
        this.alertFlag = true;
      }

      else if (holidays?.messageDetail?.message_code == 999) {
        // this.toastr.error(holidays.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error?.error.messageDetail?.message);
    });
  }
}
