import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharepointServiceService {

  apiURL: string = "";
  authapiURL: string = "";

  constructor(
    public HttpClient: HttpClient,
  ) {
    this.apiURL = environment.apiURL
    this.authapiURL = environment.authapiURL
  }

  GetAttachmentFromSharePoint(data: string) { //post
    return this.HttpClient.get(this.authapiURL + "Sharepoint/GetAttachmentFromSharePoint?entityId=" + data + '&'+ 'entityName=solz_supportrequest');
  }
    //CRM Attach File In SharePoint
    AttachFileInSharePoint(uploadFileModel: any) { // get
      return this.HttpClient.post(this.authapiURL + 'Sharepoint/AttachFileInSharePoint', uploadFileModel);
    }
}
