import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../_services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-soluzione-directory',
  templateUrl: './soluzione-directory.component.html',
  styleUrls: ['./soluzione-directory.component.scss']
})
export class SoluzioneDirectoryComponent implements OnInit {
  birthdayList: Array<any>;
  ToggleFlag: boolean;

  constructor(public dashboardService:DashboardService,
    public toastr:ToastrService,
    private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getEmployeeBirthdays();
}

getEmployeeBirthdays() {
  this.dashboardService.getUpcomingEmployeeBirthdays().subscribe((birthdays: any) => {
    if (birthdays.isSuccessful == true) { //success
       
      this.birthdayList = birthdays.data;
      this.spinner.hide();
      //sorting data comes from API in asc order By Name
      this.birthdayList.sort((a,b) => a.fullName.localeCompare(b.fullName))
    }
    else if (birthdays?.messageDetail?.message_code == 999) {
      this.toastr.error(birthdays?.messageDetail?.Message, 'Error');
    }
  }, error => {
    this.spinner.hide();
    this.toastr.error(error?.error?.messageDetail?.message);
  });
}
filterGlobal(){
  
}
      //Toggle for Expand And collapsed Table Row Data
      showHideToggle(){
        this.ToggleFlag=!this.ToggleFlag;
     }

}
