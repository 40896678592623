import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employee-goals-list',
  templateUrl: './employee-goals-list.component.html',
  styleUrls: ['./employee-goals-list.component.scss']
})
export class EmployeeGoalsListComponent implements OnInit {
  // id = '40778310-a909-ed11-82e5-000d3a8197f5'
  empId: any;
  EmployeeGoalslist: any;
  userData: any; //used to get employee data from auth service to retrieve ID.
  updateGoalsId: string;
  updateGoalsdata: any;
  empGoalsForm: FormGroup;
  ToggleFlag: boolean;

  constructor(
    private router: Router,
    private PerformanceService: PerformanceReviewService,
    public storage: StorageMap,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.empId = localStorage.getItem("empId")
  }

  ngOnInit(): void {
    this.spinner.show();

    this.GetGoalListsById()


  }

  GetGoalListsById() {
    this.spinner.show();
    this.PerformanceService.GetGoalListsById().subscribe((Responcelist: any) => {
      if (Responcelist.isSuccessful == true) {

        this.EmployeeGoalslist = Responcelist.data
        this.spinner.hide();

      }

      else if (Responcelist.isSuccessful == false) {
        if (Responcelist?.messageDetail?.message_code != 204) {

          this.toastr.error(Responcelist.Message, 'Error');
        }
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })
  }


  goalsDetails(id: any) {
    this.router.navigate(['/ESSPortal/update-employee-goals/' + id]);
  }
  addgoalsDetails() {
    this.router.navigate(['/ESSPortal/add-employee-goals/']);
  }

  submit() {
    this.router.navigate(['/ESSPortal/add-employee-goals']);
  }
  //Toggle for Expand And collapsed Table Row Data
  showHideToggle() {
    this.ToggleFlag = !this.ToggleFlag;
  }
}
