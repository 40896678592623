import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  apiURL: string = "";
  public leaveModel: any;
  authapiURL: string = "";

  private leaveObj = new BehaviorSubject<any>(null);
  latestLeaveObj = this.leaveObj.asObservable();
  //public leaveMonth: number;
  //public leaveYear: number;

  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
    this.authapiURL = environment.authapiURL;
  }

  updateLeaveRecord(leaveObj: any) {
    this.leaveObj.next(leaveObj);
  }

  // retreiving applied leaves list.
  getAppliedLeavesList(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'LeaveRecords/AppliedLeaveRecordList' );
  }

  // retreiving accepted leaves list.
  getAcceptedLeavesList(): Observable<any> {
    return this.HttpClient.get(this.authapiURL+ 'LeaveRecords/AcceptedLeaveRecordList' );
  }

  // applying for new leave.
  postNewRequest(leaveModel: any) {
    return this.HttpClient.post(this.authapiURL + 'LeaveRecords/ApplyNewLeaveRequest', leaveModel);
  }

  // canceling a leave request.
  postCancelLeave(leaveRecord: any) {
    return this.HttpClient.post(this.authapiURL + 'LeaveRecords/CancelLeaveRequest', leaveRecord);
  }

  // retrieve leave balance.
  getLeaveBalanceList(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'LeaveRecords/LeaveBalanceRecordList' );
  }

  // retrieve detail of a particular leave.
  getLeaveBalanceDetails(id: any): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'LeaveRecords/LeaveBalanceMonthlyRecordList?EmpYearRecordId=' + id);
  }

  // posting attendance dm and in return retreiving the list of a particular month and year.
  postAttendanceList(attendanceModel: any) {
    return this.HttpClient.post(this.authapiURL + 'EmployeeAttendance/EmployeeAttendanceList', attendanceModel); 
  }

  // retrieving break list.
  getBreakList(): Observable<any> {
    //here although we are retrieving data but still we do that by post method.
    return this.HttpClient.post(this.authapiURL + 'LeaveRecords/GetEmloyeeBreaKLogsByEmployeeId',  {});
  }

  // posting a new break.
  postNewBreak(breakModel: any) {
    return this.HttpClient.post(this.authapiURL + 'LeaveRecords/SaveEmployeeBreakLog', breakModel);
  }

  postQueryForAttendance(obj){
    return this.HttpClient.post(this.authapiURL + 'EmployeeAttendance/CreateAskQueryForAttendance' , obj)
  }

  GetAttendanceQuery(id){

    return this.HttpClient.get(this.authapiURL + 'EmployeeAttendance/GetAttendanceQuery?AttendanceRecId=' + id)
  }
}