import { BaseEntityDataModel } from './base-entity-data-model';

export class UserDataModel {
  ID: string;// created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  Name: string; // created by Udbhav for API DM compatability as the DM there inherits Base Entity.

  email: string; 
  fullName: string;
  firstName: string;
  lastName: string;
  passwordSalt: string;
  passwordHash: string;
  oldpassword: string;
  newpassword: string;
  password: string;
}
