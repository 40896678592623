import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportRequestService {
  apiURL: string = "";

  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
  }


  postdeleteTimeSheet(supportReqModel: any) {
    return this.HttpClient.post(this.apiURL + 'SupportRequest/DeleteTimeSheet/', supportReqModel);
  }

  // getting employee's project list.
  getProjectListRec(manager: any) {
    return this.HttpClient.get(this.apiURL + 'SupportRequest/GetProjectsListRecords/' + manager);
  }


  // getting option sets for new and edit Todos Page by sending type of list string.
  getDropDownData(dropDownType: string) {
    return this.HttpClient.get(this.apiURL + 'SupportRequest/SetDropdownforCRM?dropDownOf=' + dropDownType);
  }

  getAllEmployeeData() {
    return this.HttpClient.get(this.apiURL + 'SupportRequest/GetAllEmployeeDetailsLists/');
  }

  postNewToDo(supportReqModel: any) {
    return this.HttpClient.post(this.apiURL + 'SupportRequest/CreateNewItem', supportReqModel);
  }

  // postUploadFilesWithToDo(uploadFileModel: any) {
  //   return this.HttpClient.post(this.apiURL + 'SupportRequest/AttachFileInNote/', uploadFileModel);
  // }
  // //CRM Attach File In SharePoint
  // postUploadFilesWithToDos(uploadFileModel: any) {
  //   return this.HttpClient.post(this.apiURL + 'SupportRequest/AttachFileInSharePoint/', uploadFileModel);
  // }

  getToDosDetailsForUpdation(toDoId: string, isContactFlag: boolean) {

    return this.HttpClient.get(this.apiURL + "SupportRequest/SupportRequestFormRecord/?id=" + toDoId + "&loggedInByContact=" + isContactFlag);
  }

  // getToDosAttachmentsFromCRM(toDoId: string) {
  //   return this.HttpClient.get(this.apiURL + "SupportRequest/GetAttachmentFromCRMNote/" + toDoId);
  // }
  //GET CRM Attach File In SharePoint
  // getToDosAttachmentsFromCRMSharepoint(toDoId: string) {
  //   return this.HttpClient.get(this.apiURL + "SupportRequest/GetAttachmentFromSharePoint/?id=" + toDoId + '&'+ 'entityName=solz_supportrequest');
  // }
  //Getting Project Features Hierirchal
  // getProjectsfeatures(_projectId:any, filterId){
  //   return this.HttpClient.get(this.apiURL +"SupportRequest/GetProjectsfeatures?projectId=" +_projectId+"&dataFilter="+filterId)
  // }
  getProjectsfeatures(id, filterId) {
    return this.HttpClient.get(this.apiURL + "SupportRequest/GetProjectsfeatures?projectId=" + id + "&dataFilter=" + filterId);
  }

  //get All userstory in parent field sprint based
  getAllProjectUserStories(id) {
    return this.HttpClient.get(this.apiURL + "SupportRequest/GetAllProjectUserStories?projectId=" + id)
  }

  GetTodosByLinkedTaskIdOnBug(id) {
    return this.HttpClient.get(this.apiURL + "SupportRequest/GetTodosByLinkedTaskId/?LinkedTaskId=" + id)
  }

 
}
