import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { OptionSetDataModels } from '../../../_models/optionSetDataModel-model';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-todos-bugs',
  templateUrl: './todos-bugs.component.html',
  styleUrls: ['./todos-bugs.component.scss']
})
export class TodosBugsComponent implements OnInit {
  supportReqObj: SupportRequestDataModel;
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  endDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  solzStatusList: Array<OptionSetDataModel> = [];
  solzStatusChangeList: Array<OptionSetDataModels> = [];
  workStatusList: Array<OptionSetDataModels> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  downloadFileList: Array<UploadFileDataModel> = [];
  updateToDoId: string = "";
  lockStartDate: boolean = false; // used for locking start date form field if not having null or null date
  lockEndtDate: boolean = false; // used for locking start date form field if not having null or null date 
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList = [];
  linkedTaskFlag: boolean = false;
  upLoadImageFileList: Array<UploadFileDataModel> = [];

  // used for Asign Me As Option Set.
  assignMeList: any[] = [];
  assign: any;
  // used for work logs list
  workLogsList: Array<WorkLogDataModel> = [];
  showHideWorklogFlag: boolean = false;
  showHideWorklogDataFlag: boolean = false;
  showHideBugGridFlag: boolean = false;

  // for update form
  lockFields: boolean = false;
  implementationEffortFlag: boolean;
  expectedDateFlag: boolean;
  projectName: string;
  userStory: any;
  isBugCheck: any;
  updateToDoisBug: string;
  updateToDouserPriority: string;
  updateToDoprojectName: string;
  updateToDosprintName: string;
  isBugTodos: any;
  updateToDolinkedTaskName: string;
  bugTodoId: any;
  showBugButtonFlag: boolean = false;
  bugListData: any;
  edit: string = null;
  srNumberItemno: string;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  // searchFilterVal: any;
  viewImageSrc = null;
  tempImgArray = [];
  worklogRecordId: any;
  deteleworklogRow: any;
  filteredDuplicateLinkedTaskList: any[] = [];  // Array to hold the filtered items
  parentId: any[];
  UserStorydataCheck: any
  msg1: boolean;
  linkedDuplicateTasksList: any[] = [];
  allEmployeeList: any;
  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public sharepointServices: SharepointServiceService,
    private router: Router,
    private MasterDropdownServiceService: MasterDropdownServiceService,
    private renderer: Renderer2, private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private location: Location) { }

  ngOnInit(): void {

    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateToDoId = this.route.snapshot.paramMap.get('id');
    }
    this.supportReqForm = this.fb.group({
      ID: [''],
      Name: [''],
      createdon: [''],
      expectedResDate: [null],
      endDate: [null],
      createdOn: [null],
      ItemId: [''],
      itemNo: [''],
      title: ['', [Validators.required]],
      projectName: ['', [Validators.required]],
      projectId: [null,],
      itemDescription: ['', [Validators.required]],
      reproSteps: [''],
      parentId: [null],
      parentItemNo: [''],
      parentTitle: [''],
      userPriority: [null],
      userPriorityName: [null, [Validators.required]],
      isClientReported: ["false", [Validators.required]],
      assigneeId: [null],
      assigneeName: [''],
      implementationeffort: [0, [Validators.required]],
      comment: [''],
      createdBy: [''],
      CreatedBy: [''],
      startDate: [null],
      itemType: ["Bug", [Validators.required]],
      // linkedTask:[, [Validators.required]],
      linkedTaskName: [''],
      // ItemId:['']
      userStoryTitleAndName: ['', [Validators.required]],
      linkedTaskTitleAndItemNo: [''],
      // analyzingEstimation: [null,]

    });

    this.getMasterUserPriorityDropdown();
    this.getData(); // getting data from local storage api and setting it in our local variable.
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');

  }




  //*********************** Get APIs Begin *****************************
  //********************* To Dos Begin********************

  async getData() {
    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.endDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;
    this.lockStartDate = false;
    this.lockEndtDate = false
    this.lockFields = false;
    this.implementationEffortFlag = false;
    this.expectedDateFlag = false;
    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {

      this.empData = empNewRequest.userProfile;

      // If Soluzione Emp/Manager logged in
      if (this.empData.userId) {

        this.contactLoggedIn = false;
        this.isManager = this.empData?.isManager;
        this.empId = this.empData?.userId

        this.getToDosDataToUpdate();

      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  // for To Do update.
  async getToDosDataToUpdate() {
    this.supportReqObj = new SupportRequestDataModel();
    this.assignMeList = [{ Label: "Assignee", Value: 0 }
      // , { Label: "Assigned Reviewer", Value: 1 }
    ];
    this.toDosService.GetUserStoryDetailsByUserStoryId(this.updateToDoId).subscribe((toUpRes: any) => {

      if (toUpRes.isSuccessful == true) {   // success 
        this.copyPastImageDuplicate();
        this.supportReqObj = toUpRes.data;

        this.projectName = this.supportReqObj.projectName;
        // this.isBugCheck = this.supportReqObj.isBug;
        this.srNumberItemno = toUpRes.data.itemNumber
        // filling form fields.
        this.supportReqForm.patchValue({
          ID: toUpRes.data.id,
          ItemId: toUpRes?.data?.id,
          // assigneeId: toUpRes?.data?.assignee?.id,
          // assigneeName: toUpRes?.data?.assignee?.name,
          // comment: toUpRes?.data?.comments,
          // endDate: toUpRes?.data?.plannedEndDate,
          // implementationeffort: this.supportReqObj?.implementationeffort,
          isClientReported: this.supportReqObj?.isClientReported.toString(),
          // itemDescription: toUpRes?.data?.description,
          itemNo: this.supportReqObj?.itemNumber,
          projectId: this.supportReqObj?.projectId,
          projectName: this.supportReqObj?.projectName,
          // startDate: toUpRes?.data?.plannedStartDate,
          // solzWorkStatus: toUpRes?.data?.workStatus?.value,
          // title: this.supportReqObj?.title,
          userPriority: toUpRes?.data?.userPriority?.value,
          userPriorityName: toUpRes?.data?.userPriority?.label,
          // itemType: toUpRes?.data?.itemType?.label,
          userStory: this.supportReqObj.userStory,
          userStoryTitleAndName: toUpRes?.data?.userStoryTitle
        });

        this.GetEmployeeByProjectId(this.supportReqObj?.projectId)


        if (this.supportReqForm.value.isClientReported) {
          this.supportReqForm.value.isClientReported = "true";
        }

        else {
          this.supportReqForm.value.isClientReported = "false";
        }

      }

      else if (toUpRes.isSuccessful == false) {
        this.toastr.error(toUpRes?.messageDetail?.message, 'Error');
        this.spinner.hide();

      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.message.message);
    });
  }


  async getMasterUserPriorityDropdown() {
    await this.MasterDropdownServiceService.getMasterDropdown('UserPriority').subscribe((priorityRes: any) => {
      if (priorityRes.isSuccessful == true) {

        this.userPriorityList = priorityRes?.data;
      }
      else if (priorityRes.isSuccessful == false) {
        this.spinner.hide()
        if (priorityRes?.messageDetail?.message_code != 204) {
          this.allEmployeeList = []
          this.toastr.error(priorityRes?.messageDetail?.message)
        }
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })
  }

  async GetEmployeeByProjectId(id) {
    await this.toDosService.GetEmployeeByProjectId(id).subscribe((allEmpRes: any) => {
      if (allEmpRes.isSuccessful == true) {

        this.allEmployeeList = allEmpRes?.data;
      }
      else if (allEmpRes.isSuccessful == false) {
        this.spinner.hide()
        if (allEmpRes?.messageDetail?.message_code != 204) {
          this.allEmployeeList = []
          this.toastr.error(allEmpRes?.messageDetail?.message)
        }
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })
  }



  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }


  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }



  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }

  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    // Find the first invalid control
    const invalidControl = Object.keys(form.controls).find(key => {
      return form.get(key)?.invalid;
    });

    if (invalidControl) {
      const invalidElement = document.querySelector(`[formControlName="${invalidControl}"]`);

      if (invalidElement) {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (invalidElement as HTMLElement).focus();
      }
    }
  }

  //********************************************** for autoResize Text Area ***************************************  */
  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    // Reset the height to recalculate
    textarea.style.height = 'auto';
    // Set the height to fit the content
    textarea.style.height = `${textarea.scrollHeight}px`;
  }


  copyPastImageDuplicate() {
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');
    document.getElementById('commentDiv').addEventListener(
      'keydown', (e: KeyboardEvent) => {
        if (!((e.ctrlKey && (e.key == 'v' || e.key == 'V')) || e.code == 'Backspace')) e.preventDefault();
      }
    )

    this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(ce, button);
            }
          }
        }
        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        if (files.length > 0) {
          this.pastAttachments(mockEvent); // Call selectAttachments with the mock event
        }

      }
    });
  }


  adjustHeight(event: Event) {
    const element = event.target as HTMLElement;
    element.style.height = 'auto'; // Reset height
    element.style.height = element.scrollHeight + 'px'; // Set new height
  }
  
  copypastImageData() {
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');




    this.renderer?.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const span = this.renderer.createElement('span');
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              const closeButton = this.renderer.createElement('a');
              closeButton.setAttribute('style', 'cursor: pointer; color: red');
              this.renderer.setAttribute(closeButton, 'contenteditable', 'false');
              this.renderer.listen(closeButton, 'click', () => this.deleteImage(fileName));
              const iconClose = this.renderer.createElement('i');
              iconClose.setAttribute('style', 'color: red');
              this.renderer.addClass(iconClose, 'fa');
              this.renderer.addClass(iconClose, 'fa-regular');
              this.renderer.addClass(iconClose, 'fa-window-close');
              this.renderer.addClass(iconClose, 'mx-2');
              i += 1;
              // const textbutton = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(span, button);
              this.renderer.appendChild(closeButton, iconClose);
              // this.renderer.appendChild(closeButton, textbutton);
              this.renderer.appendChild(span, closeButton);
              console.log(span)
              this.renderer.appendChild(ce, span);
            }
          }
        }

        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        // if (files.length > 0) {
        //   this.selectAttachments(mockEvent); // Call selectAttachments with the mock event
        // }
      }
    });
  }

  pastAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadImageFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  deleteImage(fileName) {
    console.log(fileName)
    const element = document.querySelector('#commentDiv')
    const node = Array.from(element.children).find(el => el.textContent.includes(fileName));
    node.remove();
  }



  downloadImg(fileName: string, template) {
    debugger
    this.viewImageSrc = this.tempImgArray.find(img => img.fileName == fileName);
  }

  async downloadPreviewImage() {
    debugger
    let FileSaver = await import("file-saver");
    FileSaver.saveAs(this.viewImageSrc.base64, this.viewImageSrc.fileName);
  }

  viewImage(file: any) {
    console.log("File Data:", file);

    if (file && this.isImageFile(file.fileName)) {
      let imageSrc = '';

      // Check if 'bytes' contains Base64 data
      if (file.bytes && !file.bytes.startsWith('data:image')) {
        imageSrc = `data:image/png;base64,${file.bytes}`;
      }
      // If Base64 data is missing, use fileUrl (server path)
      else if (file.fileUrl) {
        imageSrc = file.fileUrl;
      }
      // If neither is available, show an error
      else {
        alert("No valid image data found!");
        return;
      }

      // Assign to viewImageSrc
      this.viewImageSrc = {
        base64: imageSrc,
        fileName: file.fileName
      };

      console.log("Updated viewImageSrc:", this.viewImageSrc);
    } else {
      alert("This file is not an image.");
    }
  }


  // Function to check if the file is an image
  isImageFile(fileName: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);
  }

  onSubmit() {
    debugger
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      this.scrollToFirstInvalidControl(this.supportReqForm)

    }
    this.spinner.show();
    this.supportReqFormSubmitted = true;


    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      return;
    }


    let bugObsect = {
      createdBy: this.empId,
      title: this.supportReqForm.value.title,
      projectId: this.supportReqObj.projectId,
      userStoryId: this.updateToDoId,
      assigneeId: this.supportReqForm.value.assigneeId,
      itemDescription: this.supportReqForm.value.itemDescription,
      userPriority: { value: this.supportReqForm.value.userPriority, label: "" },
      comment: null,
      plannedStartDate: this.supportReqForm.value.startDate,
      plannedEndDate: this.supportReqForm.value.endDate,
      implementationEffort: this.supportReqForm.value.implementationeffort,
      reproSteps: this.supportReqForm.value.reproSteps
    }
    this.toDosService.createNewBug(bugObsect).subscribe((updateRes: any) => {


      if (updateRes.isSuccessful == true) { // success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = this.updateToDoId;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }
        else if (this.upLoadImageFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadImageFileList.length; i++) {
            this.upLoadImageFileList[i].ID = this.updateToDoId;
          }

          var attachFiles = { itemDetails: this.upLoadImageFileList }
          this.uploadFiles(attachFiles);
        }



        // else just show message.

        this.toastr.success(updateRes?.messageDetail?.message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + updateRes.data]);

        if (this.upLoadFileList.length == 0) {
          this.spinner.hide();
          // this.getData();

        }
      }

      else if (updateRes.isSuccessful == false) {
        this.toastr.error(updateRes?.messageDetail?.message, 'Error');
        this.spinner.hide();

      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });


  }

  async uploadFiles(attachFiles: any) {
    await this.sharepointServices.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        // this.spinner.hide();
        // this.getData();
        // this.getData();
        this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + attachFiles?.itemDetails[0]?.ID]);

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  download(file: any) {
    debugger

    import("file-saver").then(FileSaver => {
      var contentbase64 = file.bytes;
      var contenttype = file.fileType;
      var fileFormat = "data:" + contenttype + ";base64," + contentbase64;
      //download file
      FileSaver.saveAs(fileFormat, file.fileName);
    });


  }

  back() {
    if (window.history.length > 1) {
      this.location.back()
    }
    else {
      this.router.navigate[('/ESSPortal/to_dos/my_to_dos')]
    }
  }

}

