import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, ObservedValueOf } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentInvoiceService {
  apiURL: string = "";

  constructor(public HttpClient: HttpClient,) {
    this.apiURL = environment.apiURL;
  }

  getActivePaymentInvoice(): Observable<any> {

    return this.HttpClient.get(this.apiURL + 'Invoice/GetActivePayment/');

  }

  GetBilledNewPartiallyPaidInvoiceLists(): Observable<any> {

    return this.HttpClient.get(this.apiURL + 'Invoice/GetBilledNewPartiallyPaidInvoiceLists/payment');
    // return this.HttpClient.get(this.apiURL + 'Invoice/GetBilledNewPartiallyPaidInvoiceLists?id=invoicePayment');

  }

  GetPaymentById(id:any) {

    return this.HttpClient.get(this.apiURL + 'Invoice/GetPaymentByID/'+id);

  }

  postMultiInvoicePayment(obj:any){

    return this.HttpClient.post(this.apiURL + 'Invoice/MultiInvoicePayment', obj);

  }

  //sarary slip API
  employeeMonthlySalary(id:any){
    return this.HttpClient.get(this.apiURL + 'EmployeeMonthlySalary/EmployeeSalarySlipDetails?Id=' + id)
  }

  downloadSelerlySlip(id:any){
    return this.HttpClient.get(this.apiURL + 'EmployeeMonthlySalary/GetPDFReport?Id=' + id)
  }



}
