import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthguardService } from './authguard.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private authGuardService: AuthguardService,
              private router: Router) {

  }

  // canActivate(): boolean { 
  //   if (!this.authGuardService.getToken()) {
  //     this.router.navigateByUrl("/login");
  //   }
  //   return this.authGuardService.getToken();
  // }

  canActivate(): boolean {
    const token = localStorage.getItem('authToken');
    const tokenExpiry = localStorage.getItem('tokenExpiry');
    if (!token || new Date() > new Date(tokenExpiry)) {
      this.router.navigateByUrl("/login");
      return false;
    }
    return true;
  }
  
}
