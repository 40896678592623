import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Sort } from '../../../_services/sort.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LeaveApplicationDataModel } from '../../../_models/leave-application-data-model';
import { LeaveService } from '../../../_services/leave.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import * as moment from 'moment';
import { AuthService } from '../../../_services/auth.service';
import { DashboardService } from '../../../_services/dashboard.service';

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyRequestsComponent implements OnInit {
  statusFlag: boolean;
  empData: EmployeeDataModel;
  leaveId: string;
  empId: string;
  leaveObj: LeaveApplicationDataModel;
  appliedLeavesList: Array<LeaveApplicationDataModel> = [];
  acceptedLeavesList: Array<LeaveApplicationDataModel> = [];
  cancelLeaveCode: any;
  today: any;
  leaveObj2: any

  leadStatus: string = "";

  createDate: Date[];
  isManagerFlag: boolean = false;
  // addEditLeave : string= ''
  editFlag: boolean = false;
  addFlag: boolean = false;
  request: boolean = false;
  EditLeave: any = [];
  // EmployeeId: any;
  EmployeeId: import("../../../_models/user-data-model").UserDataModel;   //import("d:/solzit ess portal/ESSPortal/ESSPortal in Angular 10/src/app/_models/user-data-model").UserDataModel;
  cancleLeaveRecord: any;
  ApprovedLeave: any = [];
  DeclinedLeave: any = [];
  CancelledLeave: any = [];

  constructor(private sort: Sort,
    private modalService: NgbModal,
    public HttpClient: HttpClient,
    private storage: StorageMap,
    private router: Router,
    public leaveService: LeaveService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getData(); // getting data from local storage api and setting it in our local variable.    
  }

  async getData() {
    this.addFlag = true
    // this.request=false
    this.request = !this.request
    // this.addEditLeave = 'New Leave'
    this.empData = new EmployeeDataModel();
    this.today = new Date();
    this.today = moment(this.today).format('YYYY-MM-DD');
    await this.storage.get('empDetails').subscribe((empMyRequest: EmployeeDataModel) => {

      this.empData = empMyRequest.userProfile;
      this.empId = this.empData.userId; // getting user data from Auth Service. // getting user data from Auth Service.
      this.isManagerFlag = this.empData.isManager;

      this.getAppliedLeaves();
      this.getLeaveBalance(); // getting All Applied Leaves.
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);

    });

  }

  // function call for getting all Leave Lists.
  async getAppliedLeaves() {
    await this.leaveService.getAppliedLeavesList().subscribe((resp: any) => {
      if (resp.isSuccessful == true) {// success
        this.appliedLeavesList = resp.data;
        // Assuming each leave application has a 'leaveDate' property
        this.appliedLeavesList.sort((a: LeaveApplicationDataModel, b: LeaveApplicationDataModel) => {
          // Convert date strings to Date objects for comparison
          const dateA = new Date(a.leaveStartDate);
          const dateB = new Date(b.leaveStartDate);
          // Sort by date in ascending order (earliest date first)
          return dateA.getTime() - dateB.getTime();
        });
      }

      else if (resp?.messageDetail?.message_code == 900) {
        this.spinner.hide();
        this.appliedLeavesList = [] as Array<LeaveApplicationDataModel>;
      }

      else if (resp.isSuccessful == false) {
        this.spinner.hide();
        if (resp?.messageDetail?.message_code != 204) {

          this.toastr.error(resp.Message, 'Error')
        }
      }

      // getting All Accepted Leaves.
      this.getAcceptedLeaves();
    },
      error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message);
      }
    );
  }

  async getAcceptedLeaves() {debugger
    await this.leaveService.getAcceptedLeavesList().subscribe((resAcc: any) => {
      if (resAcc.isSuccessful == true) { // success.
        this.acceptedLeavesList = resAcc.data;

        resAcc?.data.forEach(element => {
          if(element?.status?.value == 674180000){

            this.CancelledLeave.push(element);
          }
         else if(element?.status?.value == 674180001){

            this.ApprovedLeave.push(element);
          }

          else if(element?.status?.value == 674180002){

            this.DeclinedLeave.push(element);
          }
          
        });
      }

      else if (resAcc?.isSuccessful == false) { // page not found.
        this.spinner.hide();
        if (resAcc?.messageDetail?.message_code != 204) {

          this.toastr.error(resAcc.Message, 'Error');
        }
        // this.router.navigate(['404'])
      }

      // else if (resAcc.ResponseCode == 217) { // no records to display.
      //   this.spinner.hide();
      //   this.toastr.error(resAcc.Message, 'Error')
      // }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.toastr.error(error?.messageDetail?.message);
      }
    );

  }
  cancelLeave1(leaveRecord: any) {
    this.cancleLeaveRecord = leaveRecord
  }
  // function call for cancelling Leave Requests by sending leaveId of an individual record.
  async cancelLeave() {
    this.spinner.show();
    await this.leaveService.postCancelLeave(this.cancleLeaveRecord).subscribe((resCan: any) => {
      this.cancelLeaveCode = resCan.ResponseCode;
      if (resCan.isSuccessful == true) {

        this.toastr.success(resCan?.messageDetail?.message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        setTimeout(() => {
          location.reload();
          this.spinner.hide();
        }, 1000);
        // this.getData();
      }
      else if (resCan.isSuccessful == false) {
        this.spinner.hide();
        if(resCan?.messageDetail?.message_code != 204){
          this.toastr.error(resCan?.messageDetail?.message, 'Error');
        }
      }
     
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   //this.acceptedLeavesList.filter= filterValue.trim().toLowerCase();
  // }

  // Update Leave Record
  updateLeave(leaveModel: any) {
    // this.request=true;
    this.request != this.request;
    this.leaveService.updateLeaveRecord(leaveModel);
    // this.addEditLeave = 'Edit Leave'
    this.editFlag = true;
    // this.request=true;
    // this.spinner.show();
    this.getData();
  }
  async getLeaveBalance() {
    this.dashboardService.getBalanceLeaveDashboard().subscribe((res5: any) => {
      if (res5.isSuccessful == true) { // success
        this.leaveObj2 = res5.data;

      }
      else if (res5?.messageDetail?.message_code == 999) {
        this.spinner.hide();
        this.toastr.error(res5.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error?.messageDetail?.message);
    });
  }
}
