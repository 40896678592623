import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './New Portal UI/authorization/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ForgotPasswordComponent } from './New Portal UI/authorization/forgot-password/forgot-password.component';
import { AuthenticationGuard } from './_services/authentication.guard';
import { ChangePasswordComponent } from './New Portal UI/authorization/change-password/change-password.component';
import { GeneratePasswordComponent } from './New Portal UI/authorization/generate-password/generate-password.component';

export const routes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children:
      [
        {
          path: 'ESSPortal',
          loadChildren: () => import('./New Portal UI/portal-routing.module').then(p => p.PortalRoutingModule)
        },
      ]
  },

  {
    path: 'login',
    component: LoginComponent,
    
    
  },

  {
    path: 'forgot_password',
    component: ForgotPasswordComponent,
    
  },

  {
    path: 'generate_password/:id',
    component: GeneratePasswordComponent,
    
  },

  //{
  //  path: '',
  //  redirectTo: 'dashboard',
  //  pathMatch: 'full',
  //},
  {
    path: '404',
    component: P404Component,
    canActivate: [AuthenticationGuard],
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    canActivate: [AuthenticationGuard],
    data: {
      title: 'Page 500'
    }
  },
  //{
  //  path: 'login',
  //  component: LoginComponent,
  //  data: {
  //    title: 'Login Page'
  //  }
  //},
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
