import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  };
}
@Component({
  selector: 'app-add-employee-goals',
  templateUrl: './add-employee-goals.component.html',
  styleUrls: ['./add-employee-goals.component.scss']
})

export class AddEmployeeGoalsComponent implements OnInit {
  empGoalsForm: FormGroup;
  fieldRequiredFlag: boolean = false;
  empId: string;
  statusresionval: any;
  status: any;
  Filterstatus: [];
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  goalFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  datasplit: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private PerformanceReviewService: PerformanceReviewService,
    private MasterDropdownServiceService: MasterDropdownServiceService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public SharepointService: SharepointServiceService,
  ) {
    this.empId = localStorage.getItem("empId")
  }



  ngOnInit(): void {
    this.spinner.show();
    this.empGoalsForm = this.fb.group({
      EmployeeName: [''],
      PerformanceYear: [''],
      Goals: ['', [Validators.required, noWhitespaceValidator()]],
      achievedDate: ['', [Validators.required, noWhitespaceValidator()]],
      ExpOutcome: ['', [Validators.required, noWhitespaceValidator()]],
      percentageAchieved: [0, [Validators.required, noWhitespaceValidator, Validators.min(0), Validators.max(100)]],
      statusReason: ['', [Validators.required, noWhitespaceValidator()]],
      employeeComments: [''],
      managerComments: ['']
    });

    this.empGoalsForm.patchValue({
      statusReason: '674180002'
    })
    this.dropdownOfStatus()
  }

  //Employee Form Control
  get egl() {
    return this.empGoalsForm.controls;
  }

  checkValue(event) {
    debugger
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    if (event.target.value > 100) {
      event.target.value = 100;
    }

  }
  dropdownOfStatus() {

    this.MasterDropdownServiceService.getMasterDropdown('GoalsStatus').subscribe((responce: any) => {
      if (responce.isSuccessful == true) {

        this.status = responce.data
        this.Filterstatus = this.status.filter(element => {
          return element.label === 'In Progress' || element.label === 'New' ||
            element.value === 1 || element.value === 674180002;
        });
        this.spinner.hide();
      }
      else if (responce.isSuccessful == false) {
        this.spinner.hide();
        if (responce?.messageDetail?.message_code != 204) {
          this.toastr.error(responce?.messageDetail?.message, 'Error')
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    })
  }

  statusReason(status: any) {
    this.statusresionval = status
  }
  submit() {
    this.goalFormSubmitted = true
    this.spinner.show();
    // this.router.navigate(['/ESSPortal/employee-goals-list']);

    if (this.empGoalsForm.invalid) {
      // Mark all form controls as touched to trigger validation messages
      Object.values(this.empGoalsForm.controls).forEach(control => {
        control.markAsTouched();
        this.fieldRequiredFlag = true;
      });
      this.spinner.hide();
      return;

    }
    let obj = {
      employeeId: this.empId,
      expOutcome: this.empGoalsForm.value.ExpOutcome,
      achievebyDate: this.empGoalsForm.value.achievedDate,
      comment: this.empGoalsForm.value.employeeComments,
      managerComment: this.empGoalsForm.value.managerComments,
      goal: this.empGoalsForm.value.Goals,
      // PerformanceYear: this.empGoalsForm.value.PerformanceYear,    
      percentAchieved: this.empGoalsForm.value.percentageAchieved,
      statusReason: {

        value: this.empGoalsForm.value.statusReason
      }

    }

    this.PerformanceReviewService.postaddEmployeeGoals(obj).subscribe((responce: any) => {
      if (responce.isSuccessful == true) {

        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = responce.data;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }

        else { // else just show message.
          this.spinner.hide();
          this.toastr.success(responce?.messageDetail?.message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          this.router.navigate(['/ESSPortal/employee-goals-list']);
        }
      }

      else if (responce.isSuccessful == false) {
        this.toastr.error(responce?.messageDetail?.message, 'Error');
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })

  }

  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
    await this.SharepointService.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        this.router.navigate(['/ESSPortal/employee-goals-list']);

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  selectAttachments(event) {

    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };

          // Get the current date and time
          const now = new Date();
          const day = String(now.getDate()).padStart(2, '0');
          const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
          const year = String(now.getFullYear()).slice(2); // Get last two digits of the year
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');

          // Format the date and time as ddmmyy hhmmss
          const timestamp = `${day}${month}${year}${hours}${minutes}${seconds}`;
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_employeegoal";
          uploadFile["bytes"] = base64[1];
          // Append the timestamp to the original file name
          uploadFile["filename"] = `${file.name.split('.').slice(0, -1).join('.')}_${timestamp}.${file.name.split('.').pop()}`;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }
}


