import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { Table } from 'primeng/table';
// import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-my-to-dos',
  templateUrl: './my-to-dos.component.html',
  styleUrls: ['./my-to-dos.component.css']
})
export class MyToDosComponent implements OnInit {
  @ViewChild('parentTable') parentTable: Table;
  // @ViewChild('config') columnFilterConfig !: ElementRef; 
  userData: EmployeeDataModel;
  contactId: any;
  empId: any;
  custTableFlag: number = 0;
  empTableFlag: number = 0;
  allProjectList: Array<ProjectDataModel> = [];
  toDoList: Array<ToDosDataModal> = [];
  toDosObj: ToDosDataModal;
  ContactLoggedIn: boolean;
  isManager: any;
  heading: string;
  parameter: string;
  fieldsToExport: Array<any> = [];
  isBugList: any;
  ToggleFlag: any;
  checked: boolean = false;
  filterValue: string = '';
  searchFilterVal: any;
  expandedRow: any = null;
  assigneeFlag: boolean = false;
  titleWidth: number = 30; // Default width for Title
  assigneeWidth: number = 5; // Default width for Assignee

  constructor(public toDosService: ToDosService,
    // private primeNGConfig: PrimeNGConfig,
    private storage: StorageMap,
    public supportReqService: SupportRequestService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router) { }


  ngOnInit(): void {
    this.spinner.show();
    this.toDosObj = new ToDosDataModal();
    this.parameter = "";
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myToDos: EmployeeDataModel) => {
      this.userData = myToDos.userProfile;
      // If Contact User is logged in
      if (this.userData.contact) {
        this.ContactLoggedIn = true;
        this.contactId = this.userData.contact?.ID;
      }

      else if (this.userData.userId) {
        this.ContactLoggedIn = false;
        this.isManager = this.userData?.isManager;
        this.empId = this.userData?.userId
        this.GetToDoListBasedOnFilter(3, 0)
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  updateToDo(record: { id: string, itemType: any }) {
    if (record?.itemType?.value === 674180001) {
      const url = this.router.serializeUrl(

        this.router.createUrlTree([`/ESSPortal/to_dos/update_UserStory/${record.id}`])
      );
      window.open(`${window.location.origin}/#${url}`, '_blank');
    }

    else {

      const url = this.router.serializeUrl(

        this.router.createUrlTree([`/ESSPortal/to_dos/update_to_do/${record.id}`])
      );
      window.open(`${window.location.origin}/#${url}`, '_blank');
    }
  }


  // sending to-do id to my worklog page.
  createWorkLog(id: string) {
    // this.router.navigate(['/#/ESSPortal/to_dos/my_worklog/AddWork/' + id]);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/ESSPortal/to_dos/my_worklog/AddWork/${id}`])

    );
    window.open(`${window.location.origin}/#${url}`)
  }

  // creating user readable list.
  createExportList() {
    debugger


    for (let i = 0; i < this.toDoList.length; i++) {
      var ex = {
        Item_No: "",
        // Created_On: null,
        Title: "",
        ItemsType: "",
        // WorkType: "",
        Project_Name: "",
        // Item_Description: "",
        // Repro_Steps: "",
        Parent_Title: "",
        User_Priority: null,
        // Type_Of_Item: null,
        // Date_Moved: null,
        // Testing_Environment: null,
        // Expected_Res_Date: null,
        // Comment: "",
        // Description_Of_Resolution: "",
        Work_Status: null,
        // Is_Client_Reported: "",
        // Assignee_Name: "",
        // Assigned_Reviewer_Name: "",
        // Implementation_Effort: 0,
        // Start_Date: null,
        // Review_Effort: 0,
        // isBug: ""
      }

      ex.Item_No = this.toDoList[i].itemNumber;
      // ex.Created_On = this.toDoList[i].createdon;
      ex.Title = this.toDoList[i].title;
      ex.ItemsType = this.toDoList[i].itemType?.label;
      // ex.WorkType = this.toDoList[i].typeOfItem?.Label;
      ex.Project_Name = this.toDoList[i].project.name;
      // ex.Item_Description = this.toDoList[i].itemDescription;
      // ex.Repro_Steps = this.toDoList[i].reproSteps;
      ex.Parent_Title = this.toDoList[i].userStoryTitle;
      // ex.User_Priority = this.toDoList[i].userPriority?.Label;
      // ex.Type_Of_Item = this.toDoList[i].typeOfItem?.Label;
      // ex.Date_Moved = this.toDoList[i].dateMoved;
      // ex.Testing_Environment = this.toDoList[i].testingEnvironment?.Label;
      // ex.Expected_Res_Date = this.toDoList[i].expectedResDate;
      // ex.Comment = this.toDoList[i].comment;
      // ex.Description_Of_Resolution = this.toDoList[i].descriptionOfResolution;
      ex.Work_Status = this.toDoList[i].workStatus?.label;


      // if (this.toDoList[i].isClientReported == false || this.toDoList[i].isClientReported == null) {
      //   ex.Is_Client_Reported = "No";
      // }

      // else {
      //   ex.Is_Client_Reported = "Yes";
      // }
      // ex.Assignee_Name = this.toDoList[i].assigneeName;
      // ex.Assigned_Reviewer_Name = this.toDoList[i].assignedReviewerName;
      // ex.Implementation_Effort = this.toDoList[i].implementationeffort;
      // ex.Start_Date = this.toDoList[i].startDate;
      // ex.Review_Effort = this.toDoList[i].revieweffort;
      // // ex.isBug = this.toDoList[i].isBug
      // if (this.toDoList[i].isBug == false || this.toDoList[i].isBug == null) {
      //   ex.isBug = "No";
      // }
      // else {
      //   ex.isBug = "Yes";
      // }
      this.fieldsToExport[i] = ex;
    }
  }


  exportToExcel() {
    debugger
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.fieldsToExport);
      const workbook = { Sheets: { 'Exporting_Tasks': worksheet }, SheetNames: ['Exporting_Tasks'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Exporting_Tasks");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }
  //Toggle for Expand And collapsed Table Row Data
  showHideToggle() {
    this.ToggleFlag = !this.ToggleFlag;

  }

  isRowExpanded(record: any): boolean {
    return this.expandedRow === record;
  }

  toggleRowExpansion(table: any, record: any): void {
    if (this.isRowExpanded(record)) {
      this.expandedRow = null; // Collapse the row
      table.toggleRow(record); // PrimeNG-specific collapse logic
    } else {
      if (this.expandedRow) {
        table.toggleRow(this.expandedRow); // Collapse the previously expanded row
      }
      this.expandedRow = record; // Set the new row as expanded
      table.toggleRow(record); // Expand the new row
    }
  }
  GetToDoListBasedOnFilter(filterId, itemType) {
    this.assigneeFlag = false;
    if (this.assigneeFlag) {
      this.titleWidth = 25;  // Reduce title width when Assignee is shown
      this.assigneeWidth = 5;
    } else {
      this.titleWidth = 30;  // Full width when Assignee is hidden
    }
    this.spinner.show();
    debugger
    if (filterId == 1 && itemType == 0) {
      this.heading = " My Active Items";
    }
    else if (filterId == 2 && itemType == 0) {
      this.heading = "Not Started Items";
    }
    else if (filterId == 3 && itemType == 0) {
      this.heading = "Items I'm Working On";
    }
    else if (filterId == 4 && itemType == 0) {
      this.heading = "Pending Items";
    }
    else if (filterId == 3 && itemType == 674180003) {
      this.heading = "Bugs I'm Working On";
    }
    else if (filterId == 3 && itemType == 674180001) {
      this.heading = "User Stories I'm Working On";
    }
    else if (filterId == 5 && itemType == 0) {
      this.heading = "Completed Items";
    }
    this.toDosService.GetToDoListBasedOnFilter(filterId, itemType).subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = []
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
      this.createExportList()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }

  GetActiveItemsInMyProject() {

    this.spinner.show();
    this.assigneeFlag = true
    if (this.assigneeFlag) {
      this.titleWidth = 25;  // Reduce title width when Assignee is shown
      this.assigneeWidth = 5;
    } else {
      this.titleWidth = 30;  // Full width when Assignee is hidden
    }
    this.heading = " Active Items in My Projects";
    this.toDosService.GetActiveItemsInMyProject().subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = []
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
      this.createExportList()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }


  GetGeneralTaskListInMyProject() {
    this.assigneeFlag = false;
    if (this.assigneeFlag) {
      this.titleWidth = 25;  // Reduce title width when Assignee is shown
      this.assigneeWidth = 5;
    } else {
      this.titleWidth = 30;  // Full width when Assignee is hidden
    }
    this.spinner.show();
    this.heading = " General Tasks";
    this.toDosService.GetGeneralTaskListInMyProject().subscribe((filterResponce: any) => {
      if (filterResponce.isSuccessful == true) {
        this.toDoList = filterResponce.data === null ? [] : filterResponce.data;

        this.spinner.hide();
      }
      else if (filterResponce.isSuccessful == false) {
        this.spinner.hide();
        this.toDoList = []
        if (filterResponce?.messageDetail?.message_code != 204) {
          this.toastr.error(filterResponce?.messageDetail?.message)
        }
      }
      this.createExportList()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    }
    );
  }



}
