import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LeaveApplicationDataModel } from '../../../_models/leave-application-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { UserDataModel } from '../../../_models/user-data-model';
import { AuthService } from '../../../_services/auth.service';
import { LeaveService } from '../../../_services/leave.service';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { decimalDigest } from '@angular/compiler/src/i18n/digest';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.css'],
})
export class NewRequestComponent implements OnInit {
  // @ViewChild('form', {static: false}) form: NgForm;
  @Output() reLoadMyRequests: EventEmitter<any> = new EventEmitter();
  @ViewChild('closeBtn') closeBtn: ElementRef
  leaveObj: LeaveApplicationDataModel;
  userObj: UserDataModel;

  emptyObj: any = { Value: null, Label: "" };
  leaveDayTypes: OptionSetDataModel[] =
    [
      { Value: 0, Label: "Full Day" },
      { Value: 1, Label: "Half Day" }
    ];
  leaveTypes: OptionSetDataModel[];
  halfDayTypes: OptionSetDataModel[];
  halfDayFlag: boolean = false;

  today: any;
  days: number = 0;


  //setting optional date select only one day
  // todayDate = new Date();
  // maxDate = moment(this.todayDate).format('YYYY-MM-DD');

  userData: any; //used to get employee data from auth service to retrieve ID.
  leaveFormSubmitted: boolean = false;
  modalRef: BsModalRef
  //file upload
  datasplit: any;
  upLoadFileList: Array<UploadFileDataModel> = [];
  updateToDoId: string;
  medicalLeaveFlag: boolean = false;
  optionalLeaveFlag: boolean = false;
  ngForm: FormGroup;
  leaveDataPatch: any;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  endDateDisabled: boolean = false;

  constructor(public leaveService: LeaveService,
    public authService: AuthService,
    public storage: StorageMap,
    public HttpClient: HttpClient,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    public supportReqService: SupportRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.getData();
    // getting data from local storage api and setting it in our local variable.
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateToDoId = this.route.snapshot.paramMap.get('id');
    }




    this.leaveDataPatch = LeaveApplicationDataModel;
    this.ngForm = this.fb.group({
      ID: [''],
      leaveStartDate: [''],
      leaveEndDate: [''],
      leaveDayTypes: [''],
      EarnedLeave: [''],
      typeofHalfDayLeave: [''],
      leaveDayType: [''],
    });
    this.GetLeaveById();

  }


  openDatePicker(event: Event) {
    const input = event.target as HTMLInputElement;
    input.readOnly = true;  // Temporarily remove readonly
    input.focus();  // Focus to open the date picker
    input.readOnly = false;  // Reapply readonly after opening
  }

  async getData() {
    this.spinner.show();
    this.leaveFormSubmitted = false;
    this.halfDayFlag = false;
    this.medicalLeaveFlag = false;
    this.optionalLeaveFlag = false;
    this.userObj = new UserDataModel();
    this.leaveObj = new LeaveApplicationDataModel();
    this.leaveObj.leaveDayType = this.emptyObj; // setting default array value.
    this.leaveObj.leaveType = this.emptyObj; // setting default array value.
    this.leaveObj.typeofHalfDayLeave = this.emptyObj; // setting default array value.
    this.today = new Date();
    await this.storage.get('empDetails').subscribe((empNewRequest: any) => {
      this.userData = empNewRequest.userProfile;
      this.userObj.ID = this.userData.userId; // getting ID from Auth Service.
      this.userObj.email = this.userData.email; // getting email from Auth Service.
      this.userObj.fullName = this.userData.fullName; // getting fullName from Auth Service.
      this.leaveObj.employee = this.userObj; // setting employee data.
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // dynamically assigning half day list, leave type list on the basis leaveDayType.
  async onChangeDayType(val: any) {
    if (val == 0) {
      this.endDateDisabled = false; //disable enddate if day type is half day
      this.optionalLeaveFlag = true;
      this.halfDayFlag = false;
      this.medicalLeaveFlag = false
      this.leaveObj.typeofHalfDayLeave.Value = 0;
      this.leaveTypes = [
        { Value: 674180000, Label: "Earn Leave" },
        // { Value: 674180001, Label: "Medical Leave"  },
        // { Value: 674180003, Label: "Optional Leave" }
      ];
    }
    else {

      //same as above fill model for half Day Type
      // this.endDateDisabled = true; //disable enddate if day type is half day
      this.halfDayFlag = true;
      this.halfDayTypes = [
        { Value: 674180000, Label: "Fore Noon" },
        { Value: 674180001, Label: "After Noon" }
      ];

      this.leaveTypes = [
        { Value: 674180000, Label: "Earn Leave" },
        // { Value: 674180001, Label: "Medical Leave" }
      ];
    }
    // Set start and end dates to null
    this.ngForm.get('leaveStartDate').setValue(null);
    this.ngForm.get('leaveEndDate').setValue(null);

    // Clear the leave object dates as well
    this.leaveObj.leaveStartDate = null;
    this.leaveObj.leaveEndDate = null;
  }
  dateSelect() {

    this.leaveObj.leaveEndDate = this.leaveObj.leaveStartDate
  }
  updateEndDate() {
    if (this.leaveObj.typeofHalfDayLeave !== this.emptyObj) {
      // If half day leave is selected, set end date to the same as start date
      this.leaveObj.leaveEndDate = this.leaveObj.leaveStartDate;
      this.ngForm.value.leaveEndDate = this.leaveObj.leaveStartDate
      this.endDateDisabled = this.leaveObj.typeofHalfDayLeave !== this.emptyObj;
      // this.endDateDisabled = true;

    }
    else {
      this.endDateDisabled = false;
    }
  }
  medical(val: any) {
    this.medicalLeaveFlag = false;
    if (val.Label == 'Medical Leave') {
      this.medicalLeaveFlag = true;
    }
    else if (val.Label == 'Optional Leave') {
      this.optionalLeaveFlag = false;
    }
  }

  //set today's date in today to assign min date as today's date in startLeaveDay iff leaveType is Optional Leave.
  async onChangeLeaveType(val: any) {
    if (val == 674180003) {
      this.today = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();
    }
  }

  // opening modal
  openModal(template: TemplateRef<any>, form: FormGroup) {

    this.leaveFormSubmitted = true;
    if (form.value.typeofHalfDayLeave == this.emptyObj && this.halfDayFlag == true && this.leaveFormSubmitted == true) {
      form.controls['typeofHalfDayLeave'].setValidators([Validators.required]);
      form.controls['typeofHalfDayLeave'].updateValueAndValidity();
      form.controls['typeofHalfDayLeave'].setErrors({ 'invalid': true });

    }

    if (form.value.leaveDayType == this.emptyObj && this.leaveFormSubmitted == true) {
      form.controls['leaveDayType'].setErrors({ 'invalid': true });
    }

    if (form.value.leaveType == this.emptyObj && this.leaveFormSubmitted == true) {
      form.controls['leaveType'].setErrors({ 'invalid': true });
    }

    if (form.invalid) {
      return;
    }
    if (this.halfDayFlag == true) {

      if (form.value.leaveStartDate != (this.ngForm.value.leaveEndDate || form.value.leaveStartDate)) {
        this.spinner.hide();
        this.toastr.error("Oops! Half day leave can not have multiple dates.", "Info", {
          timeOut: 20000
        });
        return;
      }
    }
    //optional leave by testing ps
    // if (this.optionalLeaveFlag == false) {
    //   if (form.value.leaveStartDate != form.value.leaveEndDate) {
    //     this.spinner.hide();
    //     this.toastr.error(" Optional leave is only for one day.", "Info", {
    //       timeOut: 5000
    //     });
    //     return;
    //   }
    // }
    this.getNoOfDays(form.value.leaveStartDate, form.value.leaveEndDate);
    this.modalRef = this.modalService.show(template);

  }

  //Upload Medical Certificate
  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_leaveapplication";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {

    this.upLoadFileList.splice(index, 1);
  }

  //Upload Medical Certificate
  // async uploadFiles(attachFiles: any) {
  //   await this.supportReqService.postUploadFilesWithToDo(attachFiles).subscribe((upRes: any) => {
  //     if (upRes.ResponseCode == 100) { // success
  //       this.toastr.success(upRes.Message, 'Success', {
  //         positionClass: 'toast-top-full-width',
  //         timeOut: 5000
  //       });

  //     }

  //     else if (upRes.ResponseCode == 999) {
  //       this.spinner.hide();
  //       this.toastr.error(upRes.Message, 'error', {
  //         positionClass: 'toast-top-full-width',
  //         timeOut: 5000
  //       });
  //     }

  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error(error.message, 'error', {
  //       positionClass: 'tosat-top-full-width',
  //       timeOut: 5000
  //     });
  //     location.reload();
  //     this.reLoadMyRequests.emit();
  //   });


  // }


  // posting new leave request.
  leaveApply(form: NgForm) {
    debugger


    this.spinner.show();
    this.modalRef.hide();
    var data = form.value;

    this.leaveObj.leaveDayType = data.leaveDayType;
    this.leaveObj.typeofHalfDayLeave = data.leaveType;
    this.leaveObj.leaveStartDate = data.leaveStartDate;
    this.leaveObj.leaveEndDate = form.controls.leaveEndDate.value;
    // this.leaveObj.totalDaysofLeave = this.days;

    if (this.leaveObj.typeofHalfDayLeave.Value != 0) {
      this.leaveObj.typeofHalfDayLeave = data.typeofHalfDayLeave;
    }

    let halfday = {
      typeofHalfDayLeave: {
        "value": this.leaveObj?.typeofHalfDayLeave?.Value ?? null,
        "label": this.leaveObj?.typeofHalfDayLeave?.Label ?? null
      }
    };

    let leaveObj = {
      "leaveApplicationId": null,
      "employeeId": this.userData?.userId,
      "leaveType": {
        "value": data?.leaveType?.Value,
        "label": data?.leaveType?.Label
      },
      typeofHalfDayLeave: halfday?.typeofHalfDayLeave?.value ? halfday?.typeofHalfDayLeave : null,

      "leaveStartDate": data?.leaveStartDate,
      "leaveEndDate": form?.controls?.leaveEndDate.value,
      // "totalDaysofLeave": this.days
    }

    this.leaveService.postNewRequest(leaveObj).subscribe((res: any) => {

      if (res.isSuccessful == true) { // success
        //this.authService.userData = this.leaveObj;
        form.reset();
        this.toastr.success(res?.messageDetail?.message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 10000
        });
        setTimeout(function () {
          location.reload();
          this.spinner.hide();
          this.getData();
        }, 2000);
      }
      else if (res.isSuccessful == false) { // success
        //this.authService.userData = this.leaveObj;
        form.reset();
        this.toastr.error(res?.messageDetail?.message, 'Error', {
          positionClass: 'toast-top-full-width',
          timeOut: 10000
        });
        form.reset();
        setTimeout(function () {
          location.reload();
          this.spinner.hide();
        }, 3000);
      }

      //Upload Medical Certificate 
      //  if (res.Data != '00000000-0000-0000-0000-000000000000') {
      // if (this.upLoadFileList.length != 0) { // upload files if any
      //   for (let i = 0; i < this.upLoadFileList.length; i++) {
      //     this.upLoadFileList[i].ID = res.Data.id;
      //   }

      //   var attachFiles = { itemDetails: this.upLoadFileList }
      //   this.uploadFiles(attachFiles);
      // }

    }, error => {

      this.toastr.error(error?.error?.messageDetail?.message);



      setTimeout(() => {
        this.spinner.hide();
        location.reload();
      },3000);
      // }
    });

    //   setTimeout(function () {
    //    
    //   },
    //   //  10000
    // );
    // this.reLoadMyRequests.emit();


  }

  //calculate no. of leave days
  getNoOfDays(a: any, b: any) {
    if (this.halfDayFlag == true) {
      this.days = 0.5;
    }
    else {
      var date1 = new Date(a);
      var date2 = new Date(b);
      // date1.setHours(0.0);
      // date2.setHours(0.0);
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.days = dayDifference + 1;
    }
  }


  //reset form.
  resetForm() {

    // this.getData();
    this.ngForm.reset();
    location.reload();
  }
  GetLeaveById() {
    this.leaveService.latestLeaveObj.subscribe((x: any) => {

      if (x) {
        this.patchFormValue(x);
      }
    });
  }
  convert(str: any) {
    var date = new Date(str), mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }


  patchFormValue(leaveData: any) {

    //date formate 
    var leaveEndDate = null, leaveStartDate = null;
    if (leaveData.leaveEndDate != null) {
      var dateonly = leaveData.leaveEndDate.split("T");
      leaveEndDate = dateonly[0];
    }
    if (leaveData.leaveStartDate != null) {
      var dateonly = leaveData.leaveStartDate.split("T");
      leaveStartDate = dateonly[0];
    }

    this.onChangeDayType(leaveData.leaveDayType.Value);
    if (leaveData.leaveType != null)

      this.onChangeLeaveType(leaveData.leaveType.Value)
    this.leaveObj.ID = leaveData.ID;
    this.leaveObj.leaveApplicationId = leaveData.ID;
    this.leaveObj.leaveStartDate = leaveStartDate;// leaveData.leaveStartDate
    this.leaveObj.leaveEndDate = leaveEndDate;//leaveData.leaveEndDate
    this.leaveObj.leaveDayType = leaveData.leaveDayType;
    this.leaveObj.typeofHalfDayLeave = leaveData.typeofHalfDayLeave;
    this.leaveObj.leaveType = leaveData.leaveType;
    this.leaveObj.employee = leaveData.employee;
  }
  compareFun(item1, item2) {
    return item1 && item2 ? item1.Value === item2.Value : item1 === item2;
  }
  onReset(form: NgForm): void {
    form.reset();
  }

}
