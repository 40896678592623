import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { UserDataModel } from '../../../_models/user-data-model';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageMap } from '@ngx-pwa/local-storage';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public userObj: UserDataModel;
  public message: string;
  public errorflag = true;

  id: string = "";
  empImg: any
  fullName: any;

  constructor(public HttpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    public toastr: ToastrService,
    public _cd: ChangeDetectorRef,
    private spinner:NgxSpinnerService,
    private storage: StorageMap,
  ) {
  }

  ngOnInit(): void {
     
    this.userObj = new UserDataModel();

  }
  logoutEmployee() {
    this.storage.clear().subscribe(() => { });
    localStorage.clear();
    this.router.navigate(['login']);
  }
  async onLogin(loginForm: NgForm) {debugger
    const activeUserName = localStorage.getItem("empName");
    const activeUser =    localStorage.getItem("authToken");
    if (activeUser) {
      const confirmLogout = confirm(
        `Another user (${activeUserName}) is already logged in. Do you want to log them out?`
      );

      if (!confirmLogout) {
        return; // Prevent login
      }

      this.logoutEmployee(); // Log out the current user
    }
     this.spinner.show();
    var data = loginForm.value;
    this.userObj.email = data.email;
    this.userObj.password = data.password;
    await this.authService.postLogin(this.userObj).subscribe((res: any) => {

      if (res.isSuccessful == true) { // success

        this.authService.setEmployeeData(res.data);
        this.id = res.data.userProfile?.userId;
        this.empImg = res.data.userProfile.employeeImg;
        this.fullName = res.data?.userProfile.fullName;
        localStorage.setItem("empId", this.id);
        localStorage.setItem("empImg", this.empImg);
        localStorage.setItem("empName", this.fullName);
        localStorage.setItem("gender", res.data?.userProfile.gender);
        localStorage.setItem("email", res.data.employee?.userProfile.email);
        localStorage.setItem("reportingManager", res.data?.userProfile.reportingManager?.name);
        localStorage.setItem("designation", res.data?.userProfile.designation);
        let userType = res.data.userProfile.contact ? 'customer' : 'employee';
        localStorage.setItem("userType", userType);


        // Save token and its expiry time
        localStorage.setItem("authToken", res.data.authToken.accessToken);
        localStorage.setItem("tokenExpiry", res.data.authToken.tokenExpiry);
        this.spinner.hide();
        setTimeout(() => {
          if (res.data?.contact == null) {
            this.router.navigate(['ESSPortal/dashboard']);
            this._cd.detectChanges();
          } else {
            this.router.navigate(['ESSPortal/customer/my_to_doos']);
            this._cd.detectChanges();
          }
        },)
        //IF emp PORTAL NOT WORK THEN SET TIME OUT 
        //},1000)

      }
      else if (res.isSuccessful == false ) {
        this.spinner.hide();
        this.toastr.error(res.messageDetail.message, 'Error');
      }

      else if (res.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res.messageDetail.message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
}
