import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import * as moment from 'moment';

@Directive({
  selector: '[appHandlePaste]'
})
export class HandlePasteDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardItems = event.clipboardData?.items;
    if (!clipboardItems) return;

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];

      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        if (file) {
          let fileReader = new FileReader();
          fileReader.onload = (e: ProgressEvent<FileReader>) => {
            const imgElement = this.renderer.createElement('img');
            imgElement.src = e.target?.result as string;
            this.renderer.appendChild(this.el.nativeElement, imgElement);

            const fileName = file.name.split('.')[0] + '_' + moment().format('DDmmYYYYHHMMSS') + '.' + file.name.split('.')[1];
            const button = this.renderer.createElement('button');
            button.innerHTML = `<i class="fa-regular fa-image mx-2"></i>${fileName}`;
            this.renderer.listen(button, 'click', () => this.downloadImg(fileName));
            this.renderer.appendChild(this.el.nativeElement, button);
          };
          fileReader.readAsDataURL(file);
        }
      }

      if (item.type.indexOf('text') !== -1) {
        const text = event.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
      }
    }
  }

  downloadImg(fileName: string) {
    // Implement your download logic here
    console.log('Downloading image:', fileName);
  }
}
