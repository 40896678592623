import { Component, Input, OnInit } from '@angular/core';
import { FeedbackService } from '../../../../_services/feedback.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeDataModel } from '../../../../_models/employee-data-model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-my-feedback',
  templateUrl: './my-feedback.component.html',
  styleUrls: ['./my-feedback.component.scss']
})
export class MyFeedbackComponent implements OnInit {
  ToggleFlag: boolean;
  // @Input() employeeId: string
  feedBackList: any;
  employeeId: any;

  constructor(public feedBackServices: FeedbackService,
    public toastr: ToastrService,
    private router: Router,
    private storage: StorageMap,
  private spiner: NgxSpinnerService) { 
       this.employeeId =  localStorage.getItem('empId')
     }

  ngOnInit(): void {
    
    this.GetMyFeedbacksListByEmpId()
  }

  showHideToggle() {
    this.ToggleFlag = !this.ToggleFlag;
  }


  GetMyFeedbacksListByEmpId() { 
    this.spiner.show(); 
    this.feedBackServices.GetMyFeedbacksListByEmpId(this.employeeId).subscribe((feedbackListResp: any) => {
    if(feedbackListResp.isSuccessful == true){
      this.feedBackList = feedbackListResp.data;
      this.spiner.hide();

    }
    else if (feedbackListResp.isSuccessful == false){
      this.spiner.hide();

    }
  }, error => { 
    this.spiner.hide();
    this.toastr.error(error.error.messageDetail.message);
  });
  }

  updateFeedBack(id:any){ 
    console.log(id)
    // this.router.navigate(['ESSPortal/view-feedback']);
    this.router.navigate(['/ESSPortal/update-feedback/'+id]);
  }

}
