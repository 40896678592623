import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { navItemsEmp, navItemsCust, navItems } from '../../_nav';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeDataModel } from '../../_models/employee-data-model';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  public navItemsEmp = navItemsEmp;
  public navItemsCust = navItemsCust;
  filteredNavItems = [];
  empId: string = "";
  count: number = 0;
  public empImg: string = '';
  fullName: string = "";
  base64Image: any;
  currentDate: Date;
  empData: EmployeeDataModel;
  position: string;
  displayPosition: boolean = false;
  Birth: any;
  employeeImg: string;
  ids: any;
  crmUrl: any;
  reportingManager: any;
  ProfileChangebase64Image: string;


  constructor(public authService: AuthService,
    public dashboardService: DashboardService,
    public router: Router,
    public routers: ActivatedRoute,
    private storage: StorageMap,
    private domSanitizer: DomSanitizer,
    public cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public spinner : NgxSpinnerService
  ) {

    this.empId = localStorage.getItem("empId")
    var img = localStorage.getItem("empImg");
    var gender = localStorage.getItem("gender");
    if (img == "null" && (gender == "null" || gender == "674180000")) {
      this.empImg = "assets/img/user-icon.png";
    }
    else if (img == "null" && gender == "674180001") {
      this.empImg = "assets/img/user-icon.png";
    }
    else {
      this.empImg = "data:img/jpeg;base64," + img;

    }


    this.currentDate = new Date();
    //converting base64 img to jpeg format.
    this.base64Image = this.domSanitizer.bypassSecurityTrustUrl(this.empImg);
    this.fullName = localStorage.getItem("empName");
    this.employeeImg = localStorage.getItem("empImg");
    this.reportingManager = localStorage.getItem('reportingManager')

    if (this.navItems[0].url == '/ESSPortal/dashboard') {
      this.navItems[0].url = this.navItems[0].url;
    }




  }


  ngOnInit() {
    // this.cd.detectChanges()
    this.displayPosition = false;
    this.getData();
    this.crmId();
    let userType = localStorage.getItem('userType');
    if (userType === 'customer') {
      this.navItems = navItemsCust;
    }
    // else if(userType==='employee'){
    //   this.navItems = navItemsEmp;
    // }

  }

  crmId() {
    this.authService.latestCrmId.subscribe((resp) => {
      this.crmUrl = resp;
    }
    )
  }



  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  onLogout() {
    this.authService.logoutEmployee();
  }
  async getData() {
    // this.cd.detectChanges()
    await this.storage.get('empDetails').subscribe((empBalance: EmployeeDataModel) => {
      if (!empBalance?.userProfile) {
        this.router.navigate(['/login'])
      }
      else if (empBalance?.userProfile) {
        var NavItemsClient: any = [];
        this.empData = empBalance.userProfile;
        // this.empId = this.empData.ID;
        if (this.empData.userId == "00000000-0000-0000-0000-000000000000") {
          this.navItems.filter((i: any) => {
            if (i.name != "Leaves & Breaks" && i.name != "Dashboard" && i.name != "Daily hours" && i.name != "Soluzione Directory"&& i.name != "My Projects"&& i.name != "My Observations" && i.name != "Leaves & Breaks" && i.name != "Breaks" && i.name != "Performance & Goals" && i.name != "Hiring Recruitment" && i.name != "Feedback" ) {
              NavItemsClient.push(i);
            }
          });
          this.navItems = NavItemsClient;
          this.navItemsEmp = NavItemsClient;
          this.navItemsCust = NavItemsClient;
        };
      }



    });
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;

  }


  // Trigger file input click
  triggerFileInput() {
   
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  // Function to handle file input change
  onFileChange(event: any) {
    this.spinner.show();
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Strip out the "data:image/jpeg;base64," part
        this.base64Image = reader.result as string;
        const base64String = (reader.result as string).split(',')[1];
        this.ProfileChangebase64Image = base64String;
        this.updateProfilePicture();
      };
    }
    else{
      this.spinner.hide();
    }
  }


  // Function to update the profile picture
  updateProfilePicture() {
    this.spinner.show();

    let obj = {
      email: this.empData?.email,
      profileImage: this.ProfileChangebase64Image
    }
    this.authService.updateDisplayPicture(obj).subscribe((response: any) => {
      if (response.isSuccessful == true) {
        console.log('Profile picture updated successfully', response);
        // this.toastr.success(response.messageDetail.message, 'Success')
        this.toastr.success(response.messageDetail.message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        // Close the dialog
        this.displayPosition = false;
        this.spinner.hide();

      }
      else if (response.isSuccessful == false) {

        this.toastr.error(response.messageDetail.message, 'Error')
        this.spinner.hide();
      }
    }, error => {
      console.error('Error updating profile picture', error);
      this.spinner.hide();
    });
  }

}

