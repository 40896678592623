import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';

@Component({
  selector: 'app-my-to-doos',
  templateUrl: './my-to-doos.component.html',
  styleUrls: ['./my-to-doos.component.scss']
})
export class MyToDoosComponent implements OnInit {

  userData: EmployeeDataModel;
  contactId: any;
  empId: any;
  custTableFlag: number = 0;
  empTableFlag: number = 0;
  allProjectList: Array<ProjectDataModel> = [];
  toDoList: Array<ToDosDataModal> = [];
  toDosObj: ToDosDataModal;
  ContactLoggedIn: boolean;
  isManager: any;
  heading: string;
  parameter: string;
  fieldsToExport: Array<any> = [];
  isBugList: any;
  ToggleFlag: boolean;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public supportReqService: SupportRequestService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.spinner.show();
    this.toDosObj = new ToDosDataModal();
    this.parameter = "";
    this.getData(); // getting data from local storage api and setting it in our local variable.
    this.getSolzStatusOptionSet();
  }

  async getData() {
     
    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myToDos: EmployeeDataModel) => {
      this.userData = myToDos;
      // If Contact User is logged in
      if (this.userData.contact) {
        this.ContactLoggedIn = true;
        this.contactId = this.userData.contact?.ID;
        this.getCustomerProjects();
      }

      // If Soluzione Emp/Manager logged in
      else if (this.userData.ID) {
        this.ContactLoggedIn = false;
        this.isManager = this.userData?.isManager;
        this.empId = this.userData?.ID
        this.getEmployeeProjects(this.userData.ID);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //for customers.
  async getCustomerProjects() {
     
    await this.toDosService.getProjectsByCustomerId1(this.contactId).subscribe((custProjects: any) => {
       
      if (custProjects.ResponseCode == 100) { // success
        //here we set list of customer  data in Cache 
        localStorage.setItem("setCustProjects", JSON.stringify(custProjects.Data));
      
        this.allProjectList = custProjects.Data;
        this.toDosObj.customerId = this.contactId;
        this.getCustOpenToDos(this.parameter);
        this.createExportList();
      }

      else if (custProjects.ResponseCode == 999) {
        this.toastr.error(custProjects.Message, 'Error');
      }
    },
     error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    }
    );
  }

  async getCustOpenToDos(val: string) { 
    this.spinner.show();
    this.toDosObj.pageNumber = 1;
    this.parameter = val;
    if (this.parameter == "") {
      this.custTableFlag = 1;
      this.toDosObj.solzStatus = { Label: "", Value: 1 }; //Get Open Dos if set to 1
      await this.toDosService.getOpenTodosOfCustomerById(this.toDosObj).subscribe((resC1: any) => { 
        if (resC1.ResponseCode == 100) {   //success
          this.heading = "Item's Assigned to Soluzione Team";
          this.toDoList = resC1.Data;
          resC1.Data.forEach(element => { 
             
            if(element.isBug===true){
             element.isBugyes = 'Bug'
            }
            else{
             element.isBugyes = 'Task'
            }
             
           });
           this.createExportList();
        }
        // else if (resC1.ResponseCode == 999) {
        //   // this.toastr.error(resC1.Message, 'Error');
        // }
        this.spinner.hide();
      },
      //  error => {
      //   this.spinner.hide();
      //   this.toastr.error(error.message);
      // }
      );
    }

    else if (this.parameter == "Tasks Pending Clarification") {
      this.custTableFlag = 2;
      this.toDosObj.solzStatus = { Label: "Needs Clarification", Value: 674180014 };
      await this.toDosService.getOpenTodosOfCustomerById(this.toDosObj).subscribe((resC2: any) => {
        if (resC2.ResponseCode == 100) {//success
          this.heading = this.parameter;
          this.toDoList = resC2.Data;
          this.createExportList();
        }

        // else if (resC2.ResponseCode == 217) { // no records
        //   this.toDoList = [] as Array<ToDosDataModal>;
        // }

        // else if (resC2.ResponseCode == 999) {
        //   // this.toastr.error(resC2.Message, 'Error');
        // }
        this.spinner.hide();
      }, 
      // error => {
      //   this.spinner.hide();
      //   this.toastr.error(error.message);
      // }
      );
    }

    else if (this.parameter == "All Items by Project") {
      this.custTableFlag = 3;
      await this.toDosService.getOpenTodosOfCustomerById(this.toDosObj).subscribe((resC3: any) => {
        if (resC3.ResponseCode == 100) { //success
          this.heading = this.parameter;
          this.toDoList = resC3.Data;
          this.createExportList();
        }
        // else if (resC3.ResponseCode == 999) {
        //   // this.toastr.error(resC3.Message, 'Error');
        // }
        this.spinner.hide();
      },
      //  error => {
      //   this.spinner.hide();
      //   this.toastr.error(error.message);
      // }
      );
    }

    else if (this.parameter.search("Open Items by Project") == 0) {
      this.custTableFlag = 4;
      var splitId = this.parameter.split("|");
      this.toDosObj.projectId = splitId[1];
      this.toDosObj.solzStatus = { Label: "", Value: 1 }; //Get Open Dos if set to 1
      await this.toDosService.getOpenTodosOfCustomerById(this.toDosObj).subscribe((resC4: any) => {
        if (resC4.ResponseCode == 100) { // success
          this.heading = splitId[0];
          this.toDoList = resC4.Data;
          this.createExportList();
        }
        // else if (resC4.ResponseCode == 900) {
        //   // this.toastr.error(resC4.Message, 'Error');
        // }
        this.spinner.hide();
      },
      //  error => {
      //   this.spinner.hide();
      //   // this.toastr.error(error.message);
      // }
      );
    }
  }


  // for employees.
  async getEmployeeProjects(contactId) {
     
    await this.toDosService.getProjectsByCustomerId(contactId).subscribe((empProjects: any) => {
      this.spinner.hide();
      if (empProjects.ResponseCode == 100) { // success
         
        this.allProjectList = empProjects.Data;
        this.toDosObj.ID = this.empId;
        this.getToDosBasedUponParameter(this.parameter); // while loading initially, the parameter is initialized as an empty string and passed here.
        this.createExportList();
       
      }
      // else if (empProjects.ResponseCode == 999) {
      //   // this.toastr.error(empProjects.Message, 'Error');
      // }
    },
    //  error => {
    //   this.spinner.hide();
    //   this.toastr.error(error.message);
    // }
    );
  }

  async getToDosBasedUponParameter(val: string) { 
     
    // this.spinner.show();
    this.parameter = val;
    this.toDosObj.pageNumber = 1;
    if (this.parameter == "") {
       
      this.empTableFlag = 1;
      await this.toDosService.getOpenTodosOfEmployeeById(this.toDosObj).subscribe((res1: any) => { 
        if (res1.ResponseCode == 100) { //success
          this.heading = "ITEMS I'M WORKING ON";
          this.toDoList = res1.Data;
          res1.Data.forEach(element => { 
             
           if(element.isBug===true){
            element.isBugyes = 'Yes'
           }
           else{
            element.isBugyes = 'No'
           }
            
          });
       
        //  this.isBugList = res1.Data.filter(f => f.isBug===true.includes(cvf));
          // create list for exporting to excel.
          this.createExportList();

        }
        else if (res1.ResponseCode == 999) {
          this.toastr.error(res1.Message, 'Error');
        }

        this.spinner.hide();
      },
       error => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
      );
    }

    else if (this.parameter == "All Items of My Projects") {
      this.empTableFlag = 2;
      await this.toDosService.getAllTodosOfEmployeeProjects(this.toDosObj).subscribe((res2: any) => {
        if (res2.ResponseCode == 100) { // success
          this.heading = "ALL ITEMS OF MY PROJECTS";
          this.toDoList = res2.Data;
          // create list for exporting to excel.
          this.createExportList();

        }
        else if (res2.ResponseCode == 999) {
          this.toastr.error(res2.Message, 'Error');
        }

        this.spinner.hide();
      },
       error => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
      );
    }

    else if (this.parameter.search("Open Items by Project") == 0) {
      this.empTableFlag = 3;
      var splitId = this.parameter.split("|");
      this.toDosObj.projectId = splitId[1];
      this.toDosObj.solzStatus = { Label: "", Value: 1 };
      await this.toDosService.getAllTodosOfEmployeeProjects(this.toDosObj).subscribe((res3: any) => {
        if (res3.ResponseCode == 100) { // success
          this.heading = splitId[0].toUpperCase();
          this.toDoList = res3.Data;
          // create list for exporting to excel.
          this.createExportList();

        }
        // else if (res3.ResponseCode == 999) {
        //   // this.toastr.error(res3.Message, 'Error');
        // }

        this.spinner.hide();
      },
      //  error => {
      //   this.spinner.hide();
      //   // this.toastr.error(error.message);
      // }
      );
    }

    else if (this.parameter.search("All Items by Project") == 0) {
      this.empTableFlag = 4;
      var splitId = this.parameter.split("|");
      this.toDosObj.projectId = splitId[1];
      await this.toDosService.getAllTodosOfEmployeeProjects(this.toDosObj).subscribe((res4: any) => {
        if (res4.ResponseCode == 100) { // success
          this.heading = splitId[0].toUpperCase();
          this.toDoList = res4.Data;

          // create list for exporting to excel.
          this.createExportList();

        }
        // else if (res4.ResponseCode == 999) {
        //   // this.toastr.error(res4.Message, 'Error');
        // }

        this.spinner.hide();
      },
      //  error => {
      //   this.spinner.hide();
      //   // this.toastr.error(error.message);
      // }
      );
    }
  }

 // creating user readable list.
 createExportList() {


  for (let i = 0; i < this.toDoList.length; i++) {
    var ex = {
      Item_No: "",
      Created_On: null,
      Title: "",
      ItemsType: "",
      WorkType: "",
      Project_Name: "",
      Item_Description: "",
      Repro_Steps: "",
      Parent_Title: "",
      User_Priority: null,
      Type_Of_Item: null,
      Date_Moved: null,
      Testing_Environment: null,
      Expected_Res_Date: null,
      Comment: "",
      Description_Of_Resolution: "",
      Work_Status: null,
      Is_Client_Reported: "",
      Assignee_Name: "",
      Assigned_Reviewer_Name: "",
      Implementation_Effort: 0,
      Start_Date: null,
      Review_Effort: 0,
      isBug: ""
    }
    
    ex.Item_No = this.toDoList[i].itemNo;
    ex.Created_On = this.toDoList[i].createdon;
    ex.Title = this.toDoList[i].title;
    ex.ItemsType = this.toDoList[i].typeOfItems?.Label;
    ex.WorkType = this.toDoList[i].typeOfItem?.Label;
    ex.Project_Name = this.toDoList[i].projectName;
    ex.Item_Description = this.toDoList[i].itemDescription;
    ex.Repro_Steps = this.toDoList[i].reproSteps;
    ex.Parent_Title = this.toDoList[i].parentTitle;
    ex.User_Priority = this.toDoList[i].userPriority?.Label;
    // ex.Type_Of_Item = this.toDoList[i].typeOfItem?.Label;
    ex.Date_Moved = this.toDoList[i].dateMoved;
    ex.Testing_Environment = this.toDoList[i].testingEnvironment?.Label;
    ex.Expected_Res_Date = this.toDoList[i].expectedResDate;
    ex.Comment = this.toDoList[i].comment;
    ex.Description_Of_Resolution = this.toDoList[i].descriptionOfResolution;
    ex.Work_Status = this.toDoList[i].solzStatus?.Label;


    if (this.toDoList[i].isClientReported == false || this.toDoList[i].isClientReported == null) {
      ex.Is_Client_Reported = "No";
    }

    else {
      ex.Is_Client_Reported = "Yes";
    }
    ex.Assignee_Name = this.toDoList[i].assigneeName;
    ex.Assigned_Reviewer_Name = this.toDoList[i].assignedReviewerName;
    ex.Implementation_Effort = this.toDoList[i].implementationeffort;
    ex.Start_Date = this.toDoList[i].startDate;
    ex.Review_Effort = this.toDoList[i].revieweffort;
    // ex.isBug = this.toDoList[i].isBug
    if (this.toDoList[i].isBug == false || this.toDoList[i].isBug == null) {
      ex.isBug = "No";
    }
    else {
      ex.isBug = "Yes";
    }
    

    this.fieldsToExport[i] = ex;
  }
}


exportToExcel() {
  import("xlsx").then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(this.fieldsToExport);
    const workbook = { Sheets: { 'Exporting_Tasks': worksheet }, SheetNames: ['Exporting_Tasks'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "Exporting_Tasks");
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  
  import("file-saver").then(FileSaver => {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  });
}
  // sending to-do id to new to do page.
  updateToDo(id: string) { 
    this.router.navigate(['/ESSPortal/customer/update_to_doos/' + id]);
  }


   // Here we  optimization of API'S set Data in Cache And Get Whare We Need in this Work Our Loading Time Fast
  //Work Status
  getSolzStatusOptionSet() {
    this.supportReqService.getDropDownData("status").subscribe((solzStatusResp: any) => {
      if (solzStatusResp.ResponseCode == 100) {
        // this.toDosService.getWorkStatusOptionSet(solzStatusResp.Data)
        localStorage.setItem("status", JSON.stringify(solzStatusResp.Data));
        this.spinner.hide();
        this.getTestingEnvOptionSet()
      }
      else {
        this.spinner.hide();
      }
    })
  }

  //Testing Environment
  async getTestingEnvOptionSet() {
    await this.supportReqService.getDropDownData("testingenvironment").subscribe((testRes: any) => {

      if (testRes.ResponseCode == 100) {
        localStorage.setItem("setEnvironment", JSON.stringify(testRes.Data));
        this.spinner.hide();
        this.getUserPriorityOptionSet()

      }
    });
  }
  //User Priority
  async getUserPriorityOptionSet() {
    await this.supportReqService.getDropDownData("userpriority").subscribe((priorityRes: any) => {
      if (priorityRes.ResponseCode == 100) {
        // this.userPriorityList = priorityRes.Data;
        // this.getTestingEnvOptionSet();
        localStorage.setItem("setPriority", JSON.stringify(priorityRes.Data));
        this.spinner.hide();
        // this.getEmployeeProjects()
      }
    });
  }

  //Employee Project 
  // async getEmployeeProjects() {
  //   await this.toDosService.getProjectsByCustomerId(this.empId).subscribe((empProjects: any) => {
  //     this.spinner.hide();
  //     if (empProjects.ResponseCode == 100) { // success
  //       localStorage.setItem("setempProjects", JSON.stringify(empProjects.Data));
  //       this.spinner.hide();
  //       // this.allProjectList = empProjects.Data;
  //     }
  //   });
  // }
    //Toggle for Expand And collapsed Table Row Data
    showHideToggle(){
      this.ToggleFlag=!this.ToggleFlag;
   }
}
