import { ToDosDataModal } from './to-dos-data-model';

export class WorkLogDataModel {
  ID: string;// created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  Name: string; // created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  todo: ToDosDataModal;
  date: string;
  hours: number;   
  description: string;     
  loggedBy: BaseEntityDataModel; // created this because had no other choice, but not gonna change rest of the DMs.
  worklogStatus: any;
  project : string; // created by Pradeep singh for show project getting data for  existing work log or New Work Log.
  reasonForRejection: string;
  approvedRejectedBy: string;
  approvedRejectedOn: any;
  worklogStatusName: any;
}


export class BaseEntityDataModel {
  ID: string;
  Name: string;
}
