import { OptionSetDataModel } from './option-set-data-model';
import { OptionSetDataModels } from './optionSetDataModel-model';

export class SupportRequestDataModel {

  ID: string // created by Udbhav for matching with API DM.(EntityRecordDM).
  Name: string; // created by Udbhav for matching with API DM.(EntityRecordDM).

  //Created for getting string date so that it can easily exported.
  createdon: string;
  expectedResDate: string;
  // expectedResDateE: Date;

  //Not deleted yet because it is used in so many places(Delete it when you have time and optimize the system)
  createdOn: Date;
  ItemId: string;
  itemNo: string;

  //DataModel of Item Details.
  title: string;
  projectName: string;
  projectId: string;
  solzStatus: OptionSetDataModel;
  solzWorkStatus: OptionSetDataModels;
  itemDescription: string;
  reproSteps: string;
  parentId: string;
  parentItemNo: string;
  parentTitle: string;
  userPriority: OptionSetDataModel;
  typeOfItem: OptionSetDataModel;
  typeOfItems: OptionSetDataModel;
  sprintsList: OptionSetDataModel;
  isBug: boolean;
  sprintName: OptionSetDataModel;
  sprintId: OptionSetDataModel;
  linkedTaskName: string;
  linkedTask: any;
  itemType: OptionSetDataModels // for Feature	674180000/  User Story	674180001 / To-Do	674180002/  Bug	674180003
  //public OptionSetDM itemType { get; set; }
  workArea: OptionSetDataModel;
  isClientReported: boolean;
  releaseNumber: string;

  //DataModel of Implementation Part
  assigneeId: string;
  assigneeName: string;
  assignedReviewerId: string;
  assignedReviewerName: string;
  newEmpId: string;
  newEmpName: string;

  //DataModel for details of Support Request
  implementationeffort: number;
  revieweffort: number;
  comment: string;
  createdBy: string;
  createdByContact: string;
  CreatedBy: string;
  startDate: Date;
  testingEnvironment: OptionSetDataModel;
  dateMoved: Date;
  descriptionOfResolution: string;
  endDate: string; // created by Pradeep for matching with API DM.(EntityRecordDM).

  //DataModel for Timesheet
  TSsupportRequest: string;
  tSdate: any;
  tShours: number;
  TSdescription: string;
  TSloggedBy: string;
  toDoList: Array<SupportRequestDataModel>;
  label: Array<string>;
  value: Array<number>;
  customerStatus: OptionSetDataModel;
  userStoryTitleAndName: any;
  linkedTaskTitle: any;
  isWeekend: any;
  leaveType: any;
  isPresent: any
  //  extra addeed data Model 
  userStory: any;
  assignee: any;
  itemNumber: any
attendanceDate: any

}
