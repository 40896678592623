import { LeaveApplicationDataModel } from './leave-application-data-model';
import { OptionSetDataModel } from './option-set-data-model';
import { UserDataModel } from './user-data-model';

export class AttendanceRegisterDataModel {
  employee: UserDataModel;
  leaveDM: LeaveApplicationDataModel;
  leaveAppDM: LeaveApplicationDataModel;

  //Attendance Data Model
  date: Date;
  isPresent: boolean;
  isLate: boolean;
  inTime: Date;
  outTime: Date;
  totalHours: number;
  hoursPunchInOutTime: number;
  breakHours: number;
  noOfBreaks: number;
  deficientHours: number;
  leaveType: OptionSetDataModel;
}
