import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { timer } from 'rxjs';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';

@Component({
  selector: 'app-new-to-dos',
  templateUrl: './new-to-dos.component.html',
  styleUrls: ['./new-to-dos.component.css']
})

export class NewToDosComponent implements OnInit {
  supportReqObj: SupportRequestDataModel;
  selectedCountryAdvanced: any[]
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: ToDosDataModal;
  solzStatusList: Array<OptionSetDataModel> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  typeOfItemList1: any;
  allEmployeeList: any;
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList: any[];
  linkedTaskFlag: boolean = false;
  isBugTodos: any[];
  selectedBugTodoItem: any;
  linkedTask: any;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  selectedCountry: any;
  countries: any[];
  filteredCountries: any[];
  selectedCountries: any[];
  filteredBrands: any[];
  filteredGroups: any[];
  msg: boolean;
  data: any;
  msglinkedTaskFlag: boolean = true;
  errorType: string;
  viewImageSrc = null;
  tempImgArray = [];
  searchText: string = '';
  filteredUserStoryList: any[] = [];  // Array to hold the filtered items
  parentId: any[];
  UserStorydataCheck: any
  msg1: boolean;




  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    public sharepointServices: SharepointServiceService,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2, private elRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    // this.getProjectSprints();
    this.supportReqForm = this.fb.group({
      ID: [''],
      Name: [''],
      createdon: [''],
      expectedResDate: [null],
      endDate: [null],
      // expectedResDateE:[null],
      createdOn: [null],
      ItemId: [''],
      itemNo: [''],
      title: ['', [Validators.required]],
      projectName: [''],
      projectId: [null, [Validators.required]],
      solzStatus: ['',],
      itemDescription: ['', [Validators.required]],
      reproSteps: [''],
      parentId: [''],
      selectedCountryAdvanced: [''],
      parentItemNo: [''],
      parentTitle: [''],
      userPriority: [null, [Validators.required]],
      typeOfItem: ["674180002",],
      workArea: [null],
      isClientReported: ["false", [Validators.required]],
      releaseNumber: [''],
      assigneeId: [null],
      assigneeName: [''],
      assignedReviewerId: [Validators.required],
      assignedReviewerName: [Validators.required],
      newEmpId: [''],
      newEmpName: [''],
      implementationeffort: [null],
      revieweffort: [null],
      comment: [''],
      createdBy: [''],
      createdByContact: [''],
      CreatedBy: [''],
      startDate: [null],
      testingEnvironment: [null],
      dateMoved: [null],
      descriptionOfResolution: [''],
      typeOfItems: ['674180002'],
      isBug: ["false", [Validators.required]],
      sprintName: [''],
      sprintId: [null],
      isBugTodosItem: [''],
      linkedTask: [null]


    });

    this.getData(); // getting data from local storage api and setting it in our local variable.

    //get data from local storage (work status , testing environment, priority , Emp projects by emp Id)
    this.solzStatusList = JSON.parse(localStorage.getItem('setWorkStatus'));
    this.testingEnvironmentList = JSON.parse(localStorage.getItem('setEnvironment'));
    this.userPriorityList = JSON.parse(localStorage.getItem('setPriority'));
    // this.allProjectList = JSON.parse(localStorage.getItem('setempProjects'));  
    this.supportReqForm.patchValue({
      solzStatus: 674180000,
      userPriority: this.userPriorityList[2].Value,
      // projectId: this.allProjectList[0].ID,
    });

    const ce = this.elRef.nativeElement.querySelector('#commentDiv');
    document.getElementById('commentDiv').addEventListener(
      'keydown', (e: KeyboardEvent) => {
        if (!((e.ctrlKey && (e.key == 'v' || e.key == 'V')) || e.code == 'Backspace')) e.preventDefault();
      }
    )
    // this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
    //   e.preventDefault();
    //   document.getElementById('commentDiv').innerHTML += ' ';
    //   if (e.clipboardData && e.clipboardData.items.length) {
    //     let clipboardItems = e.clipboardData.items;
    //     for (let i = 0; i < clipboardItems.length; i++) {
    //       const item = clipboardItems[i];

    //       if (item.type.indexOf('image') !== -1) {
    //         const file = item.getAsFile();
    //         let fileReader = new FileReader();
    //         if (file) {
    //           let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

    //           fileReader.onloadend = () => {
    //             const base64 = fileReader.result as string;
    //             let data = {
    //               fileName: fileName,
    //               base64: base64
    //             };

    //             this.tempImgArray.push(data)

    //           }


    //           if (file) {
    //             fileReader.readAsDataURL(file);
    //           }
    //           // Create button element
    //           const button = this.renderer.createElement('a');
    //           button.setAttribute('style', 'cursor: pointer; color: blue'); // Ensuring button type is button
    //           button.setAttribute('data-toggle', 'modal'); //data-toggle="modal" data-target="#moreInfoModal1"
    //           button.setAttribute('data-target', '#viewImage');
    //           this.renderer.setAttribute(button, 'contenteditable', 'false'); // Make button non-editable
    //           let element = document.getElementById('viewImage');
    //           this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
    //           const icon = this.renderer.createElement('i');
    //           this.renderer.addClass(icon, 'fa-regular');
    //           this.renderer.addClass(icon, 'fa-image');
    //           this.renderer.addClass(icon, 'mx-2');
    //           const text = this.renderer.createText(fileName);

    //           this.renderer.appendChild(button, icon);
    //           this.renderer.appendChild(button, text);
    //           this.renderer.appendChild(ce, button);
    //         }
    //       }
    //       if (this.tempImgArray) {
    //         this.selectAttachments(this.tempImgArray)
    //       }

    //       // if (item.type.indexOf('text') !== -1) {
    //       //   const text = e.clipboardData.getData('text/plain');
    //       //   document.execCommand('insertText', false, text);
    //       // }
    //     }
    //   }
    // });
    this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(ce, button);
            }
          }
        }

        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        if (files.length > 0) {
          this.selectAttachments(mockEvent); // Call selectAttachments with the mock event
        }
      }
    });

  }

  downloadImg(fileName: string, template) {
    this.viewImageSrc = this.tempImgArray.find(img => img.fileName == fileName);
  }

  async downloadPreviewImage() {
    let FileSaver = await import("file-saver");
    FileSaver.saveAs(this.viewImageSrc.base64, this.viewImageSrc.fileName);
  }

  checkValidityUserstory(event) {debugger
    if (event) {
      this.UserStorydataCheck = event
    }
  }

  //*********************** Get APIs Begin *****************************

  async getData() {
    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;

    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {
      this.empData = empNewRequest.userProfile;
      // If Soluzione Emp/Manager logged in
      if (this.empData.userId) {
        this.contactLoggedIn = false;
        this.isManager = this.empData?.isManager;
        this.empId = this.empData?.userId
        this.getEmployeeProjects(this.empData.userId);
        this.getAllEmployeeDetails()

      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  async getEmployeeProjects(empId) {
    await this.toDosService.getProjectsByCustomerId(empId).subscribe((empProjects: any) => {
      this.spinner.hide();
      if (empProjects.ResponseCode == 100) { // success
        this.allProjectList = empProjects.Data
        this.spinner.hide();
        // this.allProjectList = empProjects.Data;
      }
    });
  }
  // Sprint Based On Project Selected
  async getProjectSprints(projectId) {
    await this.toDosService.getProjectSprints(projectId).subscribe((sprintResp: any) => {
      if (sprintResp.ResponseCode == 100) {//success
        this.sprintsList = sprintResp.Data


      }
      else if (sprintResp.ResponseCode == 999) {
        this.toastr.error(sprintResp.message, 'Error');
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getAllEmployeeDetails() {
    await this.supportReqService.getAllEmployeeData().subscribe((allEmpRes: any) => {
      if (allEmpRes.ResponseCode == 100) {

        this.allEmployeeDetails = allEmpRes.Data;
        setTimeout(() => { }, 3000);
        //this.projectSelected(this.supportReqForm.value.projectId);

        // If Contact Logged In.
        if (this.contactLoggedIn) {
          this.contactLoggedIn = true;
          this.supportReqForm.value.createdByContact = this.empData.contact?.ID;

          //Different Type of Item dropdown for customer to show
          this.typeOfItemList = [
            { Value: 674180003, Label: "Bug" },
            { Value: 674180004, Label: "Data Correction" },
            { Value: 674180001, Label: "Documentation / Content Development" },
            { Value: 674180002, Label: "New Development" }
          ];

          this.supportReqForm.value.projectId = this.allProjectList[0].ID;
          this.projectSelected(this.supportReqForm.value.projectId);

          this.solzStatusList = [{ Value: 100000001, Label: 'Approved' }];
          //this.supportReqForm.value.solzStatus = 100000001;
          this.supportReqForm.patchValue({
            solzStatus: 100000001
          });
        }

        else {
          this.contactLoggedIn = false;
          this.supportReqForm.value.isClientReported = "false";
          this.supportReqForm.value.createdBy = this.empId;
          this.supportReqForm.value.createdByContact = this?.empData?.userId;
        }

      }
      else if (allEmpRes.ResponseCode == 999) {
        this.toastr.error(allEmpRes.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //*********************** Get APIs End *****************************

  async getAllEmployeeListOnProjectId(id) {
    await this.toDosService.getAllEmployeeData(id).subscribe((allEmpRes: any) => {
      if (allEmpRes.ResponseCode == 100) {

        this.allEmployeeList = allEmpRes.Data;
      }

    })
  }
  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }

  // Assign Assignee or Assigned Reviewer as the current user according to flag value which is sent on click event.
  assignMe(val: number) {
    if (val == 0) {
      this.supportReqForm.patchValue({
        assigneeId: this.empId
      });
    }

    else {
      this.supportReqForm.patchValue({
        assignedReviewerId: this.empId
      });
    }
  }


  // Get all the Project UserStories  with project id

  async projectSelected(projectId: any) {

    this.getProjectSprints(projectId);
    this.getAllEmployeeListOnProjectId(projectId);
    this.GetAllToDoByIsBugTrueOrFalse(projectId);
    var project = { value: projectId, label: this.supportReqForm.value.isClientReported }

    await this.supportReqService.getAllProjectUserStories(projectId).subscribe((userStoryResp: any) => {
      if (userStoryResp.ResponseCode == 100) {

        // success
        if (userStoryResp.Data) {
          this.userStoryList = userStoryResp.Data;
        }
      }

      else if (userStoryResp.ResponseCode == 999) {
        this.toastr.error(userStoryResp.Message, 'Error');
        this.spinner.hide();

      }
      else {
        this.userStoryList = [];

      }
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  /*On Change of solz status i.e if solz status is  "Not Started" or "Analyzing" or "On Hold" or "Duplicate" or "QA Failed" or "Canceled" than ETA and Estimated Effort is not Required, else Bussiness Required.*/
  solzStatusSelected(val: number) {
    // If Employee Logged In.
    if (this.empData.contact == null) {
      if (val == 674180000 || val == 674180009 || val == 674180008 || val == 674180012 || val == 674180006 || val == 674180010 || val == 674180004 || val == 674180005) {
        this.fieldRequiredFlag = false;
        this.startDateFlag = false;
      }

      else {
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
      }

      //If Solz status is "Review in progress" or "Review Complete" or "Review Failed" or "Deployed" then change review effort to required or else not required
      if (val == 674180004 || val == 674180005 || val == 674180007) {
        this.reviewerEffortfieldFlag = true;
        this.startDateFlag = true;
      }

      else {
        this.reviewerEffortfieldFlag = false;
      }
    }
  }

  // this API For All Todos By Is Bug Status based on condation 
  async GetAllToDoByIsBugTrueOrFalse(projectId) {

    await this.toDosService.GetAllToDoByProjectId(projectId).subscribe((respBug: any) => {


      if (respBug.ResponseCode == 100) {
        // success

        this.isBugTodos = respBug.Data;
      }
      else if (respBug.ResponseCode == 999) {
        this.toastr.error(respBug.Message, 'Error');
        this.spinner.hide();

      }
      else {
        this.isBugTodos = [];

      }
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  updateParentUserStory(linkedTaskId) {
    // Find the linked task with the specified ID
    this.linkedTask = this.isBugTodos.filter(task => task.ID === linkedTaskId.ID);
    // this.selectedCountry = this.linkedTask[0].userStoryId
    this.supportReqForm.patchValue({
      // parentId: this.linkedTask[0].userStoryName +' | '+ this.linkedTask[0].parentTitle,
      parentId: this.linkedTask[0].userStoryId,
      assigneeId: this.linkedTask[0].assigneeId,
      assignedReviewerId: this.linkedTask[0].assignedReviewerId
    });

  }

      // Get filtered user story list based on the searchText input
      filterUserStories(event: any) {debugger
        this.msg1 = false
        let filtered: any[] = [];
        let query = event.query.toLowerCase();  // Convert the query to lowercase for case-insensitive matching
      
        for (let i = 0; i < this.userStoryList?.length; i++) {
          let userStory = this.userStoryList[i];
      
          // Perform case-insensitive search for any word related to the query
          if (userStory.itemNo.toLowerCase().includes(query) || userStory.title.toLowerCase().includes(query)) {
            filtered.push(userStory);
          }
        }
      
        // Update the filtered list
        this.filteredUserStoryList = filtered;
      }
  
  
    



  selectAttachments(event) {

    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };

          // Get the current date and time
          const now = new Date();
          const day = String(now.getDate()).padStart(2, '0');
          const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
          const year = String(now.getFullYear()).slice(2); // Get last two digits of the year
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');

          // Format the date and time as ddmmyy hhmmss
          const timestamp = `${day}${month}${year}${hours}${minutes}${seconds}`;
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          // Append the timestamp to the original file name
          uploadFile["filename"] = `${file.name.split('.').slice(0, -1).join('.')}_${timestamp}.${file.name.split('.').pop()}`;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }
  checkValidity(event) {
    if (event) {
      this.data = event

    }
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    // Find the first invalid control
    const invalidControl = Object.keys(form.controls).find(key => {
      return form.get(key)?.invalid;
    });

    if (invalidControl) {
      const invalidElement = document.querySelector(`[formControlName="${invalidControl}"]`);

      if (invalidElement) {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (invalidElement as HTMLElement).focus();
      }
    }
  }


  onSubmit() {
debugger

    this.spinner.show();
    this.supportReqFormSubmitted = true;
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      this.scrollToFirstInvalidControl(this.supportReqForm)
      return;
    }
  

      if (this.supportReqForm.value.selectedCountryAdvanced === this.data) {
        this.msg = true;
        this.spinner.hide();
        return;
      }
 
    if(this.UserStorydataCheck != null){
    if (this.supportReqForm.value.parentId === this.UserStorydataCheck) {
      this.msg1 = true;
      this.spinner.hide();
      return;
    }
  }

    // if(this.supportReqForm.value.expectedResDateE < this.supportReqForm.value.startDate){
    //   this.toastr.info(" Start Date Is Less Than EexpectedResDate ");
    //   this.spinner.hide();
    //   return;
    // }
    var today = new Date();
    this.supportReqForm.value.createdOn = moment(today).format('YYYY-MM-DD');


    // const commentDiv = document.getElementById('commentDiv');
    // if (commentDiv) {
    //   const content = commentDiv.innerHTML;
    //   console.log('Submitted Content:', content);
    //   this.supportReqForm.value.comment = content;
    // }


    if (this.contactLoggedIn) {
      this.supportReqForm.value.isClientReported = "true";
    }

    var st = this.supportReqForm.value.solzStatus;
    this.supportReqForm.value.solzStatus = new Object();
    this.supportReqForm.value.solzStatus.Value = st;



    var up = this.supportReqForm.value.userPriority;
    this.supportReqForm.value.userPriority = new Object();
    this.supportReqForm.value.userPriority.Value = up;

    var ti = this.supportReqForm.value.typeOfItem;
    this.supportReqForm.value.typeOfItem = new Object();
    this.supportReqForm.value.typeOfItem.Value = ti;

    //TypeofItems Field Hide In html but send value on toto
    var toi = this.supportReqForm.value.typeOfItems;
    this.supportReqForm.value.typeOfItems = new Object();
    this.supportReqForm.value.typeOfItems.value = toi;


    //Sprint Id Send 
    var spi = this.supportReqForm.value.sprintId;
    this.supportReqForm.value.sprintId = new Object();
    this.supportReqForm.value.sprintId = spi;


    //Linked Task
    var lts = this.supportReqForm.value.selectedCountryAdvanced.ItemId;
    this.supportReqForm.value.linkedTask = new Object();
    this.supportReqForm.value.linkedTask = lts;

    var pri = this.supportReqForm?.value?.parentId?.ItemId;
    this.supportReqForm.value.parentId = new Object();
    this.supportReqForm.value.parentId = pri;

    // var pri = this.supportReqForm.value.parentId.ItemId;
    // this.supportReqForm.value.parentId = new Object();
    // this.supportReqForm.value.parentId = pri;

    // var pri = this.supportReqForm.value.selectedCountryAdvanced.ItemId;
    // this.supportReqForm.value.parentId = new Object();
    // this.supportReqForm.value.parentId = pri;

    var isr = this.supportReqForm.value.isClientReported;
    this.supportReqForm.value.isClientReported = JSON.parse(isr);

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.isBug;
    this.supportReqForm.value.isBug = JSON.parse(ib);

    this.supportReqForm.value.workArea = new Object();
    this.supportReqForm.value.workArea.Value = 100000000; // setting work area to Hourly Task permanently.

    if (this.supportReqForm.value.testingEnvironment != null) {
      var te = this.supportReqForm.value.testingEnvironment;
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = te;
    }

    else {
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = 674180004
    }

    if (this.supportReqForm.value.startDate != null) {
      var date = new Date(this.supportReqForm.value.startDate);
      var sd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.startDate = sd;
    }

    if (this.supportReqForm.value.expectedResDate != null) {
      var erd = this.supportReqForm.value.expectedResDate;
      this.supportReqForm.value.expectedResDate = moment(erd).format('YYYY-MM-DD');
    }
    if (this.supportReqForm.value.endDate != null) {
      var ed = this.supportReqForm.value.endDate;
      this.supportReqForm.value.endDate = moment(ed).format('YYYY-MM-DD');
    }

    if (!this.contactLoggedIn) {
      this.supportReqForm.value.createdBy = this.empId;
      this.supportReqForm.value.CreatedBy = this.empId;
    }

    this.supportReqService.postNewToDo(this.supportReqForm.value).subscribe((newRes: any) => {
      if (newRes.ResponseCode == 100) {// success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = newRes.Data;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }

        else { // else just show message.
          this.spinner.hide();
          this.toastr.success(newRes.Message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + newRes.Data]);
        }
      }

      else if (newRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(newRes.Message);
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }



  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {


    await this.sharepointServices.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        this.spinner.hide();
        this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.upLoadFileList[0]?.ID]);

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
  expandLabel(selected: any) {

    if (selected == "true") {

      this.linkedTaskFlag = true;
      this.supportReqForm.controls['selectedCountryAdvanced'].setValidators([Validators.required]);
      this.supportReqForm.controls['selectedCountryAdvanced'].updateValueAndValidity();
    }
    else {
      this.linkedTaskFlag = false;
      this.supportReqForm.controls['selectedCountryAdvanced'].clearValidators();
      this.supportReqForm.controls['selectedCountryAdvanced'].updateValueAndValidity();
    }
  }
  filterCountry(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.userStoryList?.length; i++) {
      let country = this.userStoryList[i];
      // country.forEach(y => {
      if (country.itemNo.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
      // });

    }
    this.filteredCountries = filtered;
    // this.userStoryList = filtered;
  }

  filterCountryLinkedTask(event: any) {
    let filtered: any[] = [];
    let query = event.query.toLowerCase(); // Convert the query to lowercase for case-insensitive matching

    for (let i = 0; i < this.isBugTodos?.length; i++) {
      let country = this.isBugTodos[i];

      // Perform case-insensitive search for any word related to the query
      if (country.itemNo.toLowerCase().includes(query) || country.title.toLowerCase().includes(query)) {
        filtered.push(country);
      }
    }

    this.filteredCountries = filtered;
  }



}
