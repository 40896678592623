import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AttendanceRegisterDataModel } from '../../../_models/attendance-register-data-modal';
import { LeaveApplicationDataModel } from '../../../_models/leave-application-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { UserDataModel } from '../../../_models/user-data-model';
import { LeaveService } from '../../../_services/leave.service';
import { Sort } from '../../../_services/sort.service';


@Component({
  selector: 'app-daily-login-update',
  templateUrl: './daily-login-update.component.html',
  styleUrls: ['./daily-login-update.component.css']
})
export class DailyLoginUpdateComponent implements OnInit {

  attendObj: AttendanceRegisterDataModel;
  attendanceList: AttendanceRegisterDataModel[];
  leaveObj: LeaveApplicationDataModel;
  userObj: UserDataModel;
  optionObj: OptionSetDataModel;
  optionObj2: OptionSetDataModel;
  userData: any
  month: any;
  year: any;

  constructor(private sort: Sort,
    public leaveService: LeaveService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) {

    this.userObj = new UserDataModel();
    this.leaveObj = new LeaveApplicationDataModel();
    this.optionObj = new OptionSetDataModel();
    this.optionObj2 = new OptionSetDataModel();

  }

  ngOnInit(): void {
     
    this.attendObj = new AttendanceRegisterDataModel();
    this.spinner.show();
    this.route.paramMap.subscribe(params => {
      this.month = params.get('month');
      this.year = params.get('year');
    })
    var m = parseInt(this.month);
    this.month = m;
    var y = parseInt(this.year);
    this.year = y;
    this.getData(); // getting data from local storage api and setting it in our local variable.

  }

  async getData() {
    await this.storage.get('empDetails').subscribe((empAttendance) => {
     
      this.userData = empAttendance;
      this.userObj.ID = this.userData.ID;
      this.attendObj.employee = this.userObj;

      //passing month in various DMs so that it can reach Attendance Register DM.
      this.optionObj.Value = this.month;
      this.leaveObj.Month = this.optionObj;
      this.attendObj.leaveDM = this.leaveObj;

      //similarly passing year.
      this.optionObj2.Value = this.year;
      this.leaveObj.Year = this.optionObj2;
      this.attendObj.leaveAppDM = this.leaveObj;

      this.postAttendanceRecords();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  /* actually here we are doing nothing other than getting data from the database but, as we can't send a DM in a 'get' request hence 'Post' is used but we'll get the attendance data only in return. */
  async postAttendanceRecords() {
    await this.leaveService.postAttendanceList(this.attendObj).subscribe((res: any) => {
      
      if (res.ResponseCode == 100) { // success
        this.attendanceList = res.Data;
      }
      else if (res.ResponseCode == 999) {
        this.toastr.error(res.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
}
