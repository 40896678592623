import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterDropdownServiceService {
  apiURL: string = "";
  authapiURL: string = "";
  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
    this.authapiURL = environment.authapiURL;
  }

  getMasterDropdown(dropdownStatusName){
    return this.HttpClient.get(this.authapiURL + 'Master/GetOptionSet?DropDownName=' + dropdownStatusName)
  }

}
