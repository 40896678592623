import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PaymentInvoiceService } from '../_services/payment-invoice.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
 
 
  constructor(private _formBuilder: FormBuilder,
    public paymentInvoiceServices: PaymentInvoiceService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {  
    
  }
}


