import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../_services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { duration } from 'moment';
// import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';

@Component({
  selector: 'app-dashboard-activity-days',
  templateUrl: './dashboard-activity-days.component.html',
  styleUrls: ['./dashboard-activity-days.component.scss']
})
export class DashboardActivityDaysComponent implements OnInit {
  eventList: any;
  eventList11: any;
  startDateTime: any;
  openModeldiscraption: any;
  position: string;
  displayPosition: boolean = false;
  alertFlag: boolean = false;
  alerts: any = [
    {
      type: 'info',
      msg: `No holidays to display`
    },
  ]
  Duration: any;
  eventName: any;
  eventType: any;

  constructor(public dashboardService: DashboardService,
    public toastr: ToastrService,
    // public spinner: NgxSpinner
  ) { }

  ngOnInit(): void {
    this.displayPosition = false;
    this.getUpcomingEvents();
    // this.spinner.show()
  }

 async getUpcomingEvents() {
    this.dashboardService.getActivityDays().subscribe((events: any) => {

      if (events.isSuccessful == true) {//success 
        this.alertFlag = false;
        this.eventList = events.data;
        this.eventList.sort((a,b) => a.startDateTime.localeCompare(b.startDateTime))

      }
      else if(events?.messageDetail?.message_code == 900){
        this.alertFlag = true;
      }
      else if (events?.messageDetail?.message_code == 999) {
        // this.toastr.error(events?.messageDetail?.message, 'Error');
        this.alertFlag = true;
      }
      // this.spinner.hide();
    }, error => {
      // this.spinner.hide();
      // this.toastr.error(error.error.messageDetail.message);
    });
    
  }
  showPositionDialog(position: string, item) {
    
    this.position = position;
    this.displayPosition = true;

    this.eventList.forEach(element => {
      
      if (element.id === item) {

        this.openModeldiscraption = element.eventDescription;
        this.eventType = element.type.label;
        this.eventName = element.eventName;
        this.startDateTime = element.startDateTime;
        this.Duration = element.duration

      }
    });
  }

}
