import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  constructor(private storage: StorageMap) { }

  getToken() { 
    return !!this.storage.get('empDetails');
  }
}
