import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToDosService {
  apiURL: string = "";
  authapiURL: string = "";
  // allProjects = [];


  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
    this.authapiURL = environment.authapiURL;
  }

  // using behiviour subject for passing firlter value of todo 

   // State Inspection
   private selectedIns = new BehaviorSubject<any>(null);
   latestStateInspection = this.selectedIns.asObservable();

   //State Inspection
   updateStateInspection(roles: any) {
    this.selectedIns.next(roles);
    }
  // setAllProjects(){
  //   this.getProjectsByCustomerId(1).subscribe((response: any) => {
  //     this.allProjects = response;
  //   })
  // }

  // getting all to do tasks by posting required data
  getAllToDoTasks(toDoModel: any): Observable<any> {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetOpenTodosOfEmployeeById/', toDoModel);
  }

  //Only those projects will be shown in which the employee is working.
  getProjectsByCustomerId(contactId: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'SupportRequest/GetEmployeeProjectsList?isManager=false&employeeId=' + contactId);
  }

  getProjectsByCustomerId1(contactId: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'ToDos/GetProjectsByCustomerId/' + contactId);
  }

  //posting todosDM and getting customer project(s) info.
  getOpenTodosOfCustomerById(toDoModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetOpenTodosOfCustomerById/', toDoModel);
  }

  // posting todosDM and getting employee project(s) info.
  getOpenTodosOfEmployeeById(toDoModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetOpenTodosOfEmployeeById/', toDoModel);
  }

  // posting todosDM and getting all project(s) info.
  getAllTodosOfEmployeeProjects(toDoModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetAllTodosOfEmployeeProjects/', toDoModel);
  }

  getToDosByProjectId(project: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetClientReportedToDosByProjectId/', project);
  }

  getParentToDos(project: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/GetClientReportedToDosByProjectId/', project);
  }

  updateToDo(toDosModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/UpdateToDo', toDosModel);
  }

  getWorkLogsByEmpIdAndToDoId(empId: string, toDoId: string) {
    return this.HttpClient.get(this.apiURL + 'ToDos/GetWorkLogsByEmpIdOnTodo?record=' + toDoId + '&employee=' + empId);
  }

  //getToDoTasksByToDoIdAndIsManager(toDoId: string, isManager: boolean, flag: number) {
  getToDoTasksByToDoIdAndIsManager(toDoId: string, isManager: boolean) {
    //if (flag == 0) {
    //  var emp = { ID: toDoId, isManager: isManager };
    //  return this.HttpClient.post(this.apiURL + "ToDos/SearchTodosTasks/" + toDoId, emp);
    //}
    //else {

    //}
    var emp = { ID: toDoId, isManager: isManager };
    return this.HttpClient.post(this.apiURL + "ToDos/SearchTodosTasks/" + toDoId, emp);

  }

  getWorkLogById(id: string) {
    return this.HttpClient.get(this.apiURL + "ToDos/GetWorkLogById/" + id);
  }

  checkExistingWorkLog(toDoId: string, date: string, empId: string) {
    return this.HttpClient.get(this.apiURL + "ToDos/CheckExistingWorkLog/" + toDoId + "?date=" + date + "&employee=" + empId);
  }

  saveWorkLog(workLogModel: any) {
    return this.HttpClient.post(this.apiURL + "ToDos/SaveWorkLog/", workLogModel);
  }
  getAllEmployeeData(id:any) {
    return this.HttpClient.get(this.apiURL + 'ToDos/GetAllEmployeeofProject?projectId='+id);
    // GetAllEmployeeDetailsLists
  }

  //Get Sprints based on project 
  getProjectSprints(id:any){
    return this.HttpClient.get(this.apiURL + 'ToDos/GetProjectSprints?projectId='+id );
  }

  //is bug yes or no show todo based on condation 
  GetAllToDoByProjectId(id:any){
    return this.HttpClient.get(this.apiURL + 'SupportRequest/GetAllToDoByProjectId?projectId='+id );
  }

   // posting todosDM and getting all project(s) info.
   GetMyObservationToDos() {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetMyObservationToDos' );
  }

  CreateMyObservation(supportReqModel: any) {
    return this.HttpClient.post(this.authapiURL + 'ToDos/CreateMyObservation', supportReqModel);
  }

  getProjectListRecord(manager: any) {
    return this.HttpClient.get(this.authapiURL + 'ToDos/GetProjectsList?isManager=' + manager);
  }

  getobservationDetailsForUpdation(toDoId: string) {

    return this.HttpClient.get(this.authapiURL + "ToDos/GetObservationDetailsById?observationId=" + toDoId );
  }
  updateobservation(toDosModel: any) {
    return this.HttpClient.post(this.authapiURL + 'ToDos/UpdateMyObservation', toDosModel);
  }

}
