import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { timer } from 'rxjs';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';

@Component({
  selector: 'app-new-to-dos',
  templateUrl: './new-to-dos.component.html',
  styleUrls: ['./new-to-dos.component.css']
})

export class NewToDosComponent implements OnInit {
  supportReqObj: SupportRequestDataModel;
  // selectedCountryAdvanced: any[]
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: ToDosDataModal;
  solzStatusList: Array<OptionSetDataModel> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  upLoadImageFileList: Array<UploadFileDataModel> = [];
  typeOfItemList1: any;
  allEmployeeList: any;
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList: any[];
  linkedTaskFlag: boolean = false;
  isBugTodos: any[]; //
  selectedBugTodoItem: any;
  linkedTask: any;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  selectedCountry: any;
  countries: any[];
  filteredCountries: any[];
  selectedCountries: any[];
  filteredBrands: any[];
  filteredGroups: any[];
  msg: boolean;
  data: any;
  msglinkedTaskFlag: boolean = true;
  errorType: string;
  viewImageSrc = null;
  tempImgArray = [];
  filteredUserStoryList: any[] = [];  // Array to hold the filtered items
  parentId: any[];
  UserStorydataCheck: any
  msg1: boolean;
  selectedUserStoryId: any;
  isBugSelected: boolean = false;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    public SharepointService: SharepointServiceService,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2, private elRef: ElementRef,
    private MasterDropdownServiceService: MasterDropdownServiceService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    // this.getProjectSprints();
    this.supportReqForm = this.fb.group({
      ID: [''],
      plannedEndDate: [null],
      ItemId: [''],
      itemNo: [''],
      title: ['', [Validators.required]],
      projectName: [''],
      projectId: [null, [Validators.required]],
      solzStatus: ['',],
      itemDescription: ['', [Validators.required]],
      reproSteps: [''],
      parentId: ['', [Validators.required]],
      userPriority: [null, [Validators.required]],
      isClientReported: ["false", [Validators.required]],
      assigneeId: [null],
      assigneeName: [''],
      implementationEffort: [0, [Validators.required]],
      comment: [''],
      createdBy: [''],
      plannedStartDate: [null],
      itemType: ["674180002", [Validators.required]],
      linkedTask: [null]


    });

    this.getMasterUserPriorityDropdown()
    // by default todo selected in item  type
    this.isBugSelected = this.supportReqForm.value.isBug === 674180002;
    this.getData(); // getting data from local storage api and setting it in our local variable.

    //get data from local storage (work status , testing environment, priority , Emp projects by emp Id)
    // this.solzStatusList = JSON.parse(localStorage.getItem('setWorkStatus'));
    // this.testingEnvironmentList = JSON.parse(localStorage.getItem('setEnvironment'));
    // this.userPriorityList = JSON.parse(localStorage.getItem('setPriority'));
    // this.allProjectList = JSON.parse(localStorage.getItem('setempProjects'));  
    // this.supportReqForm.patchValue({
    //   solzStatus: 674180000,
    //   userPriority: this.userPriorityList[2].Value,
    //   // projectId: this.allProjectList[0].ID,
    // });
    // this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
    //   e.preventDefault();
    //   document.getElementById('commentDiv').innerHTML += ' ';
    //   if (e.clipboardData && e.clipboardData.items.length) {
    //     let clipboardItems = e.clipboardData.items;
    //     for (let i = 0; i < clipboardItems.length; i++) {
    //       const item = clipboardItems[i];

    //       if (item.type.indexOf('image') !== -1) {
    //         const file = item.getAsFile();
    //         let fileReader = new FileReader();
    //         if (file) {
    //           let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

    //           fileReader.onloadend = () => {
    //             const base64 = fileReader.result as string;
    //             let data = {
    //               fileName: fileName,
    //               base64: base64
    //             };

    //             this.tempImgArray.push(data)

    //           }


    //           if (file) {
    //             fileReader.readAsDataURL(file);
    //           }
    //           // Create button element
    //           const button = this.renderer.createElement('a');
    //           button.setAttribute('style', 'cursor: pointer; color: blue'); // Ensuring button type is button
    //           button.setAttribute('data-toggle', 'modal'); //data-toggle="modal" data-target="#moreInfoModal1"
    //           button.setAttribute('data-target', '#viewImage');
    //           this.renderer.setAttribute(button, 'contenteditable', 'false'); // Make button non-editable
    //           let element = document.getElementById('viewImage');
    //           this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
    //           const icon = this.renderer.createElement('i');
    //           this.renderer.addClass(icon, 'fa-regular');
    //           this.renderer.addClass(icon, 'fa-image');
    //           this.renderer.addClass(icon, 'mx-2');
    //           const text = this.renderer.createText(fileName);

    //           this.renderer.appendChild(button, icon);
    //           this.renderer.appendChild(button, text);
    //           this.renderer.appendChild(ce, button);
    //         }
    //       }
    //       if (this.tempImgArray) {
    //         this.selectAttachments(this.tempImgArray)
    //       }

    //       // if (item.type.indexOf('text') !== -1) {
    //       //   const text = e.clipboardData.getData('text/plain');
    //       //   document.execCommand('insertText', false, text);
    //       // }
    //     }
    //   }
    // });


  }





  //*********************** Get APIs Begin *****************************

  async getData() {
    this.copyPastImageDuplicate();
    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;

    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {
      this.empData = empNewRequest.userProfile;
      // If Soluzione Emp/Manager logged in
      if (this.empData.userId) {
        this.contactLoggedIn = false;
        this.isManager = this.empData?.isManager;
        this.empId = this.empData?.userId
        this.getEmployeeProjects();
        // this.getAllEmployeeDetails()

      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  async getEmployeeProjects() {
    await this.toDosService.GetProjectsList(this.isManager).subscribe((empProjects: any) => {
      this.spinner.hide();
      if (empProjects.isSuccessful == true) { // success
        this.allProjectList = empProjects.data
        this.spinner.hide();
        // this.allProjectList = empProjects.Data;
      }
      else if (empProjects.isSuccessful == false) {
        this.allProjectList = []
        this.spinner.hide();
        if (empProjects?.messageDetail?.message_code != 204) {
          this.toastr.error(empProjects?.messageDetail?.message)
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail.message);
    });
  }
  // Sprint Based On Project id
  async getProjectSprints(projectId) { //remove from hare 
    await this.toDosService.GetProjectSprintByProjectId(projectId).subscribe((sprintResp: any) => {
      if (sprintResp.isSuccessful == true) {//success
        this.sprintsList = sprintResp.data


      }
      else if (sprintResp.isSuccessful == false) {
        if (sprintResp?.messageDetail?.message_code != 204) {
          this.sprintsList = []
          this.toastr.error(sprintResp?.messageDetail?.message, 'Error');
        }
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail.message);
    });
  }

  //*********************** Get APIs End *****************************

  async GetEmployeeByProjectId(id) {
    await this.toDosService.GetEmployeeByProjectId(id).subscribe((allEmpRes: any) => {
      if (allEmpRes.isSuccessful == true) {

        this.allEmployeeList = allEmpRes?.data;
      }
      else if (allEmpRes.isSuccessful == false) {
        this.spinner.hide()
        if (allEmpRes?.messageDetail?.message_code != 204) {
          this.allEmployeeList = []
          this.toastr.error(allEmpRes?.messageDetail?.message)
        }
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })
  }


  async getMasterUserPriorityDropdown() {  debugger
    await this.MasterDropdownServiceService.getMasterDropdown('UserPriority').subscribe((priorityRes: any) => {
      if (priorityRes.isSuccessful == true) {

        this.userPriorityList = priorityRes?.data;
        this.supportReqForm.patchValue({
          userPriority: priorityRes?.data[2]?.value,
        });
      }
      else if (priorityRes.isSuccessful == false) {
        this.spinner.hide()
        if (priorityRes?.messageDetail?.message_code != 204) {
          this.allEmployeeList = []
          this.toastr.error(priorityRes?.messageDetail?.message)
        }
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })
  }
  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }

  // Assign Assignee or Assigned Reviewer as the current user according to flag value which is sent on click event.
  // assignMe(val: number) {
  //   if (val == 0) {
  //     this.supportReqForm.patchValue({
  //       assigneeId: this.empId
  //     });
  //   }

  //   else {
  //     this.supportReqForm.patchValue({
  //       assignedReviewerId: this.empId
  //     });
  //   }
  // }


  // Get all the Project UserStories  with project id

  async projectSelected(projectId: any) {
      

    // Reset the parentId and filtered list
    this.parentId = null; // Reset the selected user story
    this.filteredUserStoryList = []; // Clear suggestions
    this.userStoryList = []; // Clear the list of user stories
    this.GetEmployeeByProjectId(projectId);
    var project = { value: projectId, label: this.supportReqForm.value.isClientReported }

    await this.toDosService.GetAllUserStoriesByProjectId(projectId, 'task').subscribe((userStoryResp: any) => {
      if (userStoryResp.isSuccessful == true) {
        this.userStoryList = userStoryResp.data;

      }

      else if (userStoryResp.isSuccessful == false) {
        this.userStoryList = []
        if (userStoryResp?.messageDetail?.message_code != 204) {
          this.toastr.error(userStoryResp?.messageDetail?.message, 'Error');
          // this.supportReqForm.get('parentId').value.reset()
        }
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    });
  }
  onChangeItemType(event) {//if item type bug then isBugSelected flag  true and show repro step field
    const selectedValue = (event.target as HTMLSelectElement).value;

    // Update the flag based on the selected value
    this.isBugSelected = parseInt(selectedValue, 10) === 674180003;

  }
  /*On Change of solz status i.e if solz status is  "Not Started" or "Analyzing" or "On Hold" or "Duplicate" or "QA Failed" or "Canceled" than ETA and Estimated Effort is not Required, else Bussiness Required.*/
  solzStatusSelected(val: number) {
    // If Employee Logged In.
    if (this.empData.contact == null) {
      if (val == 674180000 || val == 674180009 || val == 674180008 || val == 674180012 || val == 674180006 || val == 674180010 || val == 674180004 || val == 674180005) {
        this.fieldRequiredFlag = false;
        this.startDateFlag = false;
      }

      else {
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
      }

      //If Solz status is "Review in progress" or "Review Complete" or "Review Failed" or "Deployed" then change review effort to required or else not required
      if (val == 674180004 || val == 674180005 || val == 674180007) {
        this.reviewerEffortfieldFlag = true;
        this.startDateFlag = true;
      }

      else {
        this.reviewerEffortfieldFlag = false;
      }
    }
  }

  // this API For All Todos By Is Bug Status based on condation 
  async GetAllToDoByIsBugTrueOrFalse(projectId) {
      

    await this.toDosService.GetAllToDoByProjectId(projectId).subscribe((respBug: any) => {


      if (respBug.ResponseCode == 100) {
        // success

        this.isBugTodos = respBug.Data;
      }
      else if (respBug.ResponseCode == 999) {
        this.toastr.error(respBug.Message, 'Error');
        this.spinner.hide();

      }
      else {
        this.isBugTodos = [];

      }
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  // updateParentUserStory(linkedTaskId) {
  //   // Find the linked task with the specified ID
  //   this.linkedTask = this.isBugTodos.filter(task => task.ID === linkedTaskId.ID);
  //   // this.selectedCountry = this.linkedTask[0].userStoryId
  //   this.supportReqForm.patchValue({
  //     // parentId: this.linkedTask[0].userStoryName +' | '+ this.linkedTask[0].parentTitle,
  //     parentId: this.linkedTask[0].userStoryId,
  //     assigneeId: this.linkedTask[0].assigneeId,
  //     assignedReviewerId: this.linkedTask[0].assignedReviewerId
  //   });

  // }

  checkValidityUserstory(event) {
      
    if (event) {
      this.UserStorydataCheck = event
    }
  }




  selectAttachments(event) {

    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };

          // Get the current date and time
          const now = new Date();
          const day = String(now.getDate()).padStart(2, '0');
          const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
          const year = String(now.getFullYear()).slice(2); // Get last two digits of the year
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');

          // Format the date and time as ddmmyy hhmmss
          const timestamp = `${day}${month}${year}${hours}${minutes}${seconds}`;
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          // Append the timestamp to the original file name
          uploadFile["filename"] = `${file.name.split('.').slice(0, -1).join('.')}_${timestamp}.${file.name.split('.').pop()}`;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }
  checkValidity(event) {
    if (event) {
      this.data = event

    }
  }

  // Get filtered user story list based on the searchText input
  filterUserStories(event: any) {
      
    this.msg1 = false
    let filtered: any[] = [];
    let query = event.query.toLowerCase();  // Convert the query to lowercase for case-insensitive matching

    for (let i = 0; i < this.userStoryList?.length; i++) {
      let userStory = this.userStoryList[i];

      // Perform case-insensitive search for any word related to the query
      if (userStory.title.toLowerCase().includes(query)) {
        filtered.push(userStory);
      }
    }

    // Update the filtered list
    this.filteredUserStoryList = filtered;
  }
  // Triggered when a suggestion is selected
  onUserStorySelect(event: any): void {
      
    const selectedStory = event; // Selected userstory object
    this.selectedUserStoryId = selectedStory.id
    this.supportReqForm.value.userStoryId = this.selectedUserStoryId
    // this.supportReqForm.get('parentId')?.setValue(null)
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    // Find the first invalid control
    const invalidControl = Object.keys(form.controls).find(key => {
      return form.get(key)?.invalid;
    });

    if (invalidControl) {
      const invalidElement = document.querySelector(`[formControlName="${invalidControl}"]`);

      if (invalidElement) {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (invalidElement as HTMLElement).focus();
      }
    }
  }
  //********************************************** for autoResize Text Area ***************************************  */

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    // Reset the height to recalculate
    textarea.style.height = 'auto';
    // Set the height to fit the content
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  adjustHeight(event: Event) {
    const element = event.target as HTMLElement;
    element.style.height = 'auto'; // Reset height
    element.style.height = element.scrollHeight + 'px'; // Set new height
  }


  copyPastImageDuplicate() {
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');
    document.getElementById('commentDiv').addEventListener(
      'keydown', (e: KeyboardEvent) => {
        if (!((e.ctrlKey && (e.key == 'v' || e.key == 'V')) || e.code == 'Backspace')) e.preventDefault();
      }
    )

    this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(ce, button);
            }
          }
        }
        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        if (files.length > 0) {
          this.pastAttachments(mockEvent); // Call selectAttachments with the mock event
        }

      }
    });
  }

  copypastImageData() {
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');




    this.renderer?.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const span = this.renderer.createElement('span');
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              const closeButton = this.renderer.createElement('a');
              closeButton.setAttribute('style', 'cursor: pointer; color: red');
              this.renderer.setAttribute(closeButton, 'contenteditable', 'false');
              this.renderer.listen(closeButton, 'click', () => this.deleteImage(fileName));
              const iconClose = this.renderer.createElement('i');
              iconClose.setAttribute('style', 'color: red');
              this.renderer.addClass(iconClose, 'fa');
              this.renderer.addClass(iconClose, 'fa-regular');
              this.renderer.addClass(iconClose, 'fa-window-close');
              this.renderer.addClass(iconClose, 'mx-2');
              i += 1;
              // const textbutton = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(span, button);
              this.renderer.appendChild(closeButton, iconClose);
              // this.renderer.appendChild(closeButton, textbutton);
              this.renderer.appendChild(span, closeButton);
              console.log(span)
              this.renderer.appendChild(ce, span);
            }
          }
        }

        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        // if (files.length > 0) {
        //   this.selectAttachments(mockEvent); // Call selectAttachments with the mock event
        // }
      }
    });
  }

  pastAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadImageFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  deleteImage(fileName) {
    console.log(fileName)
    const element = document.querySelector('#commentDiv')
    const node = Array.from(element.children).find(el => el.textContent.includes(fileName));
    node.remove();
  }



  downloadImg(fileName: string, template) {
      
    this.viewImageSrc = this.tempImgArray.find(img => img.fileName == fileName);
  }

  async downloadPreviewImage() {
      
    let FileSaver = await import("file-saver");
    FileSaver.saveAs(this.viewImageSrc.base64, this.viewImageSrc.fileName);
  }

  viewImage(file: any) {
    console.log("File Data:", file);

    if (file && this.isImageFile(file.fileName)) {
      let imageSrc = '';

      // Check if 'bytes' contains Base64 data
      if (file.bytes && !file.bytes.startsWith('data:image')) {
        imageSrc = `data:image/png;base64,${file.bytes}`;
      }
      // If Base64 data is missing, use fileUrl (server path)
      else if (file.fileUrl) {
        imageSrc = file.fileUrl;
      }
      // If neither is available, show an error
      else {
        alert("No valid image data found!");
        return;
      }

      // Assign to viewImageSrc
      this.viewImageSrc = {
        base64: imageSrc,
        fileName: file.fileName
      };

      console.log("Updated viewImageSrc:", this.viewImageSrc);
    } else {
      alert("This file is not an image.");
    }
  }


  // Function to check if the file is an image
  isImageFile(fileName: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);
  }

  onSubmit() {
      

    this.spinner.show();
    this.supportReqFormSubmitted = true;
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      this.scrollToFirstInvalidControl(this.supportReqForm)
      return;
    }


    // if (this.supportReqForm.value.selectedCountryAdvanced === this.data) {
    //   this.msg = true;
    //   this.spinner.hide();
    //   return;
    // }

    if (this.UserStorydataCheck != null) {
      if (this.supportReqForm.value.parentId === this.UserStorydataCheck) {
        this.msg1 = true;
        this.spinner.hide();
        return;
      }
    }

    // var today = new Date();
    // this.supportReqForm.value.createdOn = moment(today).format('YYYY-MM-DD');


    // const commentDiv = document.getElementById('commentDiv');
    // if (commentDiv) {
    //   const content = commentDiv.innerHTML;
    //   console.log('Submitted Content:', content);
    //   this.supportReqForm.value.comment = content;
    // }


    if (this.contactLoggedIn) {
      this.supportReqForm.value.isClientReported = "true";
    }

    var st = this.supportReqForm.value.solzStatus;
    this.supportReqForm.value.solzStatus = new Object();
    this.supportReqForm.value.solzStatus.Value = st;



    var up = this.supportReqForm.value.userPriority;
    this.supportReqForm.value.userPriority = new Object();
    this.supportReqForm.value.userPriority.Value = up;

    // var ti = this.supportReqForm.value.typeOfItem;
    // this.supportReqForm.value.typeOfItem = new Object();
    // this.supportReqForm.value.typeOfItem.Value = ti;

    //TypeofItems Field Hide In html but send value on toto
    // var toi = this.supportReqForm.value.typeOfItems;
    // this.supportReqForm.value.typeOfItems = new Object();
    // this.supportReqForm.value.typeOfItems.value = toi;


    //Sprint Id Send 
    var spi = this.supportReqForm.value.sprintId;
    this.supportReqForm.value.sprintId = new Object();
    this.supportReqForm.value.sprintId = spi;


    //Linked Task
    // var lts = this.supportReqForm.value.selectedCountryAdvanced.ItemId;
    // this.supportReqForm.value.linkedTask = new Object();
    // this.supportReqForm.value.linkedTask = lts;

    var pri = this.selectedUserStoryId;
    this.supportReqForm.value.userStoryId = new Object();
    this.supportReqForm.value.userStoryId = pri;

    // var pri = this.supportReqForm.value.parentId.ItemId;
    // this.supportReqForm.value.parentId = new Object();
    // this.supportReqForm.value.parentId = pri;

    // var pri = this.supportReqForm.value.selectedCountryAdvanced.ItemId;
    // this.supportReqForm.value.parentId = new Object();
    // this.supportReqForm.value.parentId = pri;

    var isr = this.supportReqForm.value.isClientReported;
    this.supportReqForm.value.isClientReported = JSON.parse(isr);

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.itemType;
    this.supportReqForm.value.itemType = JSON.parse(ib);

    // this.supportReqForm.value.workArea = new Object();
    // this.supportReqForm.value.workArea.Value = 100000000; // setting work area to Hourly Task permanently.

    // if (this.supportReqForm.value.testingEnvironment != null) {
    //   var te = this.supportReqForm.value.testingEnvironment;
    //   this.supportReqForm.value.testingEnvironment = new Object();
    //   this.supportReqForm.value.testingEnvironment.Value = te;
    // }

    // else {
    //   this.supportReqForm.value.testingEnvironment = new Object();
    //   this.supportReqForm.value.testingEnvironment.Value = 674180004
    // }

    if (this.supportReqForm.value.plannedStartDate != null) {
      var date = new Date(this.supportReqForm.value.plannedStartDate);
      var sd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.plannedStartDate = sd;
    }

    if (this.supportReqForm.value.expectedResDate != null) {
      var erd = this.supportReqForm.value.expectedResDate;
      this.supportReqForm.value.expectedResDate = moment(erd).format('YYYY-MM-DD');
    }
    if (this.supportReqForm.value.plannedEndDate != null) {
      var ed = this.supportReqForm.value.plannedEndDate;
      this.supportReqForm.value.plannedEndDate = moment(ed).format('YYYY-MM-DD');
    }

    if (!this.contactLoggedIn) {
      this.supportReqForm.value.createdBy = this.empId;
    }
    //***********************If Item type is To-Do then call To-Do API  ***************************** 
    if (this.supportReqForm.value.itemType == 674180002) {
      this.toDosService.postNewToDo(this.supportReqForm.value).subscribe((newRes: any) => {
        if (newRes.isSuccessful == true) {// success
          if (this.upLoadFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadFileList.length; i++) {
              this.upLoadFileList[i].ID = newRes.data;
            }

            var attachFiles = { itemDetails: this.upLoadFileList }
            this.uploadFiles(attachFiles);
          }
          else if (this.upLoadImageFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadImageFileList.length; i++) {
              this.upLoadImageFileList[i].ID = newRes.data;
            }

            var attachFiles = { itemDetails: this.upLoadImageFileList }
            this.uploadFiles(attachFiles);
          }

          else { // else just show message.
            this.spinner.hide();
            this.toastr.success(newRes?.messageDetail?.message, 'Success', {
              positionClass: 'toast-top-full-width',
              timeOut: 5000
            });
            this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + newRes.data]);
          }
        }

        else if (newRes.isSuccessful == false) {
          this.spinner.hide();
          this.toastr.error(newRes?.messageDetail?.message);
        }

      }, error => {
        this.spinner.hide();
        this.toastr.error(error.error?.messageDetail?.message);
      });

    }
    ////***********************If Item type is bug then call bug API  ***************************** 
    else if (this.supportReqForm.value.itemType == 674180003) {
      this.toDosService.createNewBug(this.supportReqForm.value).subscribe((newRes: any) => {
        if (newRes.isSuccessful == true) {// success
          if (this.upLoadFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadFileList.length; i++) {
              this.upLoadFileList[i].ID = newRes.data;
            }

            var attachFiles = { itemDetails: this.upLoadFileList }
            this.uploadFiles(attachFiles);
          }
          else if (this.upLoadImageFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadImageFileList.length; i++) {
              this.upLoadImageFileList[i].ID = newRes.data;
            }

            var attachFiles = { itemDetails: this.upLoadImageFileList }
            this.uploadFiles(attachFiles);
          }

          else { // else just show message.
            this.spinner.hide();
            this.toastr.success(newRes?.messageDetail?.message, 'Success', {
              positionClass: 'toast-top-full-width',
              timeOut: 5000
            });
            this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + newRes.data]);
          }
        }

        else if (newRes.isSuccessful == false) {
          this.spinner.hide();
          this.toastr.error(newRes?.messageDetail?.message);
        }

      }, error => {
        this.spinner.hide();
        this.toastr.error(error.error?.messageDetail?.message);
      });
    }
  }

  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
      
    await this.SharepointService.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + attachFiles?.itemDetails[0]?.ID]);

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
  filterCountry(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.userStoryList?.length; i++) {
      let country = this.userStoryList[i];
      // country.forEach(y => {
      if (country.itemNo.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
      // });

    }
    this.filteredCountries = filtered;
    // this.userStoryList = filtered;
  }

  filterCountryLinkedTask(event: any) {
    let filtered: any[] = [];
    let query = event.query.toLowerCase(); // Convert the query to lowercase for case-insensitive matching

    for (let i = 0; i < this.isBugTodos?.length; i++) {
      let country = this.isBugTodos[i];

      // Perform case-insensitive search for any word related to the query
      if (country.itemNo.toLowerCase().includes(query) || country.title.toLowerCase().includes(query)) {
        filtered.push(country);
      }
    }

    this.filteredCountries = filtered;
  }



}
