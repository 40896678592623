import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  };
}
@Component({
  selector: 'app-add-employee-goals',
  templateUrl: './add-employee-goals.component.html',
  styleUrls: ['./add-employee-goals.component.scss']
})

export class AddEmployeeGoalsComponent implements OnInit {
  empGoalsForm: FormGroup;
  fieldRequiredFlag: boolean = false;
  empId: string;
  statusresionval: any;
  status: any;
  Filterstatus: [];
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  goalFormSubmitted: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private PerformanceReviewService: PerformanceReviewService,
    private MasterDropdownServiceService: MasterDropdownServiceService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.empId = localStorage.getItem("empId")
  }



  ngOnInit(): void {
    this.spinner.show();
    this.empGoalsForm = this.fb.group({
      EmployeeName: [''],
      PerformanceYear: [''],
      Goals: ['', [Validators.required, noWhitespaceValidator()]],
      achievedDate: ['', [Validators.required, noWhitespaceValidator()]],
      ExpOutcome: ['', [Validators.required, noWhitespaceValidator()]],
      percentageAchieved: [0, [Validators.required, noWhitespaceValidator, Validators.min(0),]],
      statusReason: ['', [Validators.required, noWhitespaceValidator()]],
      employeeComments: [''],
      managerComments: ['']
    });

    this.empGoalsForm.patchValue({
      statusReason: '674180002'
    })
    this.dropdownOfStatus()
  }

  //Employee Form Control
  get egl() {
    return this.empGoalsForm.controls;
  }

  // checkValue(event) {
  //   debugger
  //   if (event.target.value < 0) {
  //     event.target.value = 0;
  //   }
  //   if (event.target.value > 100) {
  //     event.target.value = 100;
  //   }

  // }
  checkValue(event: Event): void {
    const inputValue = +(event.target as HTMLInputElement).value;
    if (inputValue < 0) {
      this.empGoalsForm.controls['percentageAchieved'].setValue(0);
    }
    if (inputValue > 100) {
      this.empGoalsForm.controls['percentageAchieved'].setValue(100);
    }
  }
  dropdownOfStatus() {

    this.MasterDropdownServiceService.getMasterDropdown('GoalsStatus').subscribe((responce: any) => {
      if (responce.isSuccessful == true) {

        this.status = responce.data
        this.Filterstatus = this.status.filter(element => {
          return element.label === 'In Progress' || element.label === 'New' ||
            element.value === 1 || element.value === 674180002;
        });
        this.spinner.hide();
      }
      else if (responce.isSuccessful == false) {
        this.spinner.hide();
        if (responce?.messageDetail?.message_code != 204) {
          this.toastr.error(responce?.messageDetail?.message, 'Error')
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    })
  }

  statusReason(status: any) {
    this.statusresionval = status
  }
  submit() {
    this.goalFormSubmitted = true
    this.spinner.show();
    // this.router.navigate(['/ESSPortal/employee-goals-list']);

    if (this.empGoalsForm.invalid) {
      // Mark all form controls as touched to trigger validation messages
      Object.values(this.empGoalsForm.controls).forEach(control => {
        control.markAsTouched();
        this.fieldRequiredFlag = true;
      });
      this.spinner.hide();
      return;

    }
    let obj = {
      employeeId: this.empId,
      expOutcome: this.empGoalsForm.value.ExpOutcome,
      achievebyDate: this.empGoalsForm.value.achievedDate,
      comment: this.empGoalsForm.value.employeeComments,
      managerComment: this.empGoalsForm.value.managerComments,
      goal: this.empGoalsForm.value.Goals,
      // PerformanceYear: this.empGoalsForm.value.PerformanceYear,    
      percentAchieved: this.empGoalsForm.value.percentageAchieved,
      statusReason: {

        value: this.empGoalsForm.value.statusReason
      }

    }

    this.PerformanceReviewService.postaddEmployeeGoals(obj).subscribe((responce: any) => {
      if (responce.isSuccessful == true) {

        this.toastr.success(responce?.messageDetail?.message, 'Success', {//Success
          positionClass: 'toast-top-full-width',
        });

        // setTimeout(function () {
        //   location.reload();
        // }, 1000);
        this.spinner.hide();
        this.router.navigate(['/ESSPortal/employee-goals-list']);
      }

      else if (responce.isSuccessful == false) {
        this.toastr.error(responce?.messageDetail?.message, 'Error');
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    })

  }
}


