import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { DashboardService } from '../../../_services/dashboard.service';

@Component({ 
  selector: 'app-dashboard-to-do-tasks',
  templateUrl: './dashboard-to-do-tasks.component.html',
  styleUrls: ['./dashboard-to-do-tasks.component.css']
})
export class DashboardToDoTasksComponent implements OnInit {
  @Input() employeeId: string;
  toDosObj: ToDosDataModal;
  toDosList: Array<ToDosDataModal> = [];
  supportReqObj: SupportRequestDataModel;
  supportReqList: Array<SupportRequestDataModel> = [];
  respMessage: string;
  deleteRecordData: any;

  constructor(public toDosService: ToDosService,
    public dashboardService: DashboardService,
    public supportReqService: SupportRequestService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.employeeId=   localStorage.getItem('empId')
    this.spinner.show();
    this.toDosObj = new ToDosDataModal();
    this.supportReqObj = new SupportRequestDataModel();
    this.getAllTasks();
  }

  // function for getting all tasks.
  async getAllTasks() {debugger
    this.toDosObj.ID = this.employeeId;
    this.toDosObj.pageNumber = 1;
    this.toDosObj.number = 10;
    this.toDosService.getAllToDoTasks(this.toDosObj).subscribe((toDo: any) => {
      if (toDo.ResponseCode == 100) { // success
        this.toDosList = toDo.Data;
        this.get15Tasks();
      }
      else if (toDo.ResponseCode == 999) {
        this.spinner.hide();
        // this.toastr.error(toDo.Message, 'Error');
      }

    }, error => {
      this.spinner.hide();
      // this.toastr.error(error.message);
    });
  }

  // function for getting top 15 tasks.
  async get15Tasks() {
    await this.dashboardService.getTop15TimeSheetRecords().subscribe((workLogs: any) => {
      if (workLogs.isSuccessful == true) { // success
        this.supportReqList = workLogs.data;
      }
      else if (workLogs.isSuccessful == false) {
        this.spinner.hide();
        if(workLogs?.messageDetail?.message_code == 204){

          // this.toastr.error(workLogs.Message, 'Error');
        }
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
  deleteRecord(record:any){
this.deleteRecordData = record
  }

  // function for deleting task.
  async onDelete() {
    this.spinner.show();
    this.supportReqObj = this.deleteRecordData;
    await this.supportReqService.postdeleteTimeSheet(this.supportReqObj).subscribe((response: any) => {
      if (response.ResponseCode == 100) { // success
        this.respMessage = response.Message;
      }
      else if (response.ResponseCode == 999) {
        // this.toastr.error(response.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error.message);
    });
  }

  reloadPage() {
    location.reload();
  }

  //send data to edit to-do.
  updateToDo(toDoData: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/ESSPortal/to_dos/update_to_do/${toDoData.ID}`])
    );
    window.open(`${window.location.origin}/#${url}`, '_blank');
    // this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + toDoData.ID ]);
  }

  //send data to edit work-log.
  updateWorklog(workLogData: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/ESSPortal/to_dos/my_worklog/EditWork/${workLogData.id}`])
    );
    window.open(`${window.location.origin}/#${url}`, '_blank');
    // this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + workLogData.id ]);
  }

  addTOdoWorklog(toDoData:any){
    if(toDoData?.solzStatus?.Value  != 674180000    ){
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/ESSPortal/to_dos/my_worklog/AddWork/${toDoData.ID}`])
      );
      window.open(`${window.location.origin}/#${url}`, '_blank');
      // this.router.navigate(['/ESSPortal/to_dos/my_worklog/AddWork/' + toDoData.ID ]);
    }
    else {
      this.toastr.error('Please update your work status ', 'Error')
    }
  }
}
