import { Component, Input, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeRecordService } from '../../../_services/employee-record.service';
import { AuthService } from '../../../_services/auth.service';
import { EmployeeDataModel } from '../../../_models/employee-data-model';

@Component({
  selector: 'app-inventory-allocation',
  templateUrl: './inventory-allocation.component.html',
  styleUrls: ['./inventory-allocation.component.scss']
})
export class InventoryAllocationComponent implements OnInit {
  @Input() employeeId : string
  userData: any;
  inventoryData: any;

  constructor(
    public storage: StorageMap,
    public employeeRecordService: EmployeeRecordService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public authService: AuthService,

  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.getdata()
  }

  async getdata(){
    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {
      
      this.userData = myInvoice.userProfile;
      this.employeeId = this.userData.userId
     
      this.getInventoryAllocation(this.employeeId)
    })
  }
  async getInventoryAllocation(employeeId) {
    await this.employeeRecordService.getEmployeeInventoryAllocation().subscribe((inventoryResp: any) => {
      if (inventoryResp.isSuccessful == true) { //success
        this.spinner.hide();
        this.inventoryData = inventoryResp.data;
      }
      else if (inventoryResp.isSuccessful == false) {
        this.spinner.hide();
        if(inventoryResp?.messageDetail?.message_code != 204){

          this.toastr.error(inventoryResp?.messageDetail?.message, 'Error');
        }
      }
     
   
      

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

}
