import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CalendarDataModel } from '../../../_models/calendar-data-model';
import { DashboardService } from '../../../_services/dashboard.service';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.css']
})
export class DashboardCalendarComponent implements OnInit {
  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  @Input() employeeId: string;
  calendarOptions: CalendarOptions;
  @Output() calendarDataEmit: EventEmitter<Array<CalendarDataModel>> = new EventEmitter();
  calendarList: Array<CalendarDataModel> = [];
  calendarCode: any;

  constructor(public dashboardService: DashboardService,
    public storage: StorageMap,
    private router: Router,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    const name = Calendar.name;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getCalendarData(); // getting data for calendar.
  }

  async getCalendarData() {
    await this.dashboardService.getToDoCalendar().subscribe((toDoCalendar: any) => {
      this.calendarCode = toDoCalendar.isSuccessful;
      if (this.calendarCode == true) { //success
        this.calendarList = toDoCalendar.data;

        // sending data to dashboard component for generating alerts.
        this.calendarDataEmit.emit(this.calendarList);
        this.createCalendar();
      }
      else if (toDoCalendar.isSuccessful == false) {
        this.spinner.hide();
        if(toDoCalendar?.messageDetail.message_code != 204){

          // this.toastr.error(toDoCalendar?.messageDetail?.message, 'Error');
        }
      }
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error.error.messageDetail.message);
    });
  }

  async createCalendar() {
    this.calendarOptions = {
      height: 'auto',
      fixedWeekCount: false,
      initialDate: new Date(),
      displayEventTime: true,
      editable: true,
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'today'
      },
      lazyFetching: false,
      nowIndicator: true,
      events: this.calendarList,
      eventClick: this.updateToDos.bind(this),
      selectable: true,
      select: this.makeNewToDo.bind(this),
      plugins: this.calendarPlugins,
      initialView: 'dayGridMonth',
    };
    this.spinner.hide();
  }

  async updateToDos(callEvent) {
    // var id = callEvent.event?._def.extendedProps.id;
    var id = callEvent.event?._def.publicId;

    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + id]);
  }

  async makeNewToDo(newEvent) {
    this.router.navigate(['/ESSPortal/to_dos/new_to_do']);
  }
} 
