import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreakLogDataModel } from '../../../_models/break-log-data-model';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { UserDataModel } from '../../../_models/user-data-model';
import { LeaveService } from '../../../_services/leave.service';

@Component({
  selector: 'app-my-breaks',
  templateUrl: './my-breaks.component.html',
  styleUrls: ['./my-breaks.component.css']
})
export class MyBreaksComponent implements OnInit {
  breakList: Array<BreakLogDataModel> = [];
  breakObj: BreakLogDataModel;
  empObj: EmployeeDataModel;
  empId: string;
  empData: EmployeeDataModel;

  constructor(public leaveService: LeaveService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  async getData() {
    this.breakObj = new BreakLogDataModel();
    this.empObj = new EmployeeDataModel();
    this.empData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((empBreak: EmployeeDataModel) => {
      // getting employee data from storage library.
      this.empData = empBreak.userProfile;
      this.breakObj.ID = this.empData.userId;
      this.fetchBreaks(); // getting All Applied Breaks.
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async fetchBreaks() {
   
    await this.leaveService.getBreakList().subscribe((res: any) => {
      if (res.isSuccessful == true) { // success
        this.breakList = res.data;
        this.breakObj.totalItemNo = this.breakList.length;
        
        this.breakList.sort((a, b) => new Date(b.outTime).getTime() - new Date(a.outTime).getTime());
      }
      else if (res.isSuccessful == false) {
        if(res?.messageDetail?.message_code != 204){

          this.toastr.error(res?.messageDetail?.message, 'Error');
        }
      }
      this.spinner.hide();
    }, error => {
     // this.spinner.hide();
      this.toastr.error(error?.error.messageDetail?.message);
    });
  }
}
