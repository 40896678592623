  import { OptionSetDataModel } from './option-set-data-model';
import { UserDataModel } from './user-data-model';

export class EmployeeDataModel {
  employee: UserDataModel;  
  contact: UserDataModel;
  fullName: string;
  userName: string;
  designation: string;
  employeeImg: any;   //note- do check it while deploying.
  joiningDate: Date;
  birthdayDate: Date;
  isManager: boolean;
  gender: OptionSetDataModel;
  ID: string;// created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  Name: string; // created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  reportingManager : any // created by Pradeep for showing reporting manager at base entity
  reportingManagerID : any // created by Pradeep for showing reporting manager at base entity
  userProfile: any
  userId: any
  customerId:any
  email: any
}
