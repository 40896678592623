import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoLeadingSpace]' // Use this selector in your templates
})
export class NoLeadingSpaceDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {debugger
    const inputElement = event.target as HTMLInputElement;
    const isFirstCharacter = inputElement.selectionStart === 0;

    // Prevent leading space
    if (isFirstCharacter && event.key === ' ') {
      event.preventDefault();
    }
  }
}
