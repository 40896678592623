import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { NewRequestComponent } from './leave-requests/new-request/new-request.component';
import { MyRequestsComponent } from './leave-requests/my-requests/my-requests.component';
import { SortDirective } from '../_directives/sort.directive';
import { Sort } from '../_services/sort.service';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardPieChartComponent } from './dashboard/dashboard-pie-chart/dashboard-pie-chart.component';
import { DashboardLineChartComponent } from './leave-requests/my-balance/leave-balance-details/dashboard-line-chart/dashboard-line-chart.component';
import { DashboardSoluzioneHolidaysComponent } from './dashboard/dashboard-soluzione-holidays/dashboard-soluzione-holidays.component';
import { MyBalanceComponent } from './leave-requests/my-balance/my-balance.component';
import { LeaveBalanceDetailsComponent } from './leave-requests/my-balance/leave-balance-details/leave-balance-details.component';
import { AttendanceComponent } from './attendance-registers/attendance/attendance.component';
import { MyBreaksComponent } from './leave-requests/my-breaks/my-breaks.component';
import { ApplyBreaksComponent } from './leave-requests/my-breaks/apply-breaks/apply-breaks.component';
import { ChartModule } from 'primeng/chart';
import { AlertModule } from 'ngx-bootstrap/alert';
import { DashboardToDoComponent } from './dashboard/dashboard-to-do/dashboard-to-do.component';
import { DashboardToDoTasksComponent } from './dashboard/dashboard-to-do-tasks/dashboard-to-do-tasks.component';
import { DashboardUpcomingBirthdaysComponent } from './dashboard/dashboard-upcoming-birthdays/dashboard-upcoming-birthdays.component';
import { DashboardCalendarComponent } from './dashboard/dashboard-calendar/dashboard-calendar.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import{AuthenticationGuard} from '../_services/authentication.guard';
import { MyToDosComponent } from './to-dos/my-to-dos/my-to-dos.component';
import { MyWorklogComponent } from './to-dos/my-worklog/my-worklog.component';
import { NewToDosComponent } from './to-dos/new-to-dos/new-to-dos.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChangePasswordComponent } from './authorization/change-password/change-password.component';
import { UpdateToDosComponent } from './to-dos/update-to-dos/update-to-dos.component';
import { Tooltip } from 'chart.js';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { DailyArrivalUpdateComponent } from './Daily-Arrival-Update/daily-arrival-update/daily-arrival-update.component';
import { DailyLoginUpdateComponent } from './Daily-Login-Update/daily-login-update/daily-login-update.component';
import { SoluzioneDirectoryComponent } from './soluzione-directory/soluzione-directory.component';
import { DashboardEmployeeOfMonthComponent } from './dashboard/dashboard-employee-of-month/dashboard-employee-of-month.component';
import { DashboardActivityDaysComponent } from './dashboard/dashboard-activity-days/dashboard-activity-days.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { CRMProjectListComponent } from './crm-project-list/crm-project-list.component';
import { PaymentsComponent } from '../payments/payments.component';
import { FilterPipe } from '../Pipe/filter.pipe';
import { InvoicePaymentComponent } from './invoice-payment/invoice-payment.component';
import { CheckboxModule } from 'primeng/checkbox';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap'
import { CarouselModule } from 'primeng/carousel';
import { ErrorComponent } from './error/error.component'
import { GalleriaModule } from 'primeng/galleria';
import { ProjectAllocationComponent } from './employee-skills/project-allocation/project-allocation.component';
import { SkillsAllocationComponent } from './employee-skills/skills-allocation/skills-allocation.component';
import { InventoryAllocationComponent } from './employee-skills/inventory-allocation/inventory-allocation.component';
import { MyToDoosComponent } from './customer/my-to-doos/my-to-doos.component';
import { NewToDoosComponent } from './customer/new-to-doos/new-to-doos.component';
import { UpdateToDoosComponent } from './customer/update-to-doos/update-to-doos.component';
import { TodosBugsComponent } from './to-dos/todos-bugs/todos-bugs.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DemoSolzitComponent } from '../demo-solzit/demo-solzit.component';
import { HiringPositionComponent } from '../hiring-position/hiring-position.component';
import { MyHiringApplicationComponent } from '../hiring-position/my-hiring-application/my-hiring-application.component';
import { OpenPositionComponent } from '../hiring-position/open-position/open-position.component';
import { ToDosBugsComponent } from './customer/to-dos-bugs/to-dos-bugs.component';
import { EmployeeGoalsListComponent } from '../New Portal UI/Performance-Reviews/employee-goals-list/employee-goals-list.component';
import { AddEmployeeGoalsComponent } from '../New Portal UI/Performance-Reviews/add-employee-goals/add-employee-goals.component';
import { UpdateEmployeeGoalsComponent } from '../New Portal UI/Performance-Reviews/update-employee-goals/update-employee-goals.component';
import { PerformanceReviewsFormComponent } from './Performance-Reviews/performance-reviews-form/performance-reviews-form.component';
import { StepsModule } from 'primeng/steps';
import { TabViewModule } from 'primeng/tabview';
import { PerformanceReviewsListComponent } from './Performance-Reviews/performance-reviews-list/performance-reviews-list.component';
import { UpdatePerformanceReviewFormComponent } from './Performance-Reviews/update-performance-review-form/update-performance-review-form.component';
import { CalendarModule } from 'primeng/calendar';
import { SalarySlipComponent } from './salary-slip/salary-slip.component';
import { RaiseBugAllProjectComponent } from './raise-bug-all-project/raise-bug-all-project.component';
import { MyObservationComponent } from './raise-bug-all-project/my-observation/my-observation.component';
import { UpdateRaiseBugAllProjectComponent } from './raise-bug-all-project/update-raise-bug-all-project/update-raise-bug-all-project.component';
import { FeedbackComponent } from './feedback/feedback/feedback.component';
import { MyFeedbackComponent } from './feedback/feedback/my-feedback/my-feedback.component';
import { DashboardBannerComponent } from './dashboard/dashboard-banner/dashboard-banner.component';
import { UpdateFeedBackComponent } from './feedback/update-feed-back/update-feed-back.component';
import { NoLeadingSpaceDirective } from '../_directives/directives/no-leading-space.directive';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

const viewRuotes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    // canActivate: [AuthenticationGuard]
  }, {
    path: 'Soluzione_Directory',
    component: SoluzioneDirectoryComponent,
    data: {
      breadcrumb: [
        {
          label: 'Soluzione Directory',
          url: ''
        }
      ]
    },
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'leave_requests',
    children: [
      {
        path: 'my_requests',
        component: MyRequestsComponent,
        data: {
          breadcrumb: [
            {
              label: 'Leaves & Breaks',
              url: ''
            },
            {
              label: 'Leave Requests',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      },

      {
        path: 'my_leave_balance',
        children: [
          {
            path: '',
            component: MyBalanceComponent,
            data: {

              breadcrumb: [
                {
                  label: 'Leaves & Breaks / Leave Balance',
                  url: ''
                },
              ]
            },
            // canActivate: [AuthenticationGuard]
          },

          {
            path: 'details/:leaveId',
            component: LeaveBalanceDetailsComponent,
            data: {
              breadcrumb: [
                {
                  label: 'Leaves & Breaks',
                  url: ''
                },
                {
                  label: 'Leave Balance',
                  url: 'ESSPortal/leave_requests/my_leave_balance'
                },
                {
                  label: 'Leave Balance Detail',
                  url: ''
                },
              ]
            },
            // canActivate: [AuthenticationGuard]
          }
        ]
      },

      {
        path: 'breaklogs',
        component: MyBreaksComponent, data: {
          breadcrumb: [
            {
              label: 'Leaves & Breaks',
              url: ''
            },
            {
              label: 'Breaks',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      }
    ]
  },
  {

    path: 'daily_hours/daily_arrival_update/:month/:year',
    component: DailyArrivalUpdateComponent,
    data: {
      breadcrumb: [
        {
          label: 'Leaves & Breaks',
          url: ''
        },
        {
          label: 'Late Arrival Time',
          url: ''
        },
      ]
    },
    // canActivate: [AuthenticationGuard]
  },
  // {
  //   path: 'daily_hours/daily_login_update/:month/:year',
  //   component: DailyLoginUpdateComponent,
  //   data:{
  //     breadcrumb: [
  //       {
  //         label:'Daily Hours',
  //         url:''
  //       }, 
  //       {
  //         label:'Daily Login Update',
  //         url: ''
  //       },
  //     ]
  //   },
  //   canActivate: [AuthenticationGuard]
  // },

  {
    path: 'attendance_registers',
    children: [
      {
        path: 'attendance/:month/:year',
        component: AttendanceComponent,
        data: {
          breadcrumb: [
            {
              label: 'Leaves & Breaks',
              url: ''
            },
            {
              label: 'Leave Balance',
              url: 'ESSPortal/leave_requests/my_leave_balance'
            },
            {
              label: 'Attendance Record',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      }
    ]
  },

  {
    path: 'to_dos',
    children: [
      {
        path: 'my_to_dos',
        component: MyToDosComponent,
        data: {
          breadcrumb: [
            {
              label: 'My Work',
              url: ''
            },
            {
              label: 'My To Dos',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      },

      {
        path: 'my_worklog/:type/:id',
        component: MyWorklogComponent,
        // canActivate: [AuthenticationGuard]
      },

      {
        path: 'new_to_do',
        component: NewToDosComponent,
        data: {
          breadcrumb: [
            {
              label: 'My Work',
              url: ''
            },
            {
              label: 'Add To Do',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      },

      {
        path: 'update_to_do/:id',
        component: UpdateToDosComponent,
        // canActivate: [AuthenticationGuard]
      },
      {
        path: 'to-dos-bugs/:id/:projectName/:userPriority/:linkedTaskName/:isBug/:isEdit/:assignee/:reviewer/:itemnoSr/:testingEnv',
        component: TodosBugsComponent,
        // canActivate: [AuthenticationGuard]

      }
    ]
  },
  {
    path: 'Account/change_password',
    component: ChangePasswordComponent,
    data: {
      breadcrumb: [
        {
          label: 'Account',
          url: ''
        },
        {
          label: 'Change Password',
          url: ''
        },

      ]
    },
  },

  {
    path: 'project-list',
    component: ProjectListComponent,
    // canActivate: [AuthenticationGuard]
    data: {

      breadcrumb: [
        {
          label: 'Project List ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'crm-project-list/:id',
    component: CRMProjectListComponent,
    data: {

      breadcrumb: [
        {
          label: 'CRM Project List ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'Payments',
    component: PaymentsComponent,
    data: {
      breadcrumb: [
        {
          label: 'Payments ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'invoice-payment',
    component: InvoicePaymentComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Invoice Payment ',
          //  label:'',
          url: ''
        },
      ]
    },
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'project-allocation',
    component: ProjectAllocationComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Project Allocation ',
          //  label:'',
          url: ''
        },
      ]
    },
  },
  {
    path: 'inventory-allocation',
    component: InventoryAllocationComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'My Assets  ',
          //  label:'',
          url: ''
        },
      ]
    },
  },
  {
    path: 'skills-allocation',
    component: SkillsAllocationComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Skills Allocation ',
          //  label:'',
          url: ''
        },
      ]
    },
  },
  {
    path: 'hiring-position',
    component: HiringPositionComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Open Positions ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'my-hiring-application',
    component: MyHiringApplicationComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'My References ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'open-position/:id',
    component: OpenPositionComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Hiring Applications ',
          //  label:'',
          url: ''
        },
      ]
    },
  },

  {
    path: 'raise-bug-all-project',
    component: RaiseBugAllProjectComponent,
    data: {
      breadcrumb: [
        {
          label: 'My Observation ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'my-observation',
    component: MyObservationComponent,
    data: {
      breadcrumb: [
        {
          label: 'My Observation ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'update-raise-bug-all-project/:id',
    component: UpdateRaiseBugAllProjectComponent,
    data: {
      breadcrumb: [
        {
          label: 'Update Observation ',
          url: ''
        },
      ]
    },
  },


  //this Todoos only show for Customer  

  {
    path: 'customer',
    children: [
      {
        path: 'my_to_doos',
        component: MyToDoosComponent,
        data: {
          breadcrumb: [
            {
              label: 'My Work',
              url: ''
            },
            {
              label: 'My Task',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      },

      {
        path: 'new_to_doos',
        component: NewToDoosComponent,
        data: {
          breadcrumb: [
            {
              label: 'My Work',
              url: ''
            },
            {
              label: 'Add Task',
              url: ''
            },
          ]
        },
        // canActivate: [AuthenticationGuard]
      },

      {
        path: 'update_to_doos/:id',
        component: UpdateToDoosComponent,
        // canActivate: [AuthenticationGuard]
      },
      {
        path: 'to-dos-bugs/:id/:projectName/:userPriority/:linkedTaskName/:isBug/:isEdit/:assignee/:reviewer/:itemnoSr/:testingEnv',
        component: ToDosBugsComponent,
        // canActivate: [AuthenticationGuard]
      }
    ],
  },

  {
    path: 'demo-solzit',
    component: DemoSolzitComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Demo Solzit ',
          //  label:'',
          url: ''
        },
      ]
    },
  },

  {
    path: 'employee-goals-list',
    component: EmployeeGoalsListComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Employee Goals ',
          //  label:'',
          url: ''
        },
      ]
    },
  },
  {
    path: 'add-employee-goals',
    component: AddEmployeeGoalsComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Add Employee Goals  ',
          url: ''
        },
      ]
    },
  },
  {
    path: 'update-employee-goals/:id',
    component: UpdateEmployeeGoalsComponent,
    data: {
      breadcrumb: [
        {
          label: 'Update Employee Goals ',
          url: ''
        },
      ]
    },
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'performance-reviews-form/:id',
    component: PerformanceReviewsFormComponent,
    data: {
      breadcrumb: [
        {
          label: 'Performance Review ',
          url: ''
        },
      ]
    },
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'performance-reviews-list',
    component: PerformanceReviewsListComponent,
    data: {
      breadcrumb: [
        {
          label: ' Performance Review  ',
          url: ''
        },
      ]
    },
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'update-performance-review-form/:id',
    component: UpdatePerformanceReviewFormComponent,
    data: {
      breadcrumb: [
        {
          label: 'Update Performance Reviews',
          url: ''
        },
      ]
    },
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'salary-slip/:id',
    component: SalarySlipComponent,
    // canActivate: [AuthenticationGuard],
    data: {
      breadcrumb: [
        {
          label: 'Salary Slip ',
          //  label:'',
          url: ''
        },
      ]
    },
  },

  {
    path: 'my-feedback',
    component: MyFeedbackComponent,
    data: {
      breadcrumb: [
        {
          label: 'Soluzione values your feedback. Please feel free to share your thoughts.',
          url: ''
        }
      ]
    },
    canActivate: [AuthenticationGuard] 
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    data: {
      breadcrumb: [
        {
          label: 'Add Feedback ',
          url: ''
        }
      ]
    },
    canActivate: [AuthenticationGuard] 
  },
  {
    path: 'update-feedback/:id',
    component: UpdateFeedBackComponent,
    data: {
      breadcrumb: [
        {
          label: 'View Feedback ',
          url: ''
        }
      ]
    },
    canActivate: [AuthenticationGuard] 
  },

];

@NgModule({
  declarations: [
    SortDirective,
    DashboardComponent,
    DashboardPieChartComponent,
    DashboardLineChartComponent,
    DashboardSoluzioneHolidaysComponent,
    NewRequestComponent,
    MyRequestsComponent,
    MyBalanceComponent,
    LeaveBalanceDetailsComponent,
    AttendanceComponent,
    MyBreaksComponent,
    ApplyBreaksComponent,
    DashboardToDoComponent,
    DashboardToDoTasksComponent,
    DashboardUpcomingBirthdaysComponent,
    DashboardCalendarComponent,
    MyToDosComponent,
    MyWorklogComponent,
    NewToDosComponent,
    ChangePasswordComponent,
    UpdateToDosComponent,
    DailyArrivalUpdateComponent,
    DailyLoginUpdateComponent,
    SoluzioneDirectoryComponent,
    DashboardActivityDaysComponent,
    DashboardEmployeeOfMonthComponent,
    ProjectListComponent,
    CRMProjectListComponent,
    PaymentsComponent,
    FilterPipe,
    ErrorComponent,
    InvoicePaymentComponent,
    ProjectAllocationComponent,
    SkillsAllocationComponent,
    InventoryAllocationComponent,
    MyToDoosComponent,
    NewToDoosComponent,
    UpdateToDoosComponent,
    TodosBugsComponent,
    ToDosBugsComponent,
    DemoSolzitComponent,
    HiringPositionComponent,
    MyHiringApplicationComponent,
    OpenPositionComponent,
    EmployeeGoalsListComponent,
    AddEmployeeGoalsComponent,
    UpdateEmployeeGoalsComponent,
    PerformanceReviewsFormComponent,
    PerformanceReviewsListComponent,
    UpdatePerformanceReviewFormComponent,
    RaiseBugAllProjectComponent,
    MyObservationComponent,
    UpdateRaiseBugAllProjectComponent,
    FeedbackComponent,
    MyFeedbackComponent,
    DashboardBannerComponent,
    NoLeadingSpaceDirective

  ],
  imports: [
    RouterModule.forChild(viewRuotes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    NgbModule,
    MultiSelectModule,
    FullCalendarModule,
    ChartModule,
    AlertModule,
    TabsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgDynamicBreadcrumbModule,
    NgSelectModule,
    TooltipModule,
    InputTextModule,
    CheckboxModule,
    DialogModule,
    NgbCarouselModule,
    CarouselModule,
    GalleriaModule,
    AutoCompleteModule,
    StepsModule,
    TabViewModule,
    CalendarModule

  ],
  exports: [RouterModule],
  providers: [Sort],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class PortalRoutingModule { }
