import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { LeaveApplicationDataModel } from '../../../_models/leave-application-data-model';
import { LeaveService } from '../../../_services/leave.service';
import { Sort } from '../../../_services/sort.service';

@Component({
  selector: 'app-my-balance',
  templateUrl: './my-balance.component.html',
  styleUrls: ['./my-balance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyBalanceComponent implements OnInit {
  empId: string;
  leaveBalanceList: LeaveApplicationDataModel[];
  leaveId: string;
  empData: EmployeeDataModel;

  constructor(private router: Router,
    public leaveService: LeaveService,
    private sort: Sort,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.empId = localStorage.getItem('empId');

    this.spinner.show();
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  async getData() { 
    this.empData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((empBalance: EmployeeDataModel) => {
   
      this.empData = empBalance.userProfile;
      this.empId = this.empData.userId;
      this.getLeaveBalance();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getLeaveBalance() {
    await this.leaveService.getLeaveBalanceList().subscribe((res: any) => {
      if (res.isSuccessful == true) { // success
        this.leaveBalanceList = res.data;

      }
      else if (res.isSuccessful == false) {
        if(res?.messageDetail?.message_code != 204){

          this.toastr.error(res?.messageDetail?.message, 'Error');
        }
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  async getleaveBalDet(leaveId: any) {
    this.router.navigate(['ESSPortal/leave_requests/my_leave_balance/details/' + leaveId]);
  }

  async toAttendance(val: any) {
    var data = val
    this.router.navigate(['ESSPortal/attendance_registers/attendance/' + data.month.value + '/' + data.year.value]);
  }
  
  async viewSalarySlip(salaryId:any){
    if(salaryId ){

      console.log('salaryId', salaryId)
      this.router.navigate(['ESSPortal/salary-slip/' + salaryId])
    }
    else{
      this.toastr.error('No Record Found', 'ERROR')
    }
  }
}
