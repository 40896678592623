import { INavData } from '@coreui/angular';

export const date = new Date();

export const m = date.getMonth() + 1;

export const y = date.getFullYear();

export const navItems: any[] = [
  {
    name: 'Dashboard',
    url: '/ESSPortal/dashboard/',
    icon: 'fa fa-home',
    role: ['employee']
  },

  // {
  //   title: true,
  //   name: 'Components'
  // },
  {
    name: 'Soluzione Directory',
    url: '/ESSPortal/Soluzione_Directory',
    icon: 'cil-book',
    role: ['employee']

  },

  {
    name: 'My Work',
    url: 'to_dos',
    icon: 'cil-briefcase',
    role: ['employee'],
    children: [
      {
        name: 'My To-Dos',
        url: '/ESSPortal/to_dos/my_to_dos',
        icon: 'cil-library-add',
        role: ['employee']
      },

      //{
      //  name: 'Work Log',
      //  url: '/ESSPortal/to_dos/my_worklog/',
      //  icon: 'fa fa-bar-chart-o'
      //},

      // {
      //   name: 'Add To-Do',
      //   url: '/ESSPortal/to_dos/new_to_do',
      //   icon: 'cil-playlist-add',
      //   role: ['employee'],
      // },
      {
        name: 'My Projects',
        url: '/ESSPortal/project-allocation',
        icon: 'fa fa-folder-open-o',
        role: ['employee'],
      },
      {
        name: 'My Observations',
        url: '/ESSPortal/my-observation',
        icon: 'cil-playlist-add',
        role: ['employee'],
      },

    ]
  },

  {
    name: 'Leaves & Breaks',
    url: '/ESSPortal/leave_requests',
    icon: 'fa fa-plane',
    role: ['employee'],
    children: [
      {
        name: 'Leave Requests',
        url: '/ESSPortal/leave_requests/my_requests/',
        icon: 'fa fa-address-book',
        role: ['employee']
      },

      {
        name: 'Leave Balance',
        url: '/ESSPortal/leave_requests/my_leave_balance',
        icon: 'fa fa-bar-chart-o',
        role: ['employee']
      },
      // {
      //   name: 'If Late Arrival Time',
      //   url: `/ESSPortal/daily_arrival_update/${m}/${y}`,
      //   icon: 'cil-av-timer'
      // },
      // {
      //   name: 'Daily Login Hours',
      //   url: `/ESSPortal/daily_login_update/${m}/${y}`,
      //   icon: 'cil-laptop'
      // },
      {
        name: 'Late Arrival Time',
        url: `/ESSPortal/daily_hours/daily_arrival_update/${m}/${y}`,
        icon: 'fa fa-clock-o ',
        role: ['employee']
      },


      {
        name: 'Breaks',
        url: '/ESSPortal/leave_requests/breaklogs',
        icon: 'fa fa-cutlery',
        role: ['employee']
      }
    ]
  },

  {
    name: 'Performance & Goals',
    url: 'employee-goals-list',
    icon: 'fa fa-line-chart',
    role: ['employee'],
    children: [

      // {
      //   name: 'Performance Review ',
      //   url: `/ESSPortal/performance-reviews-form`,
      //   icon: 'fa fa-bars ',
      //   role: ['employee']
      // },
      {
        name: '  Performance Review ',
        url: `/ESSPortal/performance-reviews-list`,
        icon: 'fa fa-bars ',
        role: ['employee']
      },
      {
        name: 'Employee Goals ',
        url: `/ESSPortal/employee-goals-list`,
        icon: 'fa fa-bars ',
        role: ['employee']
      },
      // {
      //   name: 'Update Performance Review Form ',
      //   url: `/ESSPortal/update-performance-review-form`,
      //   icon: 'fa fa-bars ',
      //   role: ['employee']
      // },
      // {
      //   name: 'Add Employee Goals',
      //   url: `/ESSPortal/add-employee-goals`,
      //   icon: 'fa fa-bar-chart',
      //   role: ['employee']
      // },
      // {
      //   name: 'Update Employee Goals',
      //   url: `/ESSPortal/update-employee-goals`,
      //   icon: 'fa fa-area-chart ',
      //   role: ['employee']
      // }
    ]
  },

  {
    name: 'Hiring Recruitment',
    url: '/ESSPortal/hiring-position',
    icon: 'fas fa-users',
    role: ['employee'],
    children: [
      {
        name: 'Open Positions',
        url: '/ESSPortal/hiring-position',
        icon: 'fa fa-users',
        role: ['employee'],
      },
      {
        name: 'My References ',
        url: '/ESSPortal/my-hiring-application',
        icon: 'fa fa-users',
        role: ['employee'],
      },

    ]
  },
  // {
  //   name: 'Daily hours',
  //   url: 'daily_hours',
  //   icon: 'cil-watch',
  //   role: ['employee'],
  //   children: [
  //     {
  //       name: 'Late Arrival Time',
  //       url: `/ESSPortal/daily_hours/daily_arrival_update/${m}/${y}`,
  //       icon: 'fa fa-clock-o ',
  //       role: ['employee']
  //     },
  //     // {
  //     //   name: 'Daily Login Hours',
  //     //   url: `/ESSPortal/daily_hours/daily_login_update/${m}/${y}`,
  //     //   icon: 'fa fa-laptop ',
  //     //   role: ['employee']
  //     // }
  //   ]
  // },

  // {
  //   name: 'Project',
  //   url: '/ESSPortal/project-list',
  //   icon: 'icon-list',
  //   role: ['employee']
  // },


  {
    name: 'My Profile',
    url: 'my_profile',
    icon: 'icon-user',
    role: ['customer', 'employee'],
    children: [
      {
        name: 'My Skills',
        url: '/ESSPortal/skills-allocation',
        icon: 'fa fa-language',
        role: ['employee'],
      },
      {
        name: 'My Assets',
        url: '/ESSPortal/inventory-allocation',
        icon: 'fa fa-cart-plus',
        role: ['employee'],
      },
      {
        name: 'Change Password',
        url: '/ESSPortal/Account/change_password',
        icon: 'icon-key',
        role: ['customer', 'employee']
      }
    ]
  },
  // {
  //   name: 'Demo',
  //   url: '/ESSPortal/demo-solzit',
  //   icon: 'icon-key',
  //   role: ['customer', 'employee']
  // }
  // {
  //   name: 'CRM Project',
  //   url:'/ESSPortal/crm-project-list',
  //   icon: 'icon-list',
  // }
  // {
  //   name: 'Payments',
  //   url: '/ESSPortal/Payments',
  //   icon: 'icon-list',
  // },
  // {
  //   name: 'invoice-payment',
  //   url: '/ESSPortal/invoice-payment',
  //   icon: 'icon-list',
  // }

  // {
  //   name: 'My Work for Customer',
  //   url: 'customer',
  //   icon: 'cil-briefcase',
  //   role: ['customer'],
  //   children: [
  //     {
  //       name: 'My To-dos',
  //       url: '/ESSPortal/customer/my_to_doos',
  //       icon: 'cil-library-add',
  //       role: ['customer']
  //     },

  //     {
  //       name: 'Add To-Do',
  //       url: '/ESSPortal/customer/new_to_doos',
  //       icon: 'cil-playlist-add',
  //       role: ['customer']
  //     },
  //     // {
  //     //   name: 'Update To-Do',
  //     //   url: '/ESSPortal/customer/update_to_doos',
  //     //   icon: 'cil-playlist-add',
  //     //   role: ['customer']
  //     // },
  //   ]
  // },

  // {
  //   name: 'Salary Slip',
  //   url: '/ESSPortal/salary-slip',
  //   icon: 'cil-book',
  //   role: ['employee']

  // },

   {
    name: 'Feedback',
    url: '/ESSPortal/my-feedback',
    icon: 'fa fa-comments',
    role: ['employee']

  },



];

export const navItemsEmp: any[] = [
  {
    name: 'Dashboard',
    url: '/ESSPortal/dashboard/',
    icon: 'fa fa-home',
    role: ['employee']
  },

  // {
  //   title: true,
  //   name: 'Components'
  // },
  {
    name: 'Soluzione Directory',
    url: '/ESSPortal/Soluzione_Directory',
    icon: 'cil-book',
    role: ['employee']

  },

  {
    name: 'My Work',
    url: 'to_dos',
    icon: 'cil-briefcase',
    role: ['employee'],
    children: [
      {
        name: 'My To-dos',
        url: '/ESSPortal/to_dos/my_to_dos',
        icon: 'cil-library-add',
        role: ['employee']
      },
      {
        name: 'Add To-Do',
        url: '/ESSPortal/to_dos/new_to_do',
        icon: 'cil-playlist-add',
        role: ['employee'],
      },

      {
        name: 'My Projects',
        url: '/ESSPortal/project-allocation',
        icon: 'fa fa-folder-open-o',
        role: ['employee'],
      },
      {
        name: 'My Observation',
        url: '/ESSPortal/RaiseBugAllProjectComponent',
        icon: 'cil-playlist-add',
        role: ['employee'],
      },

    ]
  }, {
    name: 'Leaves & Breaks',
    url: '/ESSPortal/leave_requests',
    icon: 'fa fa-plane',
    role: ['employee'],
    children: [
      {
        name: 'Leave Requests',
        url: '/ESSPortal/leave_requests/my_requests/',
        icon: 'fa fa-address-book',
        role: ['employee']
      },

      {
        name: 'Leave Balance',
        url: '/ESSPortal/leave_requests/my_leave_balance',
        icon: 'fa fa-bar-chart-o',
        role: ['employee']
      },
      // {
      //   name: 'If Late Arrival Time',
      //   url: `/ESSPortal/daily_arrival_update/${m}/${y}`,
      //   icon: 'cil-av-timer'
      // },
      // {
      //   name: 'Daily Login Hours',
      //   url: `/ESSPortal/daily_login_update/${m}/${y}`,
      //   icon: 'cil-laptop'
      // },


      {
        name: 'Breaks',
        url: '/ESSPortal/leave_requests/breaklogs',
        icon: 'fa fa-cutlery',
        role: ['employee']
      }
    ]
  },
  {
    name: 'Daily hours',
    url: 'daily_hours',
    icon: 'cil-watch',
    role: ['employee'],
    children: [
      {
        name: 'Late Arrival Time',
        url: `/ESSPortal/daily_hours/daily_arrival_update/${m}/${y}`,
        icon: 'fa fa-clock-o ',
        role: ['employee']
      },
      {
        name: 'Daily Login Hours',
        url: `/ESSPortal/daily_hours/daily_login_update/${m}/${y}`,
        icon: 'fa fa-laptop ',
        role: ['employee']
      }
    ]
  },

  // {
  //   name: 'Project',
  //   url: '/ESSPortal/project-list',
  //   icon: 'icon-list',
  //   role: ['employee']
  // },

  {
    name: 'My Profile',
    url: 'my_profile',
    icon: 'icon-user',
    role: ['customer', 'employee'],
    children: [
      {
        name: 'My Skills',
        url: '/ESSPortal/skills-allocation',
        icon: 'fa fa-language',
        role: ['employee'],
      },
      {
        name: 'My Assets',
        url: '/ESSPortal/inventory-allocation',
        icon: 'fa fa-cart-plus',
        role: ['employee'],
      },
      {
        name: 'Change Password',
        url: '/ESSPortal/Account/change_password',
        icon: 'icon-key',
        role: ['customer', 'employee']
      }
    ]
  },
  {
    name: 'Performance Reviews',
    url: 'employee-goals-list',
    icon: 'cil-watch',
    role: ['employee'],
    children: [
      {
        name: 'Employee Goals List',
        url: `/ESSPortal/employee-goals-list`,
        icon: 'fa fa-clock-o ',
        role: ['employee']
      },
      {
        name: 'Add Employee Goals',
        url: `/ESSPortal/add-employee-goals`,
        icon: 'fa fa-laptop ',
        role: ['employee']
      },
      {
        name: 'Update Employee Goals',
        url: `/ESSPortal/update-employee-goals`,
        icon: 'fa fa-laptop ',
        role: ['employee']
      }
    ]
  },
];

export const navItemsCust: any[] = [
  {
    name: 'My Profile',
    url: 'my_profile',
    icon: 'icon-user',
    role: ['customer', 'employee'],
    children: [
      {
        name: 'Change Password',
        url: '/ESSPortal/Account/change_password',
        icon: 'icon-key',
        role: ['customer', 'employee']
      }
    ]
  },
  {
    name: 'My Work ',
    url: 'customer',
    icon: 'cil-briefcase',
    role: ['customer'],
    children: [
      {
        name: 'My Task',
        url: '/ESSPortal/customer/my_to_doos',
        icon: 'cil-library-add',
        role: ['customer']
      },

      {
        name: 'Add Task',
        url: '/ESSPortal/customer/new_to_doos',
        icon: 'cil-playlist-add',
        role: ['customer']
      },
    ]
  },
];

