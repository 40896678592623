import { Component, OnInit } from '@angular/core';
import { SupportRequestService } from '../../_services/support-request.service';
import { ProjectDataModel } from '../../_models/projects-data-model';
import { Observable } from 'rxjs';
import { AuthService } from '../../_services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-crm-project-list',
  templateUrl: './crm-project-list.component.html',
  styleUrls: ['./crm-project-list.component.scss']
})
export class CRMProjectListComponent implements OnInit {
  searchProjectData:string='' 
  crmForm!: FormGroup;
  allProjectList: Array<ProjectDataModel> = [];
  task: boolean = false;
  projectData: any;
  userStoryList: any;
  toDos: any;
  projectId: any;
  userStory: any;
  Todos: any;
  filterId: any
  hide: boolean = false;
  features: any[] = [];
  UserStory: any = [];
  NorecordFoundMsg: any;
  recordFlag: any;
  feeturesFlag: boolean = false;
  userStoriesFlag: boolean = false;
  todosFlag: boolean = false;
  plusFlag1: boolean = true;
  minusFlag1: boolean = false;
  plusFlag2: boolean = false;
  minusFlag2: boolean = false;
  fieldsToExport: Array<any> = [];
  data: any;
  id: any;
  ids: any;
  constructor(
    private _supportService: SupportRequestService,
    private AuthService: AuthService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.spinner.show;
    this.id = this.route.snapshot.paramMap.get('id');
     this.getProjectData(this.id); 
    this.AuthService.updateCrmId(this.router.url)
    this.getProjectList();
   
    this.crmForm = this.fb.group({
      name: [null]
    })
      
  }



  get f(): { [key: string]: AbstractControl } {
    return this.crmForm.controls;
  }
  async getProjectData(_id) {  
    let obj;
    obj = {
      id:  this.id,
      filterId: this.filterId ? this.filterId : '',
    }
    this._supportService.getProjectsfeatures(obj.id, obj.filterId).subscribe((response: any) => {

      if (response.ResponseCode == 100) { //

        this.recordFlag = false;
        this.features = response.Data;
        response.Data.forEach(element => {
          if (element.UserStory != null) {
            this.UserStory = element.UserStory;
          }
        })
     
        this.projectData = response.Data;

        // create list for exporting to excel.
   
        this.createExportList();
       
      }
      else {
      
        this.recordFlag = true
        this.features = null
        this.NorecordFoundMsg = response.Message
      }
    },
      error => {
        this.spinner.hide();
        this.toastr.error(error.message);
      });
   
  }
  getProjectList(): void {
  this.spinner.show();
    let url = "false"
    this._supportService.getProjectListRec(url)
      .subscribe({
        next: (res: any) => {
          this.allProjectList = res.Data;
          this.allProjectList.filter(element => {
            if(element.ID===this.id){ 
              this.crmForm.patchValue({ name: element.projectName });
            }
          })
          this.spinner.hide();
        }
      })
  }
  // projectSelected(event) {
  //   
  //   this.projectId = event
  //   this.getProjectData(event);
  //   if (event) {
  //     this.task = true
  //   }
  //   else {
  //     this.task = false
  //   }
  // }
  TaskStatus(selected) {
   
    if (selected === "null") {
      this.filterId = null;

    }
    else {
      this.filterId = selected;
    }
    this.getProjectData(selected)

  }
  //Toggle for Expand And collapsed Table Row Data
  // showHideToggle(){
  //     this.ToggleFlag=!this.ToggleFlag;
  //  }
  updateTodos(id){
     var url='https://solzit.crm5.dynamics.com/main.aspx?appid=78c076a1-f776-e911-a99b-000d3a81e548&pagetype=entityrecord&etn=solz_supportrequest&id=' + id; 
     window.open(url);
  }
  updateFeatures(id){
     var url='https://solzit.crm5.dynamics.com/main.aspx?appid=78c076a1-f776-e911-a99b-000d3a81e548&pagetype=entityrecord&etn=solz_supportrequest&id=' + id; 
     window.open(url);
  }
  updateUserStory(id){
    var url='https://solzit.crm5.dynamics.com/main.aspx?appid=78c076a1-f776-e911-a99b-000d3a81e548&pagetype=entityrecord&etn=solz_supportrequest&id=' + id; 
    window.open(url);
  }
  expandLabel(selected: any) {
    if (selected == 1) {
      this.feeturesFlag = true;
      this.userStoriesFlag = false;
      this.todosFlag = false;
      this.plusFlag1 = true;
      this.minusFlag1 = false;
    }
    else if (selected == 2) {
      this.userStoriesFlag = true;
      this.feeturesFlag = true;
      this.todosFlag = false;
      this.plusFlag1 = false;
      this.minusFlag1 = true;
      this.plusFlag2 = true;
      this.minusFlag2 = false;
    }
    else if (selected == 3) {
      this.todosFlag = true;
      this.userStoriesFlag = true;
      this.feeturesFlag = true;
      this.plusFlag1 = false;
      this.minusFlag1 = true;
      this.plusFlag2 = false;
      this.minusFlag2 = true;
    }

  }

  // creating user readable list.
  createExportList() {
    class ex {
      "SR No":string;
      "TaskName": "";
      "Type": string;
      "Complete": "";
      "Effort": "";
      "Assignee": "";
      "Reviewer": "";
      "Start": "";
      "Finish": "";
      "Work_Status": "";
    }
    this.projectData.forEach(feature => {
      var item = new ex();
      item['SR No']=feature.ticketNumber;
      item.TaskName = feature.TaskName;
      item.Type = "Feature";
      item.Complete = feature.Complete;
      item.Effort = feature.Effort;
      item.Assignee = feature.Assignee;
      item.Reviewer = feature.Reviewer;
      item.Start = feature.Start;
      item.Finish = feature.Finish;
      this.fieldsToExport.push(item);
      if (feature.UserStory!) {
        feature.UserStory.forEach(userStory => {
          var item1 = new ex();
          item['SR No']=userStory.ticketNumber;
          item1.TaskName = userStory.TaskName;
          item1.Type = "User Story";
          item1.Complete = userStory.Complete;
          item1.Effort = userStory.Effort;
          item1.Assignee = userStory.Assignee;
          item1.Reviewer = userStory.Reviewer;
          item1.Start = userStory.Start;
          item1.Finish = userStory.Finish;
          this.fieldsToExport.push(item1);
          if (userStory.Todos!) {
            userStory.Todos.forEach(Todos => {
              var item2 = new ex();
              item['SR No']=Todos.ticketNumber;
              item2.TaskName = Todos.TaskName;
              item2.Type = "To-Dos";
              item2.Complete = Todos.Complete;
              item2.Effort = Todos.Effort;
              item2.Assignee = Todos.Assignee;
              item2.Reviewer = Todos.Reviewer;
              item2.Start = Todos.Start;
              item2.Finish = Todos.Finish;
              this.fieldsToExport.push(item2);

            });
          }

        });

      }
    });
  }

  //export to Excel sheet

  exportToExcel(projectData: any) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.fieldsToExport);
      const workbook = { Sheets: { 'Exporting_Tasks': worksheet }, SheetNames: ['Exporting_Tasks'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Exporting_Tasks");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }
  

}
