import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';
import { MasterDropdownServiceService } from '../../../_services/master-dropdown-service.service';
import { OptionSetDataModels } from '../../../_models/optionSetDataModel-model';
import { TableModule } from 'primeng/table';
import { Location } from '@angular/common';

type NewType = ToDosDataModal;

@Component({
  selector: 'app-update-to-dos',
  templateUrl: './update-to-dos.component.html',
  styleUrls: ['./update-to-dos.component.css']
})
export class UpdateToDosComponent implements OnInit, AfterViewInit {
  supportReqObj: SupportRequestDataModel;
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  // testingEnvironmentList: Array<OptionSetDataModel> = [];
  // allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  endDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: NewType;
  solzStatusList: Array<OptionSetDataModel> = [];
  solzStatusChangeList: Array<OptionSetDataModels> = [];
  workStatusList: Array<OptionSetDataModels> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  upLoadImageFileList: Array<UploadFileDataModel> = [];
  downloadFileList: Array<UploadFileDataModel> = [];
  updateToDoId: string = "";
  lockStartDate: boolean = false; // used for locking start date form field if not having null or null date
  lockEndtDate: boolean = false; // used for locking start date form field if not having null or null date 
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList = [];
  linkedTaskFlag: boolean = false;
  updateLinkedTASK: boolean = false;

  // used for Asign Me As Option Set.
  assignMeList: any[] = [];
  assign: any;
  // used for work logs list
  workLogsList: Array<WorkLogDataModel> = [];
  showHideWorklogFlag: boolean = false;
  showHideWorklogDataFlag: boolean = false;
  showHideBugGridFlag: boolean = false;

  // for update form
  lockFields: boolean = false;
  implementationEffortFlag: boolean;
  effortLockFlag: boolean;
  expectedDateFlag: boolean;
  projectName: string;
  userStory: any;
  bugListData: any;
  edit: string = null;
  srNumberItemno: string;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  // searchFilterVal: any;
  viewImageSrc = null;
  tempImgArray = [];
  worklogRecordId: any;
  deteleworklogRow: any;
  // analyzingEstimationFlg: boolean = false;
  solzStatusvalue: number;
  filteredDuplicateLinkedTaskList: any[] = [];  // Array to hold the filtered items
  parentId: any[];
  UserStorydataCheck: any
  msg1: boolean;
  linkedDuplicateTasksList: any[] = [];
  getTodoDetails: any;
  i = 0;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public sharepointServices: SharepointServiceService,
    private router: Router,
    private MasterDropdownServiceService: MasterDropdownServiceService,
    private renderer: Renderer2, private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private location: Location) { }


  ngAfterViewInit(): void {
    document.getElementById('commentDiv')?.addEventListener(
      'keydown', (e: KeyboardEvent) => {
        if (!((e.ctrlKey && (e.key == 'v' || e.key == 'V')) || e.code == 'Backspace')) e.preventDefault();
      }
    )
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateToDoId = this.route.snapshot.paramMap.get('id');

      this.supportReqForm = this.fb.group({
        ID: [''],
        endDate: [null],
        ItemId: [''],
        itemNo: [''],
        title: ['', [Validators.required]],
        projectName: ['', [Validators.required]],
        projectId: [null, [Validators.required]],
        solzWorkStatus: [null, [Validators.required]],
        itemDescription: ['', [Validators.required]],
        reproSteps: [''],
        parentId: [null],
        userPriority: [null],
        userPriorityName: [''],
        isClientReported: ['false', [Validators.required]],
        assigneeId: [null],
        assigneeName: [''],
        implementationeffort: ['', [Validators.required]], // Fixed line
        comment: [''],
        CreatedBy: [''],
        startDate: [null],
        itemType: ['', [Validators.required]],
        linkedTaskName: [''],
        userStoryTitleAndName: [''],
        linkedTaskTitleAndItemNo: [''],
        featureId: [''],
        featureName: [''],
        testingEnvironmentName: [''],
        sprintName: ['']
      });

      this.getDropDownWorkStatus();

    }



  }


  copyPastImageDuplicate() {
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');
    document.getElementById('commentDiv').addEventListener(
      'keydown', (e: KeyboardEvent) => {
        if (!((e.ctrlKey && (e.key == 'v' || e.key == 'V')) || e.code == 'Backspace')) e.preventDefault();
      }
    )

    this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(ce, button);
            }
          }
        }
        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        if (files.length > 0) {
          this.pastAttachments(mockEvent); // Call selectAttachments with the mock event
        }

      }
    });
  }

  copypastImageData() {
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');




    this.renderer?.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const span = this.renderer.createElement('span');
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              const closeButton = this.renderer.createElement('a');
              closeButton.setAttribute('style', 'cursor: pointer; color: red');
              this.renderer.setAttribute(closeButton, 'contenteditable', 'false');
              this.renderer.listen(closeButton, 'click', () => this.deleteImage(fileName));
              const iconClose = this.renderer.createElement('i');
              iconClose.setAttribute('style', 'color: red');
              this.renderer.addClass(iconClose, 'fa');
              this.renderer.addClass(iconClose, 'fa-regular');
              this.renderer.addClass(iconClose, 'fa-window-close');
              this.renderer.addClass(iconClose, 'mx-2');
              i += 1;
              // const textbutton = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(span, button);
              this.renderer.appendChild(closeButton, iconClose);
              // this.renderer.appendChild(closeButton, textbutton);
              this.renderer.appendChild(span, closeButton);
              console.log(span)
              this.renderer.appendChild(ce, span);
            }
          }
        }

        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        // if (files.length > 0) {
        //   this.selectAttachments(mockEvent); // Call selectAttachments with the mock event
        // }
      }
    });
  }

  pastAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadImageFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  deleteImage(fileName) {
    console.log(fileName)
    const element = document.querySelector('#commentDiv')
    const node = Array.from(element.children).find(el => el.textContent.includes(fileName));
    node.remove();
  }



  downloadImg(fileName: string, template) {
    debugger
    this.viewImageSrc = this.tempImgArray.find(img => img.fileName == fileName);
  }

  async downloadPreviewImage() {
    debugger
    let FileSaver = await import("file-saver");
    FileSaver.saveAs(this.viewImageSrc.base64, this.viewImageSrc.fileName);
  }

  viewImage(file: any) {
    console.log("File Data:", file);

    if (file && this.isImageFile(file.fileName)) {
      let imageSrc = '';

      // Check if 'bytes' contains Base64 data
      if (file.bytes && !file.bytes.startsWith('data:image')) {
        imageSrc = `data:image/png;base64,${file.bytes}`;
      }
      // If Base64 data is missing, use fileUrl (server path)
      else if (file.fileUrl) {
        imageSrc = file.fileUrl;
      }
      // If neither is available, show an error
      else {
        alert("No valid image data found!");
        return;
      }

      // Assign to viewImageSrc
      this.viewImageSrc = {
        base64: imageSrc,
        fileName: file.fileName
      };

      console.log("Updated viewImageSrc:", this.viewImageSrc);
    } else {
      alert("This file is not an image.");
    }
  }


  // Function to check if the file is an image
  isImageFile(fileName: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);
  }



  //*********************** Get APIs Begin *****************************
  //********************* To Dos Begin********************

  async getData() {

    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.endDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;
    this.lockStartDate = false;
    this.lockEndtDate = false
    this.lockFields = false;
    this.implementationEffortFlag = false;
    this.expectedDateFlag = false;
    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {

      this.empData = empNewRequest.userProfile;

      // If Soluzione Emp/Manager logged in
      if (this.empData.userId) {

        this.contactLoggedIn = false;
        this.isManager = this.empData?.isManager;
        this.empId = this.empData?.userId

        this.getWorkLogList();

      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
    this.getToDosDataToUpdate()
    this.cdRef.detectChanges();
  }
  // ############################## Get all Dropdown of SolzStatus ###################################
  async getDropDownWorkStatus() {
    // this.spinner.show();
    await this.MasterDropdownServiceService.getMasterDropdown('SolzStatus').subscribe((dropdownResponce: any) => {
      if (dropdownResponce.isSuccessful == true) { // success
        this.solzStatusChangeList = dropdownResponce.data;

        this.getData(); // getting data from local storage api and setting it in our local variable.
        this.cdRef.detectChanges();
      }
      else if (dropdownResponce.isSuccessful == false) {
        if (dropdownResponce?.messageDetail?.message_code != 204) {

          this.toastr.error(dropdownResponce?.messageDetail?.message, 'Error');
        }
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail.message);
    })
  }
  // ############################## Get all Linked Task and bugs of project By ProjectId   ###################################
  GetLinkedTaskByIdProjectId(projectId) {
    this.toDosService.GetLinkedTaskByIdProjectId(projectId).subscribe((linkedTaskResp: any) => {
      if (linkedTaskResp.isSuccessful == true) {

        this.linkedDuplicateTasksList = linkedTaskResp.data
      }
      else {
        this.linkedDuplicateTasksList = []
      }
    })
  }


  // for To Do update.
  async getToDosDataToUpdate() {
    this.supportReqObj = new SupportRequestDataModel();
    this.assignMeList = [{ Label: "Assignee", Value: 0 }
      // , { Label: "Assigned Reviewer", Value: 1 }
    ];
    await this.toDosService.getToDosDetailsForUpdation(this.updateToDoId).subscribe((toUpRes: any) => {

      if (toUpRes.isSuccessful == true) {   // success 
        this.copyPastImageDuplicate();
        this.supportReqObj = toUpRes.data;
        this.getTodoDetails = toUpRes.data
        this.projectName = this.supportReqObj.projectName;
        // this.isBugCheck = this.supportReqObj.isBug;
        this.srNumberItemno = toUpRes.data.itemNumber
        this.GetLinkedTaskByIdProjectId(this.supportReqObj.projectId)
        const status = toUpRes?.data?.workStatus?.value;//this.supportReqForm.get('solzWorkStatus').value;
        if (toUpRes?.data?.itemType?.value == 674180001) { //if item type is user story

          this.GetTodosByLinkedTaskIdOnBug();
        }

        //***********************If Item type is To-Do then Set Status Behavior  *****************************         

        if (toUpRes?.data?.itemType?.value == 674180002) { //if Item Type Is To-Do

          this.supportReqForm.controls['userStoryTitleAndName'].setErrors({ 'required': true });
          if (status === 674180000) { // Initial Status // not Started
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180009 || s.value === 674180000 || s.value == 674180012 || s.value == 674180008 || s.value == 674180014  // Analyzing / Duplicate / On Hold / Needs Clarification
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }

          else if (status === 674180009) { // Analyzing
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180001 || s.value === 674180009 || s.value == 674180012 || s.value == 674180008 || s.value == 674180014  // Work In Progress / Duplicate / On Hold / Needs Clarification// 
            )
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
            // this.supportReqForm.controls['analyzingEstimation'].markAsTouched();

            // this.analyzingEstimationFlg = true;
          }
          else if (status === 674180001) { // Work In Progress
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180001 || s.value === 674180002 || s.value == 674180008 // Work In Progress / On Hold

            )
            // this.lockFields = true
            // this.supportReqForm.get('analyzingEstimation').disable();
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = true

          }
          else if (status === 674180002) { // Work Complete
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180002 // Work In Progress || Work Complete
            );
            this.supportReqForm.disable()
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180012) //Duplicate
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180012
            );
            this.supportReqForm.disable()
            this.linkedTaskFlag = false
            this.updateLinkedTASK = true
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180008) //On Hold
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180008 || s.value === 674180001 || s.value === 674180012 || s.value === 674180014 // Duplicate
            );
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180014) //Need clarification 
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180014 || s.value === 674180015 || s.value === 674180008
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180015) //Clarification Given
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180015 || s.value === 674180001 || s.value == 674180012 || s.value == 674180008 || s.value == 674180014 // Duplicate
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }
          else {
            this.solzStatusChangeList = []; // Default or no options
          }
        }

        //***********************If Item type is Bug then Set Status Behavior  ***************************** 

        else if (toUpRes?.data?.itemType?.value == 674180003) { //if Item Type Is Bug
          // this.supportReqForm.controls['userStoryTitleAndName'].setErrors({ 'required': true });

          if (status === 674180000) { // Initial Status // not Started
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180009 || s.value === 674180000 || s.value == 674180012 || s.value == 674180008 || s.value == 674180014  // Analyzing / Duplicate / On Hold / Needs Clarification
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }

          else if (status === 674180009) {// Analyzing
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180001 || s.value === 674180009 || s.value == 674180012 || s.value == 674180008 || s.value == 674180014 || s.value == 674180011 // Work In Progress / Duplicate / On Hold / Needs Clarification// not an issue 
            )
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
            // this.supportReqForm.controls['analyzingEstimation'].markAsTouched();

            // this.analyzingEstimationFlg = true;
          }
          else if (status === 674180001) { // Work In Progress
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180001 || s.value === 674180003 || s.value == 674180008 // Work In Progress / On Hold
            )
            // this.lockFields = true
            // this.supportReqForm.get('analyzingEstimation').disable();
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = true
          }
          else if (status === 674180003) { // Ready for Review	
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180003 || s.value === 674180005 || s.value == 674180006 // Ready for Review , review passed , review failed
            );
            // this.supportReqForm.disable()
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = false
            this.lockFields = false
          }

          else if (status == 674180005) { //Review Passed
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180005  // Review Passed
            );
            this.supportReqForm.disable()
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180006) { //Review Failed	
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180006 || s.value === 674180001 || s.value == 674180014 // Ready for Review , work-in-progress , need clarification 
            );
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180012) //Duplicate
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180012
            );
            this.supportReqForm.disable()
            this.linkedTaskFlag = false
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180008) //On Hold
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180008 || s.value === 674180001 || s.value === 674180012 || s.value === 674180014 // Duplicate
            );
            this.showHideWorklogFlag = true
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180014) //Need clarification 
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180014 || s.value === 674180015 || s.value === 674180008 || s.value == 674180011
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180015) //Clarification Given
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180015 || s.value === 674180001 || s.value == 674180012 || s.value == 674180008 || s.value == 674180014 || s.value == 674180011 // Duplicate
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }
          else if (status == 674180011) //Not An Issue
          {
            this.workStatusList = this.solzStatusChangeList?.filter(
              s => s.value === 674180011 || s.value === 674180014 || s.value == 674180008 || s.value == 674180001
            );
            this.showHideWorklogFlag = false
            this.showHideWorklogDataFlag = false
          }
          else {
            this.solzStatusChangeList = []; // Default or no options
          }

        }
        // filling form fields.
        this.supportReqForm.updateValueAndValidity();
        this.supportReqForm.patchValue({
          ID: toUpRes.data.id,
          ItemId: toUpRes?.data?.id,
          Name: this.supportReqObj?.Name,
          assignedReviewerId: this.supportReqObj?.assignedReviewerId,
          assignedReviewerName: this.supportReqObj?.assignedReviewerName,
          assigneeId: toUpRes?.data?.assignee?.id,
          assigneeName: toUpRes?.data?.assignee?.name,
          comment: toUpRes?.data?.comments,
          endDate: toUpRes?.data?.plannedEndDate,
          descriptionOfResolution: this.supportReqObj?.descriptionOfResolution,
          implementationeffort: this.supportReqObj?.implementationeffort,
          isClientReported: this.supportReqObj?.isClientReported.toString(),
          itemDescription: toUpRes?.data?.description,
          itemNo: this.supportReqObj?.itemNo,
          projectId: this.supportReqObj?.projectId,
          projectName: this.supportReqObj?.projectName,
          reproSteps: this.supportReqObj?.reproSteps,
          // revieweffort: this.supportReqObj.revieweffort,
          startDate: toUpRes?.data?.plannedStartDate,
          solzWorkStatus: toUpRes?.data?.workStatus?.value,
          testingEnvironment: toUpRes?.data?.testingEnviroment?.Value,
          title: this.supportReqObj?.title,
          typeOfItem: this.supportReqObj?.typeOfItem?.Value,
          userPriority: toUpRes?.data?.userPriority?.value,
          userPriorityName: toUpRes?.data?.userPriority?.label,
          workArea: this.supportReqObj?.workArea?.Value,
          parentTitle: this.supportReqObj?.parentTitle,
          parentId: this.supportReqObj?.linkedTask?.name,
          parentItemNo: this.supportReqObj?.parentItemNo,
          itemType: toUpRes?.data?.itemType?.label,
          sprintName: toUpRes?.data?.sprint?.label,
          sprintId: this.supportReqObj?.sprintId,
          linkedTask: this.supportReqObj?.linkedTask,
          linkedTaskName: this.supportReqObj?.linkedTask?.name,
          linkedTaskId: this.supportReqObj?.linkedTask,
          linkedTaskTitleAndItemNo: this.supportReqObj?.linkedTaskTitle,
          userStoryTitleAndName: toUpRes?.data?.userStoryTitle,
          featureName: toUpRes?.data?.feature?.name,
          featureId: toUpRes?.data?.feature?.id,
          testingEnvironmentName: toUpRes?.data?.testingEnviroment?.label,
        });

        if (this.supportReqForm.value.isClientReported) {
          this.supportReqForm.value.isClientReported = "true";
        }

        else {
          this.supportReqForm.value.isClientReported = "false";
        }



        // locking fields according to specific conditions
        if (!this.contactLoggedIn) {

          if (this.supportReqForm.value.solzWorkStatus != null) {
            if (this.supportReqForm.value.solzWorkStatus == 674180002 || this.supportReqForm.value.solzWorkStatus == 674180005) {

              this.lockFields = true;
              this.toastr.info("This Task is already  Complete, you can't add or modify any field's ", 'Information', {
                positionClass: 'toast-top-full-width',
                timeOut: 3000
              });
            }
          }

          if (this.supportReqForm.value.implementationeffort && this.supportReqForm.value.implementationeffort != 0 && status != 674180000 && status != 674180009) {
            this.effortLockFlag = true;
          }

          if (this.supportReqForm.value.implementationeffort == 0) {
            this.supportReqForm.value.implementationeffort == null;
            this.lockFields = false;
            this.effortLockFlag = false;
          }


          if (this.supportReqForm.value.endDate != "0001-01-01T00:00:00" && this.supportReqForm.value.endDate != null) {
            this.supportReqForm.value.endDate = moment(this.supportReqForm.value.endDate).format('YYYY-MM-DD');
            this.supportReqForm.patchValue({
              endDate: this.supportReqForm.value.endDate
            });
            if (status != 674180000 && status != 674180009) {

              this.lockEndtDate = true;
            }
          }
          // format of expected resolution date so as to display in form for Employee.
          if (this.supportReqForm.value.startDate != "0001-01-01T00:00:00" && this.supportReqForm.value.startDate != null) {
            this.supportReqForm.value.startDate = moment(this.supportReqForm.value.startDate).format('YYYY-MM-DD');
            this.supportReqForm.patchValue({
              startDate: this.supportReqForm.value.startDate
            });
            if (status != 674180000 && status != 674180009) {


              this.lockStartDate = true;
            }
          }
          this.spinner.hide();

          if (status === 674180001) {
            if (toUpRes?.data?.isSystemGenerated) {
              this.supportReqForm.disable()
            }
          }


          else {
            this.supportReqForm.value.startDate = null;
          }
        }
      }

      else if (toUpRes.isSuccessful == false) {
        this.toastr.error(toUpRes?.messageDetail?.message, 'Error');
        this.spinner.hide();

      }

      this.getToDsAttachments();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
    this.supportReqForm.updateValueAndValidity();
    this.cdRef.detectChanges();

  }


  //CRM SharePoint API
  async getToDsAttachments() {
    this.downloadFileList = [] as Array<UploadFileDataModel>;
    await this.sharepointServices.GetAttachmentFromSharePoint(this.updateToDoId).subscribe((fileRes: any) => {
      if (fileRes.isSuccessful == true) {// success
        this.downloadFileList = fileRes.data;
      }

      else if (fileRes.isSuccessful == false) {
        // if(fileRes.Data != null){q
        //   this.toastr.error(fileRes.Message, 'Error');
        // }        
        this.spinner.hide();

      }
      // this.getWorkLogList();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  //********************* To Dos End ********************

  //********************* Work Log Begin ********************

  getWorkLogList() {
    this.workLogsList = [] as Array<WorkLogDataModel>;
    this.toDosService.getWorkLogsByEmpIdAndToDoId(this.updateToDoId).subscribe((workRes: any) => {
      if (workRes.isSuccessful == true) {// success.

        this.workLogsList = workRes.data;
      }

      else if (workRes.isSuccessful == false) {
        this.spinner.hide();
        if (workRes?.messageDetail?.message_code != 204) {
          this.toastr.error(workRes?.messageDetail?.message, 'Error');
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }



  //********************* Work Log End ********************

  //*********************** Get APIs End *****************************

  //****************************** To-Do Related Actions Begin *************************


  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }

  /*On Change of solz status i.e if solz status is  "Not Started" or "Analyzing" or "On Hold" or "Duplicate" or "QA Failed" or "Canceled" than ETA and Estimated Effort is not Required, else Bussiness Required.*/
  solzStatusSelected(val: number) {
    this.solzStatusvalue = val
    if (val == 674180000 || val == 674180009) // Not Started , Analyzing
    {
      this.fieldRequiredFlag = false;
      this.startDateFlag = false;
      this.endDateFlag = false;
      this.implementationEffortFlag = false;

      this.supportReqForm.controls['linkedTaskName'].setErrors(null);



    }
    if (val == 674180001) //Work In Progress
    {
      this.fieldRequiredFlag = true;
      this.startDateFlag = true;
      this.endDateFlag = true;
      this.implementationEffortFlag = false;

    }
    if (val == 674180002) //Work Complete
    {
      // this.supportReqForm.disable();
    }

    if (val == 674180014 || val == 674180008 || val == 674180015 || val == 674180012 || val == 674180012) //Needs Clarification , On Hold,  Clarification Given
    {
      this.fieldRequiredFlag = false;
      this.startDateFlag = false;
      this.endDateFlag = false;
      this.implementationEffortFlag = false;
      this.supportReqForm.controls['comment'].setErrors({ 'required': true });
      if (val == 674180012) {
        this.supportReqForm.controls['linkedTaskName'].setErrors({ 'required': true });
        this.linkedTaskFlag = true
      }
      else {
        this.supportReqForm.controls['linkedTaskName'].setErrors(null);
        this.linkedTaskFlag = false

      }
    }
    else {
      this.linkedTaskFlag = false
    }




  }
  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }



  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }

  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    // Find the first invalid control
    const invalidControl = Object.keys(form.controls).find(key => {
      return form.get(key)?.invalid;
    });

    if (invalidControl) {
      const invalidElement = document.querySelector(`[formControlName="${invalidControl}"]`);

      if (invalidElement) {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        (invalidElement as HTMLElement).focus();
      }
    }
  }
  checkValidityUserstory(event) {
    if (event) {
      this.UserStorydataCheck = event
    }
  }
  // Get filtered user story list based on the searchText input
  filterUserStories(event: any) {
    this.msg1 = false
    let filtered: any[] = [];
    let query = event.query.toLowerCase();  // Convert the query to lowercase for case-insensitive matching

    for (let i = 0; i < this.linkedDuplicateTasksList?.length; i++) {
      let duplicateTask = this.linkedDuplicateTasksList[i];

      // Perform case-insensitive search for any word related to the query
      if (duplicateTask?.title?.toLowerCase().includes(query)) {
        filtered.push(duplicateTask);
      }
    }

    // Update the filtered list
    this.filteredDuplicateLinkedTaskList = filtered;
  }

  //********************************************** for autoResize Text Area ***************************************  */
  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    // Reset the height to recalculate
    textarea.style.height = 'auto';
    // Set the height to fit the content
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  adjustHeight(event: Event) {
    const element = event.target as HTMLElement;
    element.style.height = 'auto'; // Reset height
    element.style.height = element.scrollHeight + 'px'; // Set new height
  }
  
  onSubmit() {
    debugger
    this.supportReqFormSubmitted = true;
    this.supportReqForm.controls['linkedTaskName'].setErrors(null);
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      this.scrollToFirstInvalidControl(this.supportReqForm)

    }

    if (this.UserStorydataCheck != null || this.UserStorydataCheck == null) {
      if (this.solzStatusvalue == 674180012) {

        if (this.supportReqForm.value.linkedTaskName === this.UserStorydataCheck) {
          this.supportReqForm.controls['linkedTaskName'].setErrors({ 'required': true });
          this.spinner.hide();
          this.msg1 = true
          this.scrollToFirstInvalidControl(this.supportReqForm)
        }
        if (this.solzStatusvalue == 674180012 && this.supportReqForm.value.linkedTaskName == null) {
          this.supportReqForm.controls['linkedTaskName'].setErrors({ 'required': true });
          this.spinner.hide();
          this.msg1 = false
          this.scrollToFirstInvalidControl(this.supportReqForm)
        }
      }
    }

    this.spinner.show();

    if (this.fieldRequiredFlag == true || this.reviewerEffortfieldFlag == true) {

      if (this.supportReqForm.value.startDate == null || this.supportReqForm.value.startDate == "0001-01-01T00:00:00" || this.supportReqForm.value.startDate == "Invalid date") {

        this.supportReqForm.controls['startDate'].setErrors({ 'required': true });
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
        this.endDateFlag = true;
      }
      if (this.supportReqForm.value.endDate == null || this.supportReqForm.value.endDate == "0001-01-01T00:00:00" || this.supportReqForm.value.endDate == "Invalid date") {

        this.supportReqForm.controls['endDate'].setErrors({ 'required': true });
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
        this.endDateFlag = true;
      }

    }

    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      return;
    }
    var isr = this.supportReqForm.value.isClientReported;
    this.supportReqForm.value.isClientReported = JSON.parse(isr);

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.itemType;
    this.supportReqForm.value.itemType = ib
    // JSON.parse(ib);

    if (this.supportReqForm.value.testingEnvironment != null) {
      var te = this.supportReqForm.value.testingEnvironment;
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = te;
    }

    else {
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = 674180004
    }

    if (this.supportReqForm.value.startDate != null) {
      var date = new Date(this.supportReqForm.value.startDate);
      var sd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.startDate = sd;
    }
    if (this.supportReqForm.value.endDate != null) {
      var date = new Date(this.supportReqForm.value.endDate);
      var ed = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.endDate = ed;
    }

    if (this.supportReqForm.value.expectedResDate != null) {
      var erd = this.supportReqForm.value.expectedResDate;
      this.supportReqForm.value.expectedResDate = moment(erd).format('YYYY-MM-DD');
    }

    if (!this.contactLoggedIn) {
      this.supportReqForm.value.createdBy = this.empId;
      this.supportReqForm.value.CreatedBy = this.empId;
    }

    if (this.assign?.length == 1) {
      if (this.assign[0].Value == 0) {

        this.supportReqForm.value.assigneeId = this.empId;
      }
      else if (this.assign[0].Value == 1) {
        this.supportReqForm.value.assignedReviewerId = this.empId;
      }
    }

    else if (this.assign?.length == 2) {
      this.supportReqForm.value.assigneeId = this.empId
      this.supportReqForm.value.assignedReviewerId = this.empId;
    }

    // deciding which object to send according to who logged in i.e., 

    var updateSupportReqObj;
    if (this.contactLoggedIn) {

      updateSupportReqObj = {
        ID: this.supportReqForm.value.ID,
        comment: this.supportReqForm.value.comment,
        ItemId: this.supportReqForm.value.ItemId,
        itemNo: this.supportReqForm.value.itemNo,
        projectId: this.supportReqForm.value.projectId,
        projectName: this.supportReqForm.value.projectName,
        // solzStatus: this.supportReqForm.value.solzWorkStatus
      }
    }
    else {
      updateSupportReqObj = this.supportReqForm.value;
    }
    let newTodoObj = {
      toDoId: this.supportReqForm.value.ID,
      plannedStartDate: this.cleanDate(this.supportReqForm.value.startDate),
      plannedEndDate: this.cleanDate(this.supportReqForm.value.endDate),
      implementationEffort: this.supportReqForm.value.implementationeffort,
      comment: this.supportReqForm.value.comment,
      duplicateTaskId: this.supportReqForm?.value?.linkedTaskName?.id || null,
      workStatus: {
        value: this.supportReqForm?.value?.solzWorkStatus,
        label: ""
      },
      assigneeId:
        this.assign?.[0]?.Value === 0
          ? this.empId // Use empId if assign[0].Value is 0
          : this.supportReqForm?.value?.assigneeId // Otherwise, use the value from the form

    }

    let newBugObj = {
      bugId: this.supportReqForm.value.ID,
      plannedStartDate: this.cleanDate(this.supportReqForm.value.startDate),
      plannedEndDate: this.cleanDate(this.supportReqForm.value.endDate),
      implementationEffort: this.supportReqForm.value.implementationeffort,
      comment: this.supportReqForm.value.comment,
      duplicateTaskId: this.supportReqForm?.value?.linkedTaskName?.id || null,
      reproSteps: this.supportReqForm.value.reproSteps || null,
      workStatus: {
        value: this.supportReqForm?.value?.solzWorkStatus,
        label: ""
      },
      assigneeId:
        this.assign?.[0]?.Value === 0
          ? this.empId // Use empId if assign[0].Value is 0
          : this.supportReqForm?.value?.assigneeId // Otherwise, use the value from the form

    }

    //*********************** If Item Type is To-Do *****************************
    if (this.supportReqObj.itemType.value == 674180002) {
      this.toDosService.updateToDo(newTodoObj).subscribe((updateRes: any) => {


        if (updateRes.isSuccessful == true) { // success
          if (this.upLoadFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadFileList.length; i++) {
              this.upLoadFileList[i].ID = this.updateToDoId;
            }

            var attachFiles = { itemDetails: this.upLoadFileList }
            this.uploadFiles(attachFiles);
          }
          else if (this.upLoadImageFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadImageFileList.length; i++) {
              this.upLoadImageFileList[i].ID = this.updateToDoId;
            }

            var attachFiles = { itemDetails: this.upLoadImageFileList }
            this.uploadFiles(attachFiles);
          }
          // else just show message.
          this.toastr.success(updateRes?.messageDetail?.message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });

          if (this.upLoadFileList.length == 0 && this.upLoadImageFileList.length == 0) {
            this.spinner.hide();
            if (this.solzStatusvalue == 674180002) {
              this.router.navigate(['/ESSPortal/to_dos/my_to_dos'])
            }
            else {

              this.getData();
              this.cdRef.detectChanges();
            }

          }
        }

        else if (updateRes.isSuccessful == false) {
          this.toastr.error(updateRes?.messageDetail?.message, 'Error');
          this.spinner.hide();

        }

      }, error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message);
      });
    }

    //*********************** If Item Type is Bug *****************************

    else if (this.supportReqObj.itemType.value == 674180003) {
      this.toDosService.EditBug(newBugObj).subscribe((updateRes: any) => {


        if (updateRes.isSuccessful == true) { // success
          if (this.upLoadFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadFileList.length; i++) {
              this.upLoadFileList[i].ID = this.updateToDoId;
            }

            var attachFiles = { itemDetails: this.upLoadFileList }
            this.uploadFiles(attachFiles);
          }
          else if (this.upLoadImageFileList.length != 0) { // upload files if any
            for (let i = 0; i < this.upLoadImageFileList.length; i++) {
              this.upLoadImageFileList[i].ID = this.updateToDoId;
            }

            var attachFiles = { itemDetails: this.upLoadImageFileList }
            this.uploadFiles(attachFiles);
          }
          // else just show message.

          this.toastr.success(updateRes?.messageDetail?.message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });

          if (this.upLoadFileList.length == 0) {
            this.spinner.hide();
            this.getData();
            this.cdRef.detectChanges();
          }
        }

        else if (updateRes.isSuccessful == false) {
          this.toastr.error(updateRes?.messageDetail?.message, 'Error');
          this.spinner.hide();

        }

      }, error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message);
      });
    }

  }
  // Helper function to validate the date
  cleanDate(date: any): any {
    debugger
    if (!date || isNaN(new Date(date).getTime())) {
      return null; // Return null if date is invalid or empty
    }
    return date; // Otherwise, return the valid date
  }
  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
    await this.sharepointServices.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        // this.spinner.hide();
        // this.getData();
        if (this.solzStatusvalue == 674180002) {
          this.router.navigate(['/ESSPortal/to_dos/my_to_dos'])
        }
        else {

          this.getData();
          this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.upLoadFileList[0]?.ID]);
        }

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

  createNewTodo() {
    this.router.navigate(['/ESSPortal/to_dos/new_to_do']);
  }
  download(file: any) {

    import("file-saver").then(FileSaver => {
      var contentbase64 = file.bytes;
      var contenttype = file.fileType;
      var fileFormat = "data:" + contenttype + ";base64," + contentbase64;
      //download file
      FileSaver.saveAs(fileFormat, file.fileName);
    });


  }

  GetTodosByLinkedTaskIdOnBug() {
    this.toDosService.GetAllBugsByUserStoryId(this.updateToDoId).subscribe((bugResp: any) => {
      if (bugResp.isSuccessful == true) { // success
        this.bugListData = bugResp.data;
      }

      else if (bugResp.isSuccessful == false) {
        this.spinner.hide();
        if (bugResp?.messageDetail?.message_code != 204) {

          this.toastr.error(bugResp?.messageDetail?.message);
        }
        this.bugListData = []
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message)
    });

  }
  //send Id's of These Fields on router
  addBug() {
    this.router.navigate(['/ESSPortal/to_dos/to-dos-bugs/', this.updateToDoId]);

  }

  //Task Bug's grid list record update 
  updateLinkedBug(recordId) {
    this.updateToDoId = recordId
    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.updateToDoId]);
    this.getToDosDataToUpdate()
  }
  //********************** Worklog Related Actions Begin *************************


  //*********************************** Add Bug Related Actions End *************************






  //*********************************** To-Do Related Actions End *************************


  //********************** Worklog Related Actions Begin *************************
  getWorklogRecordId(workLogRow: any) {
    this.worklogRecordId = workLogRow.id
  }

  // here sending to-do id to my worklog page to create a new to-do respective worklog.
  createWorkLog() {

    this.router.navigate(['/ESSPortal/to_dos/my_worklog/AddWork/' + this.updateToDoId]);
  }

  // here sending worklog id to my worklog page to edit already existing worklog.
  async EditWorklog(workLogRow: any) {

    this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + workLogRow.id]);
  }
  async EditWorklogrecord() {

    this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + this.worklogRecordId]);
  }
  deleteWorkLog(workLogRow: any) {
    this.deteleworklogRow = workLogRow
  }

  async onDelete() {
    this.spinner.show();
    await this.supportReqService.postdeleteTimeSheet(this.deteleworklogRow).subscribe((delRes: any) => {
      if (delRes.ResponseCode == 100) { // success
        this.spinner.hide();
        this.toastr.success(delRes.Message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });

      }

      else if (delRes.ResponseCode == 999) {
        this.toastr.error(delRes.Message, 'Error');
        this.spinner.hide();
      }
      this.spinner.hide();
      this.getWorkLogList();
      // location.reload();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // //********************** Worklog Related Actions End *************************
  back() {
    debugger;
    if (window.history.state?.navigationId > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/ESSPortal/to_dos/my_to_dos']); // Fixed syntax
    }
  }

}

