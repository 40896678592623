import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from '../../../_services/feedback.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-feed-back',
  templateUrl: './update-feed-back.component.html',
  styleUrls: ['./update-feed-back.component.scss']
})
export class UpdateFeedBackComponent implements OnInit {
  feedbackId: string;
  feedbackForm: FormGroup;
  Updatefeedback: any;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    if (this.route.snapshot.paramMap.get('id')) {
      this.feedbackId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.feedbackForm = this.fb.group({
      regardingTo: [''],
      feedBackTitle: [''],
      feedBackDescription: [''],
      reportedBy: [''],
      reportedOn: [''],
      ResolvedBy: [''],
      resolution: [''],
      owner: [''],
      status: [''],
      actionTakenOn: ['']
      // other: ['']
    });

    this.GetMyFeedbacksByFeedBackId();
  }

  GetMyFeedbacksByFeedBackId() {
     
    this.spinner.show();
    this.feedbackService.GetMyFeedbacksByFeedBackId(this.feedbackId).subscribe((feedbckResp: any) => {
      if (feedbckResp.isSuccessful == true) {
        this.Updatefeedback = feedbckResp.data[0]
        console.log('responce', this.Updatefeedback[0])
        this.feedbackForm.patchValue({
          regardingTo: this.Updatefeedback.regardingTo.label,
          feedBackTitle: this.Updatefeedback.feedBackTitle,
          feedBackDescription: this.Updatefeedback.feedBackDescription,
          reportedBy: this.Updatefeedback.reportedBy,
          reportedOn: this.Updatefeedback.reportedOn,
          ResolvedBy: this.Updatefeedback.actionTakenBy,
          resolution: this.Updatefeedback.resolution,
          owner: this.Updatefeedback.actionTakenBy,
          status: this.Updatefeedback?.status?.label,
          actionTakenOn: this.Updatefeedback?.actionTakenOn
        })
        this.spinner.hide();
      }
      else if (feedbckResp.isSuccessful == false) {
        this.toastr.error(feedbckResp?.messageDetail?.message, 'Error')
        this.spinner.hide();
      }


    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }


onSubmit() {

}

  //Support Request Form Control
  get srf() {
  return this.feedbackForm.controls;
}



}
