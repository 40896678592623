import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRecordService {
  apiURL: string
  authapiURL: string = "";
  constructor(
    public HttpClient: HttpClient
  ) {
    this.apiURL = environment.apiURL
    this.authapiURL = environment.authapiURL;
  }

  ngOnInit() {

  }

  // Get employee Project Allocation
  getemployeeProjectAllocation(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'EmployeeRecord/GetemployeeProjectAllocation' );
  }

  getEmployeeInventoryAllocation(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'EmployeeRecord/GetEmployeeInventoryAllocation' );
  }
  // ..............................HIRING MODEL API'S BEGNNING ............................................
  //APi For Hiring Model GRID
  getHiringPosition(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'HiringRecruitment/GetListOfOpenPosition');
  }

  //APi For Hiring Model DATA
  getCandidateApplicationByEmployeeId(): Observable<any> {
    return this.HttpClient.get(this.authapiURL + 'HiringRecruitment/GetCandidateApplicationByEmployeeId' );
  }

//Post API'S Of Create Condiate Application Form
  postapplyApplicationForm(obj:any): Observable<any> {
    return this.HttpClient.post(this.authapiURL + 'HiringRecruitment/CreateCandidateApplication',obj);
  }
  // ..............................HIRING MODEL API'S END ............................................


}
