import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from '../../../_services/feedback.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { SharepointServiceService } from '../../../_services/sharepoint-service.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  feedBackSubmit: boolean = false;
  // @Input() employeeId: string
  feedbackOptionset: any;
  otherFlag: boolean = false
  employeeId: string;
  upLoadFileList: Array<UploadFileDataModel> = [];
  datasplit: any;

  constructor(private fb: FormBuilder,
    public feedBackServices: FeedbackService,
    public toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private sharepointServices: SharepointServiceService
  ) {

    this.employeeId = localStorage.getItem('empId')

  }

  ngOnInit(): void {

    this.feedbackForm = this.fb.group({
      regardingTo: ['', Validators.required],
      feedBackTitle: ['', Validators.required],
      feedBackDescription: ['', Validators.required],
      // other: [''],
      feedBackDetails: ['']


    });
    this.getFeedBackDropdown()
  }
  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    // Reset the height to recalculate
    textarea.style.height = 'auto';
    // Set the height to fit the content
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  //Support Request Form Control
  get srf() {
    return this.feedbackForm.controls;
  }
  getFeedBackDropdown() {
    this.spinner.show();
    this.feedBackServices.getFeedBackDropdown().subscribe((resp: any) => {
      if (resp.isSuccessful == true) {
        this.feedbackOptionset = resp.data;

        this.spinner.hide();
      }
      else if (resp.isSuccessful == false) {
        this.spinner.hide();
      }

    }, error => {
      this.toastr.error(error.message);
      this.spinner.hide();
    });
  }

  // OnChangestatusReason(value) {
  //   if (value == 674180005) {
  //     this.otherFlag = true
  //   }
  //   console.log(value)
  // }
  modelValidate() {
    this.feedBackSubmit = true
    // this.spinner.show();
    if (this.feedbackForm.invalid) {
      this.spinner.hide();
      return
    }
  }


  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_feedback";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }
  onSubmit() {

    this.spinner.show();
    this.feedBackSubmit = true;
    if (this.feedbackForm.invalid) {
      this.spinner.hide();
      return
    }
    let feedback =
    {
      "feedBackId": null,
      "feedBackTitle": this.feedbackForm.value.feedBackTitle,
      "feedBackDescription": this.feedbackForm.value.feedBackDescription,
      "regardingTo": {
        "value": this.feedbackForm.value.regardingTo,
        "label": "string"
      },
      "reportedBy": "string",
      "reportedById": this.employeeId,
      "status": {
        "value": 0,
        "label": "string"
      },
      "publishToEmp": false,
      "resolution": "string",
      "actionTakenBy": "string"
    }
    this.feedBackServices.createMyfeedBacks(feedback).subscribe((feedbackresp: any) => {
      if (feedbackresp.isSuccessful == true) {
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = feedbackresp.data;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }
        this.toastr.success(feedbackresp.messageDetail.message, 'Success')
        this.router.navigate(['/ESSPortal/my-feedback'])
        this.spinner.hide();
      }
      else if (feedbackresp.isSuccessful == false) {
        this.toastr.error(feedbackresp.messageDetail.message, 'Error')
        this.spinner.hide();
      }


    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error.messageDetail.message);
    });
  }

  async uploadFiles(attachFiles: any) {
    await this.sharepointServices.AttachFileInSharePoint(attachFiles).subscribe((upRes: any) => {
      if (upRes.isSuccessful == true) { // success
        this.router.navigate(['/ESSPortal/my-feedback']);

      }
      else if (upRes.isSuccessful == false) {
        this.spinner.hide();
        this.toastr.error(upRes?.messageDetail?.message);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }

}
